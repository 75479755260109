import React, { useContext } from 'react';
import { Grid, Icon, Popup, Radio } from 'semantic-ui-react';
import { UPDATE_RENTAL_TYPE } from '../../state/actions';
import { CertificateContext } from '../../state/context';
import { RentalTypeWrapper } from './tot-property-styles';

//Store as an array of arrays of objects to work with Rsuite's Grid component
const rentalTypes = [
  [
    { label: 'Home', value: 'home' },
    { label: 'Apartment', value: 'apartment' },
    { label: 'Bed and Breakfast', value: 'bed_and_breakfast' },
    { label: 'Condotel', value: 'condotel' },
  ],
  [
    { label: 'Condo', value: 'condo' },
    { label: 'Duplex', value: 'duplex' },
    { label: 'Mobile Home', value: 'mobile_home' },
    { label: 'Motel', value: 'motel' },
  ],
  [
    { label: 'Triplex', value: 'triplex' },
    { label: 'Timeshare', value: 'timeshare' },
    { label: 'Hotel', value: 'hotel' },
  ],
];

export const RentalTypesRadioMenu = () => {
  const { dispatch } = useContext(CertificateContext);

  // Dispatches
  const handleRentalTypeChange = (e, { value }) =>
    dispatch({ type: UPDATE_RENTAL_TYPE, payload: { rentalType: value } });

  return (
    <RentalTypeWrapper>
      <Grid stackable>
        {rentalTypes.map((subList, i) => (
          <Grid.Row key={`rentalSubList-${i}`}>
            {subList.map((radioData) => (
              <RentalTypeRadio
                key={radioData.label}
                radioData={radioData}
                handleRentalTypeChange={handleRentalTypeChange}
              />
            ))
            }
          </Grid.Row>
        ))}
      </Grid>
    </RentalTypeWrapper>
  );
}

const RADIO_ITEM_NAME = 'radioGroup';
const RENTAL_TYPE_CONDOTEL = 'Condotel';

const RentalTypeRadio = (props) => {
  const {
    radioData,
    handleRentalTypeChange,
  } = props;

  const {
    state : { rentalType },
  } = useContext(CertificateContext);

  return (
    <Grid.Column width={4}>
      <Radio
        name={RADIO_ITEM_NAME}
        label={radioData.label}
        value={radioData.value}
        checked={rentalType === radioData.value}
        onChange={handleRentalTypeChange}
      />
      {radioData.label === RENTAL_TYPE_CONDOTEL &&
        <Popup
          content='Multiple owners on one property with resort amenities'
          trigger={<Icon style={{ paddingLeft: '0.5rem' }} name='info circle'/>}
          inverted
        />
      }
    </Grid.Column>
  );
}
