import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  margin: 1rem 0 0 0;
`;

export const InputLabelSection = styled.div`
  color: #7f8fa4;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  font-size: 15px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

export const QuarterSectionWrapper = styled.div`
  margin: 1rem 0;
`;

export const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ResponsiveMessage = styled.div`
    margin-top:1em;
  @media (min-width: 768px) {
    width:450px;
  }
`;


export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-top: -6px;
    background-color:transparent ;
    padding:0 0 0 20px;
  }
`;