import { Checkbox, Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const RadioFieldWrapper = styled.div`
  margin: 1rem 0;
`;

export const RadioSectionWrapper = styled.div`
  margin-bottom: 6rem;
`;

export const RowWrapper = styled.div`
  margin: 1rem 0;
  padding: 0 1rem;
`;

export const UnpaddedRowWrapper = styled.div`
  margin: 1rem 0;
`;

export const FirstRowWrapper = styled.div`
  margin: 1rem 0;
  padding-right: 1rem;
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const FieldInput = styled(Input)`
  margin: 0.5rem 0;
  width: ${(props) => props.width || '150px'};
`;

export const StyledCheckbox = styled(Checkbox)`
  &&& > .ui.checkbox > label {
    font-weight: bold;
    color: #5c5d5d;
  }
`;

export const ImageSectionWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 6rem 0;
`;

export const PersonalInfoForm = styled.div`
  @media only screen and (max-width: 768px) {
    .ui.stackable.divided.grid {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  }
`;