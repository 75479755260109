import styled from "styled-components";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";

export const HeaderText = styled.div`
  font-family: Lato;
  font-size: 18px;
  line-height: 1.56;
  color: #5c5d5d;
  padding-bottom: 20px;
`;

export const WarningText = styled.div`
  width: 800px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #f7981c;
`;

export const AddressInput = styled(TextField)`
  &&& {
    width: 400px;
  }

  input {
    padding: 10px 14px;
  }
`;

export const ParcelInput = styled(TextField)`
  &&& {
    width: 200px;
    margin-bottom: 20px;
  }

  input {
    padding: 10px 14px;
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '400px',
      height: '36px',
      marginTop: '20px',
    },
  }),
);
