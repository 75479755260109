import React, { useEffect, useRef } from 'react';
import { Tab, TabItem } from './stripe-form-styles';
import { authSender } from '../utils/auth-sender';
import { ACH_PAYMENT_TAB, CREDIT_CARD_TAB } from './checkout-constants';

async function makeConfig(setBankIdentifiers, onExitCB) {
  const { data } = await authSender.post('/getLinkToken');
  const linkToken = data.link_token;

  return {
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      const accountId = metadata.accounts[0].id;
      const { data } = await authSender.post('/plaidExchangeTokens', { publicToken, accountId });
      setBankIdentifiers({ bTok: data.stripe_bank_account_token, metadata });
    },
    onExit: onExitCB,
  };
}

export default function ACHTab({ isactive, setActiveTab, setBankIdentifiers }) {
  const linkHandlerRef = useRef();

  useEffect(() => {
    (async function () {
      const configs = await makeConfig(setBankIdentifiers, () => setActiveTab(CREDIT_CARD_TAB));
      linkHandlerRef.current = window.Plaid.create(configs);
    })();
  }, [setBankIdentifiers, setActiveTab]);

  return (
    <Tab
      isactive={isactive}
      onClick={() => {
        setActiveTab(ACH_PAYMENT_TAB);
        linkHandlerRef.current.open();
      }}
    >
      <TabItem>
        <img
          src='https://js.stripe.com/v3/fingerprinted/img/ideal-739132f67727ae7a5b577248e731dfbd.svg'
          alt=''
          height='14px'
        />
      </TabItem>
      <TabItem>
        <span
          style={{
            width: '100%',
            height: '12px',
            fontSize: '12px',
            lineHeight: '1',
            padding: '5px 0 2px',
          }}
        >
          Bank Account
        </span>
      </TabItem>
    </Tab>
  );
}
