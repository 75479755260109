/* eslint-disable operator-linebreak */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import { Button } from 'semantic-ui-react';
import { ButtonWrapper } from './navigation-buttons-styles';
import { PaymentContext } from '../../state/context';
import {
  UPDATE_CHECKOUT_DETAILS,
  UPDATE_SIGNATORY,
  UPDATE_REPORT_REVENUE_DETAILS,
  UPDATE_PAYMENT_CONFIRMATION,
} from '../../state/actions';
import { authSender } from '../../utils/auth-sender';

const NavigationButtons = ({
  nextLabel = 'Continue',
  isNextDisabled = true,
  backClick,
  nextClick,
  nextPayload,
  contextPayload,
}) => {
  const history = useHistory();
  const { state, dispatch } = useContext(PaymentContext);

  const noPayment = async () => {
    const paymentId = state.checkoutDetails.paymentId;
    const noPayResponse = await authSender.post('/payNothing',
      { signatory: nextPayload, paymentId }).catch(() => {
        alert('Our system encountered an unknown error, we sincerely apologize for the inconvenience');
      });
    if (noPayResponse.data) {
      const checkTotResponse = await authSender.get(`${ROUTES.checkTotPayment}/${paymentId}`);
      dispatch({
        type: UPDATE_PAYMENT_CONFIRMATION,
        payload: { paymentConfirmation: checkTotResponse?.data?.paymentConfirmation },
      });
    }
  };

  const handleBackClick = () => {
    if (backClick === 'pay-tot') {
      history.push(`/${backClick}`);
    } else if (backClick === 'revenue') {
      history.push(`/pay-tot/${backClick}`);
    } else if (backClick === 'home') {
      history.push('/');
    }
  };

  const handleNextClick = async () => {
    if (nextClick === 'sign') {
      // saving the report revenue data in context for persisting data
      dispatch({
        type: UPDATE_REPORT_REVENUE_DETAILS,
        payload: contextPayload,
      });

      const payload = nextPayload.map(({ dateStart, dateEnd, taxableReceipts, tbidExcluded, numDaysOccupied, numDaysAvailable }) => ({
        dateStart,
        dateEnd,
        taxableReceipts: Number(taxableReceipts),
        misc: {tbidExcluded: Number(tbidExcluded)},
        numDaysOccupied: Number(numDaysOccupied),
        numDaysAvailable: Number(numDaysAvailable),
      }));

      const { data } = await authSender.post(`${ROUTES.calculateTot}/${state.totCertId}`, payload);
      dispatch({
        type: UPDATE_CHECKOUT_DETAILS,
        payload: { checkoutDetails: data, checkoutItems: payload },
      });
      history.push(`/pay-tot/${nextClick}`);
    } else if (nextClick === 'payment') {
      if (state.checkoutDetails?.summary.total === 0) {
        dispatch({
          type: UPDATE_SIGNATORY,
          payload: { signatoryName: nextPayload },
        });
        noPayment().then(() => history.push('/pay-tot/confirm', { paymentType: 'noPay' }));
      } else {
        dispatch({
          type: UPDATE_SIGNATORY,
          payload: { signatoryName: nextPayload },
        });
        history.push(`/pay-tot/${nextClick}`);
      }
    }
  };

  return (
    <ButtonWrapper>
      <Button basic content='Back' onClick={handleBackClick} />
      <Button color='black' content={nextLabel} disabled={isNextDisabled} onClick={handleNextClick} />
    </ButtonWrapper>
  );
};

export default NavigationButtons;
