import styled from 'styled-components';

export const SectionHeadingWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

export const InfoSectionWrapper = styled.div`
  margin-top: 4rem;
`;

export const UpdateDetailsWrapper = styled.div`
  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const UpdateDetailsButtonWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;