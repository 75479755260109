import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Image } from 'semantic-ui-react';
import LayoutHeader from '../components/common/layout-header';
import { StyledAnchor } from '../components/tot-pay/tot-pay-footer-styles';

const UnderConstructionPermit = () => (
  <>
    <LayoutHeader title='Placer County Short-term Rental Registration and Tax Payment System ' />
    <Container style={{ paddingTop: '10rem' }}>
      <Header style={{ color: '#5c5d5d', fontSize: '50px', fontWeight: 'normal' }} size='huge'>
        STR Permit Online Form is Under Development.
      </Header>
      <Header style={{ fontWeight: 'normal' }}>
      A new online STR Permit Application will be available soon. In the meantime, you can find all of the permit details and {' '}
        <StyledAnchor style={{ color: '#027680', textDecoration: 'underline' }} target='_blank' href='https://www.placer.ca.gov/DocumentCenter/View/42378/CDRA-STR-Application_FINAL'>
        downloadable forms here
        </StyledAnchor>
        .{' '}
      </Header>
      <div style={{ margin: '3rem 0' }}>
        <Button basic content='Back' as={Link} to='/' />
      </div>
      <Image src='../../assets/under-construction.svg' />
    </Container>
  </>
);

export default UnderConstructionPermit;
