import { StepConnector, StepLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const activeColor = '#027680';
const inactiveColor = 'rgba(0, 0, 0, 0.15)';

export const QontoConnectorV2 = withStyles({
  active: {
    '& $line': {
      borderColor: activeColor,
    },
  },
  completed: {
    '& $line': {
      borderColor: activeColor,
    },
  },
  line: {
    borderColor: inactiveColor,
    borderTopWidth: 3,
    borderRadius: 1,
    height: '63px',
  },
})(StepConnector);

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: inactiveColor,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: activeColor,
  },
  circle: {
    marginLeft: '8px',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    marginLeft: '5px',
    color: activeColor,
    zIndex: 1,
    fontSize: 18,
  },
});

export const StepperLabel = styled(StepLabel)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #5c5d5d;
`;
