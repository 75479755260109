/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  /* margin: 20% 20% 0 20%; */
  padding: 0;

  width: 380px;
  @media only screen and (max-width: 768px) {
    width: unset;
  }
  display: flex;
  flex-direction: column;
`;

export const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Tab = styled.div`
  height: 50px;
  border-radius: 6px;
  margin-top: 10px;
  border-color: ${(props) => (props.isactive ? 'black' : 'rgba(128,128,128, 0.5)')};
  border-width: 1px;
  border-style: solid;

  display: flex;
  flex-direction: column;

  padding: 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FieldLabel = styled.label`
  margin-top: 12px;
  margin-bottom: 12px;
  color: rgba(26, 26, 26, 0.7);
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '13px'};
`;

export const CardField = styled.div`
  display: flex;
  margin-bottom: 10px;

  .stripe-element {
    height: 35px;
  }

  #stripe-card-number {
    width: 100%;
    padding: 8px 12px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }

  #stripe-expiry {
    width: 50%;
    padding: 8px 12px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }

  #stripe-cvc {
    width: 50%;
    font-size: 14px;
    padding: 8px 12px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  box-sizing: border-box;
  line-height: 1.5;
  color: rgba(26, 26, 26, 0.9);
  font-size: 16px;
  border-radius: 6px;
  border: 0;
  margin: 10px auto 10px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
`;

// export const PayButton = styled.button`
//   background-color: ${(props) => (props.disabled ? '#999a9a' : '#458E87')};
//   color: #eee;
//   cursor: pointer;
//   border-radius: 6px;
//   box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
//     0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
//   border: 0;
//   height: 40px;
//   width: 100%;
//   font-size: 16px;
//   font-weight: 600;
//   margin-top: 24px;
// `;

export const PayButton = styled(Button)`
  &&& {
    background-color: ${(props) => (props.disabled ? '#999a9a' : '#458E87')};
    color: #eee;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 5px 0 rgba(50, 50, 93, 0.1),
      0 1px 1px 0 rgba(0, 0, 0, 0.07);
    border: 0;
    height: 40px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-top: 24px;
  }
`;
