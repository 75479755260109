import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
  padding: 0.2rem 0;
`;

export const YearLabel = styled.div`
  font-weight: bold;
  padding: 0.5rem 0;
`;

export const LeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexibleContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;
