import styled from 'styled-components';

export const SectionWrapper = styled.div`
  color: #5c5d5d;
  margin-top: 3rem;
`;

export const HeadingContainer = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const ConfirmationNumberContainer = styled.div`
  font-size: 50px;
  margin: 0.5rem 0;
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  margin-bottom: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row-reverse;
`;
