import { Button } from 'semantic-ui-react';

const SubmitButton = (props) => {
  return (
    <Button
      content='Check Certificate Number'
      color='black'
      onClick={props.onClick}
      disabled={props.disabled}
      loading={props.loading}
      data-cy="tot-check"
    />
  );
};

export default SubmitButton;
