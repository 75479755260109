import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

export const FooterLink = styled.a`
  color: #027680;
`;

export const FooterWrapper = styled(Container)`
  padding-top: 5rem;
`;
