import React from 'react';
import { Image } from 'semantic-ui-react';
import { FooterWrapper, StyledAnchor } from './tot-pay-footer-styles';

const TOTPaymentFooter = ({ withImage }) => (
  <FooterWrapper>
    <div>
      <div>Want to print, fill out and mail forms directly to our offices?</div>
      <div>
        <StyledAnchor style={{ color: '#027680' }} href="https://www.placer.ca.gov/1464/TOT-Forms">
          Download PDF Forms
        </StyledAnchor>
        <span>{' and mail it to us.'}</span>
      </div>
    </div>
    {withImage ? (
      <Image
        src="../../../assets/tot-pay-address.svg"
        alt="TOT payment address"
      />
    ) : null}
  </FooterWrapper>
);

export default TOTPaymentFooter;
