// TODO: We should stop using this! One more level of complexity. KISS
export const ApplyTotRouteMap = {
  '/apply-tot': '/apply-tot/property',
  '/apply-tot/property': '/apply-tot/owner-info',
  '/apply-tot/owner-info': '/apply-tot/contact-manager',
  '/apply-tot/contact-manager': '/apply-tot/listings',
  '/apply-tot/listings': '/apply-tot/confirm-details',
  '/apply-tot/confirm-details': '/apply-tot/tot-sign',
  '/apply-tot/tot-sign': '/apply-tot/confirm',
}
