import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const SectionHeadingWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

export const RowWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const FieldInput = styled(Input)`
  margin: 0.5rem 0;
  width: ${(props) => props.width || '300px'};
`;
