import { FieldItem } from '../common/field-item';

const ConditionalFieldItem = (props) => {
  const { label, content, hasChanged = false } = props;

  if (!content) return null;
  return <FieldItem label={label} content={content} hasChanged={hasChanged}/>;
}

export default ConditionalFieldItem;
