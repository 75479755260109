import { authSender } from '../utils/auth-sender';
import { CardNumberElement } from '@stripe/react-stripe-js';

export async function payCC({
  stripe,
  elements,
  paymentId,
  certificateHolder,
  signatory,
  emailInputValue,
  onSuccessRedirect,
  confirmPaymentSuccess,
  unlock,
  setLoading,
  history,
  onError,
}) {
  if (!stripe || !elements) {
    // TODO: Can't possibly just ignore silently
    // eslint-disable-next-line no-useless-return
    return;
  }

  const receiptEmail = emailInputValue;

  try {
    const { data: { clientSecret } } = await authSender.post('/payCC', {
      email: receiptEmail,
      paymentId,
      signatory,
    });

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: certificateHolder,
        },
      },
      receipt_email: receiptEmail,
    });

    if (result.error) {
      onError(result.error);
      // TODO: Show error to customer (e.g., insufficient funds)
      unlock();
      alert(result.error.message);
      history.push('/payment-error', { errorMessage: result.error.message });
    } else if (result.paymentIntent.status === 'succeeded') {
      // console.log('success');
      await confirmPaymentSuccess();
      await onSuccessRedirect();
    }
  } catch (err) {
    // console.log('err', err);
    onError(err);
    history.push('/payment-error', { errorMessage: 'Server Error' });
    unlock();
  }
}
