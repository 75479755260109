import React from 'react';
import { Divider } from '@material-ui/core';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  &&& {
    width: 176px;
    margin-top: 9px;
  }
`;

const BoldText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #000000;
`;

export function OrSeparator() {
  return (
    <div style={{ display: 'flex', margin: '20px 0' }}>
      <StyledDivider style={{ marginRight: '14px' }}/>
      <BoldText>OR</BoldText>
      <StyledDivider style={{ marginLeft: '14px' }}/>
    </div>
  );
}
