import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
  stats: null,
};

export const sampleSlice = createSlice({
  name: 'app', // will be used for creating the action types
  initialState: INIT_STATE,
  reducers: {
    serverReturnedStats: (state, action) => {
      const { stats } = action.payload;
      state.stats = stats;
    },
  },
})

export const appActions = sampleSlice.actions;

export const appReducer = sampleSlice.reducer;
