import React from 'react';
import { FieldLabel, FieldWrapper } from '../tot-pay/tot-payment-styles';

export const FieldItem = (props) => {
  const {
    label,
    content,
    hasChanged = false,
  } = props;

  return (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <p style={{fontWeight: hasChanged ? 'bold' : 'normal'}}>
        {content}
      </p>
    </FieldWrapper>
  );
}
