import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Radio } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';
import {
  UPDATE_LOCAL_CONTACT,
  UPDATE_OWNER_INFO,
  UPDATE_RENTAL_MANAGER,
  UPDATE_SAME_AS_RENTAL,
  UPDATE_TOT_CERTIFICATION_STEP,
} from '../../state/actions';
import { CertificateContext } from '../../state/context';
import {
  contactFormValidation,
  getAddressSuggestions,
  getRawLocalContact
} from '../../utils/helper-functions';
import { contactInfoWhiteSpaceTrimmer } from '../../utils/utility-functions';
import { HeaderWrapper } from '../common/common-styles';
import { ownerLabels } from '../common/constants';
import { ERROR_MESSAGES } from '../common/error-constants';
import {
  RadioFieldWrapper,
  RadioSectionWrapper,
  StyledCheckbox,
  ImageSectionWrapper,
  PersonalInfoForm
} from './apply-tot-owner-info-styles';
import ContactSectionAddress from './contact-section-address';
import { ButtonWrapper } from './navigation-buttons-styles';
import { StyledErrorMessage } from './tot-address-styles';

const TotOwnerInfo = () => {
  const { state: {
    ownerInfo,
    manageRental,
    localContact,
    sameAsRental,
    propertyAddress,
  }, dispatch } = useContext(CertificateContext);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(ERROR_MESSAGES.NoErrorsArray);

  // For auto-suggestions of property manager address
  const [initialDataLoading, setInitialDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  /*
   * `rawLocalContact` tracks the local in the form.
   * Do not confuse with `localContact`, which is the real placeholder for the Local Contact
   * value that we want to send to the server. See useEffect
   */
  const [rawLocalContact, setRawLocalContact] = useState(getRawLocalContact(
    manageRental,
    localContact,
  ));

  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 2 },
    });
  }, [dispatch]);

  useEffect(() => {
    if (sameAsRental) {
      updateOwnerInfo({ ...ownerInfo, address: propertyAddress });
      setSearchTerm(propertyAddress)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAsRental]);

  // Property manager address suggestions
  useEffect(() => {
    if (Boolean(searchTerm)) {
      (async () => setSuggestions(await getAddressSuggestions(searchTerm)))();
    } else if (initialDataLoading) {
      updateOwnerInfo({ ...managerInfo, address: searchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    /*
     * Every time either  `rawLocalContact`  or `manageRental` change, this useEffect will run and
     * set the value of `localContact` in context state.
     *
     * If the radio button "Same as certificate holder" is selected, the `localContact` state
     * must be the same as `manageRental` (if manageRental not undefined).
     * E.g.:
     *     if
     *       manageRental="owner"  // manageRental tells certificateHolder
     *       rawLocalContact=certificateHolder
     *     then
     *       localContact="owner"
     */
    let newLocalContactValue = null;
    if (manageRental) {
      newLocalContactValue = (rawLocalContact === "certificateHolder") ?
        manageRental : rawLocalContact;
    } else {
      newLocalContactValue = (rawLocalContact === "certificateHolder") ?
        null : rawLocalContact;
    }

    if (rawLocalContact !== localContact) {
      dispatch({ type: UPDATE_LOCAL_CONTACT, payload: { localContact: newLocalContactValue } });
    }
  }, [localContact, rawLocalContact, manageRental, dispatch])

  const updateOwnerInfo = (payload) => {
    dispatch({
      type: UPDATE_OWNER_INFO,
      payload,
    });
  };

  const handleRentalManager = (e, { value }) => {
    dispatch({
      type: UPDATE_RENTAL_MANAGER,
      payload: { manageRental: value },
    });
  };

  const handleLocalContact = (e, { value }) => {
    setRawLocalContact(value);
  };

  const handleSameAsRentalChange = (e, { value }) => {
    dispatch({
      type: UPDATE_SAME_AS_RENTAL,
      payload: { sameAsRental: !sameAsRental },
    });
  };

  const validateInputs = () => {
    updateOwnerInfo(contactInfoWhiteSpaceTrimmer(ownerInfo));
    setErrors(ERROR_MESSAGES.NoErrorsArray);

    let errorMsgs = [];
    const formErrors = contactFormValidation(ownerInfo);
    if (Boolean(formErrors)) errorMsgs.push(formErrors);
    if (!Boolean(manageRental)) errorMsgs.push(ERROR_MESSAGES.MissingCertificateHolder);
    if (!Boolean(rawLocalContact)) errorMsgs.push(ERROR_MESSAGES.MissingLocalContactPerson);

    // If there are any pushed errors, then this will trigger
    if (errorMsgs.length) {
      setErrors(errorMsgs);
      return false;
    }

    return true;
  }

  const handleNextClick = () => {
    setIsLoading(true);

    if (validateInputs()) {
      if (manageRental === 'owner' && rawLocalContact === 'certificateHolder') {
        history.push('/apply-tot/listings');
      } else {
        history.push(ApplyTotRouteMap[history.location.pathname]);
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <PersonalInfoForm>
        <Grid stackable divided relaxed>
          <Grid.Column width={8}>
            <HeaderWrapper>Owner Information - All Fields Required</HeaderWrapper>
            <ContactSectionAddress
              labels={ownerLabels}
              info={ownerInfo}
              updateInfo={updateOwnerInfo}
              sameAsRental={sameAsRental}
              searchTerm={searchTerm}
              suggestions={suggestions}
              setSearchTerm={setSearchTerm}
            />
            <StyledCheckbox
              label='Same as rental unit'
              checked={sameAsRental}
              onChange={handleSameAsRentalChange}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <RadioSectionWrapper>
              <HeaderWrapper>Who is the certificate holder?</HeaderWrapper>
              <RadioFieldWrapper>
                <Radio
                  label='The owner'
                  name='radioGroupManager'
                  value='owner'
                  checked={manageRental === 'owner'}
                  onChange={handleRentalManager}
                />
              </RadioFieldWrapper>
              <RadioFieldWrapper>
                <Radio
                  label='The property manager'
                  name='radioGroupManager'
                  value='property_mgr'
                  checked={manageRental === 'property_mgr'}
                  onChange={handleRentalManager}
                />
              </RadioFieldWrapper>
            </RadioSectionWrapper>

            <RadioSectionWrapper>
              <HeaderWrapper>Who is the local contact person?</HeaderWrapper>
              <RadioFieldWrapper>
                <Radio
                  label='The certificate holder'
                  name='radioGroupContact'
                  value='certificateHolder'
                  checked={rawLocalContact === 'certificateHolder'}
                  onChange={handleLocalContact}
                />
              </RadioFieldWrapper>
              <RadioFieldWrapper>
              </RadioFieldWrapper>
              <RadioFieldWrapper>
                <Radio
                  label='Someone else'
                  name='radioGroupContact'
                  value='someoneElse'
                  checked={rawLocalContact === 'someoneElse'}
                  onChange={handleLocalContact}
                />
              </RadioFieldWrapper>
            </RadioSectionWrapper>
          </Grid.Column>
        </Grid>
      </PersonalInfoForm>

      {Boolean(errors.length) &&
        <StyledErrorMessage negative>
          {errors.map((error, idx) => <p key={idx}>{error}</p>)}
        </StyledErrorMessage>
      }

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button
          onClick={handleNextClick}
          color='black'
          loading={isLoading}
          disabled={isLoading}
        >
          Continue
        </Button>
      </ButtonWrapper>

      <ImageSectionWrapper>
        <img src='../../../assets/apply-tot-owner-info.svg' alt='owner info' />
      </ImageSectionWrapper>
    </>
  );
};

export default TotOwnerInfo;
