const { List } = require('semantic-ui-react');

export const leftSectionPanels = [
  {
    key: 'transient-occupancy-tax',
    title: {
      content: 'Transient Occupancy Tax (TOT)',
    },
    content: {
      content: (
        <List bulleted style={{ paddingLeft: '2rem', display: 'flex', flexDirection: 'column' }}>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#what-is-tot'>
            What is TOT
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-due-date'>
            TOT Due Date
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-occupancy-tax'>
            TOT Rate
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-responsible'>
            Who is responsible for collecting TOT
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-property-manager'>
            If I use a property manager for my rental, do I still need to file a return?
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-fees-included'>
            What fees are included as rent for TOT
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-how-often'>
            How often do I have to file my TOT
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-still-need'>
            Do I still need to file a tax return even if I did not collect any rent during the quarter?
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-if-sell'>
            What if I sell of transfer or terminate operation of my business or rental?
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-dispute-penalty'>
            What do I do if I dispute a penalty and interest assessment by the County?
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-options-denied'>
            What are my options if my appeal is denied?
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-exemption'>
            Exceptions to TOT
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-online-payment'>
            Online Payment
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#tot-copy-uniform'>
            Where can I find a copy of the Uniform Transient Occupancy Tax Code?
          </List.Item>
        </List>
      ),
    },
  },
  {
    key: 'short-term-rental',
    title: {
      content: 'Short-Term Rental',
    },
    content: {
      content: (
        <List bulleted style={{ paddingLeft: '2rem', display: 'flex', flexDirection: 'column' }}>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-term-rental'>
            Short-Term Rental
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-permitting-information'>
            Permitting Information
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-permit-costs'>
            Permit Costs
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#maximum-occupancy'>
            Maximum Occupancy
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-renewal'>
            Renewal
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-permit-exemptions'>
            Permit Exemptions
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short=property-management'>
            Property Management
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-ordinance-standards'>
            Short-Term Rental Ordinance Standards
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-violations'>
            Violations
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-appeal'>
            Appeal
          </List.Item>
          <List.Item style={{ lineHeight: '1.5', color: '#5c5d5d' }} as={'a'} href='#short-contact'>
            Contact
          </List.Item>
        </List>
      ),
    },
  },
];
