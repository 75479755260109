import appSettings from '../app-settings.json';
import { BaseSender } from './base-sender';

export class UISender extends BaseSender {
  dataDomain = appSettings.strPlacerApiDomain;

  async get(path, params, responseType = 'json') {
    return UISender.axiosInstance.get(`${this.dataDomain}${path}`, { params, responseType });
  }

  async post(path, body, responseType = '') {
    const bodyJSON = (body && body.toJSON) ? body.toJSON() : body;
    return UISender.axiosInstance.post(`${this.dataDomain}${path}`, bodyJSON, { responseType });
  }
}

export const uiSender = new UISender();
