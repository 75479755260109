import React, { useState } from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import { getQuarterDetails } from '../../utils/utility-functions';
import {
  FlexibleContainer,
  LeftSectionWrapper,
  SectionWrapper,
  StyledCheckbox,
  YearLabel,
} from './previous-quarters-modal-styles';

const PreviousQuartersModal = ({
  setOpen,
  open,
  selectedQuarters,
  setSelectedQuarters,
  previousQuarters,
  previousQuartersChecked,
  setPreviousQuartersChecked,
}) => {
  const [currentSelection, setCurrentSelection] = useState([]);
  const [currentDeselected, setCurrentDeselected] = useState([]);

  const handleQuarterSelection = ({ name, checked }, year, quarter) => {
    setPreviousQuartersChecked((prevState) => {
      return { ...prevState, ...{ [name]: checked } };
    });
    const quarterDetails = getQuarterDetails(quarter, year);
    if (checked) {
      if (currentDeselected.length > 0) {
        setCurrentSelection([...currentDeselected.filter((item) => !(item.quarter === quarter && item.year === year))]);
      }
      setCurrentSelection([...currentSelection, ...quarterDetails]);
    } else {
      if (currentSelection.length > 0) {
        setCurrentSelection([...currentSelection.filter((item) => !(item.quarter === quarter && item.year === year))]);
      }
      setCurrentDeselected([...currentDeselected, ...quarterDetails]);
    }
  };

  const handleContinue = () => {
    // filtering out the deselected item from the previous selection and then adding current selection
    setSelectedQuarters([
      ...selectedQuarters.filter((item) => {
        let isValidSelection = true;
        currentDeselected.forEach((DeselectedItem) => {
          if (DeselectedItem.quarter === item.quarter && DeselectedItem.year === item.year) {
            isValidSelection = false;
          }
        });
        return isValidSelection;
      }),
      ...currentSelection,
    ]);
    setCurrentSelection([]);
    setCurrentDeselected([]);
    setOpen(false);
  };

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} size='tiny'>
      <Modal.Header>Report Additional TOT</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header as='h5'>Select the Quarters that You Received Revenue</Header>
          <FlexibleContainer>
            <LeftSectionWrapper>
              <YearLabel>{previousQuarters[0].year}</YearLabel>
              {previousQuarters[0]?.quarters?.map((quarter) => (
                <StyledCheckbox
                  name={quarter.name}
                  checked={previousQuartersChecked[quarter.name]}
                  key={quarter.quarter}
                  label={quarter.title}
                  onChange={(event, data) => {
                    handleQuarterSelection(data, previousQuarters[0].year, quarter.quarter);
                  }}
                />
              ))}
            </LeftSectionWrapper>
            <SectionWrapper>
              <YearLabel>{previousQuarters[1].year}</YearLabel>
              {previousQuarters[1]?.quarters?.map((quarter) => (
                <StyledCheckbox
                  key={quarter.quarter}
                  label={quarter.title}
                  name={quarter.name}
                  checked={previousQuartersChecked[quarter.name]}
                  onChange={(event, data) => {
                    handleQuarterSelection(data, previousQuarters[1].year, quarter.quarter);
                  }}
                />
              ))}
            </SectionWrapper>
          </FlexibleContainer>
          <FlexibleContainer>
            <LeftSectionWrapper>
              <YearLabel>{previousQuarters[2].year}</YearLabel>
              {previousQuarters[2]?.quarters?.map((quarter) => (
                <StyledCheckbox
                  key={quarter.quarter}
                  label={quarter.title}
                  name={quarter.name}
                  checked={previousQuartersChecked[quarter.name]}
                  onChange={(event, data) => {
                    handleQuarterSelection(data, previousQuarters[2].year, quarter.quarter);
                  }}
                />
              ))}
            </LeftSectionWrapper>
            <SectionWrapper>
              <YearLabel>{previousQuarters[3].year}</YearLabel>
              {previousQuarters[3]?.quarters?.map((quarter) => (
                <StyledCheckbox
                  key={quarter.quarter}
                  label={quarter.title}
                  name={quarter.name}
                  checked={previousQuartersChecked[quarter.name]}
                  onChange={(event, data) => {
                    handleQuarterSelection(data, previousQuarters[3].year, quarter.quarter);
                  }}
                />
              ))}
            </SectionWrapper>
          </FlexibleContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content='Close' onClick={() => setOpen(false)} />
        <Button color='black' onClick={handleContinue}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PreviousQuartersModal;
