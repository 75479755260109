import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react';
import { UPDATE_LISTINGS } from '../../state/actions';
import { UpdateTOTContext } from '../../state/context';
import { ListingOptions } from '../../utils/dropdown-options';
import { ButtonWrapper } from '../apply-tot/navigation-buttons-styles';
import { ButtonSectionWrapper, FieldInputWrapper, FieldLabel, FullWidthInput, GridWrapper } from '../apply-tot/tot-listings-styles';
import { HeaderWrapper, HeaderWrapperBold } from '../common/common-styles';
import { INPUT_MAX_LENGTHS } from '../common/constants';

export const UpdateListings = () => {
  // Hooks
  const history = useHistory();
  const {
    state: {
      listingUrls = [""],
    },
    dispatch,
  } = useContext(UpdateTOTContext);

  // Dispatches
  const handleAddListing = () => {
    dispatch({
      type: UPDATE_LISTINGS,
      payload: { listingUrls: [...listingUrls, ''] },
    });
  };

  const handleListingChange = (e, data, index) => {
    dispatch({
      type: UPDATE_LISTINGS,
      payload: {
        listingUrls: [
          ...listingUrls.map((item, i) => {
            if (i === index) { item = data.value; }
            return item;
          }),
        ],
      },
    });
  };

  // Click Handlers
  const handleNextClick = () => {
    history.push('/update-info/update-review');
  };

  return (
    <>
      <HeaderWrapper>
        Please report each listing advertising your short-term rental. Adding your listing makes payment verification
        process easier.
      </HeaderWrapper>

      <HeaderWrapperBold>This is optional.</HeaderWrapperBold>

      <GridWrapper>
        <Grid stackable>

          <Grid.Column width={4}>
            <FieldLabel>Listing Platform</FieldLabel>
            {listingUrls &&
              listingUrls.map((listing, $index) => (
                <div key={$index}>
                  <FieldInputWrapper>
                    <Dropdown fluid selection options={ListingOptions} />
                  </FieldInputWrapper>
                </div>
              ))}
          </Grid.Column>

          <Grid.Column width={12}>
            <FieldLabel>URL</FieldLabel>
            {listingUrls &&
              listingUrls.map((listing, $index) => (
                <div key={$index}>
                  <FieldInputWrapper>
                    <FullWidthInput
                      value={listing}
                      onChange={(e, data) => handleListingChange(e, data, $index)}
                      maxLength={INPUT_MAX_LENGTHS.URL}
                    />
                  </FieldInputWrapper>
                </div>
              ))}
            <div>Example: https://www.airbnb.com/rooms/12345678</div>
          </Grid.Column>
        </Grid>
      </GridWrapper>

      <ButtonSectionWrapper>
        <Button onClick={handleAddListing}>
          <Icon name='add' />
          <span>Add listing</span>
        </Button>
      </ButtonSectionWrapper>

      <ButtonWrapper>
        <Button
          basic
          content='Back'
          onClick={history.goBack}
        />

        <Button
          color='black'
          content='Continue'
          onClick={handleNextClick}
          disabled={false}
        />
      </ButtonWrapper>
    </>
  )
}
