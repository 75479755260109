import React from 'react';
import { Grid } from 'semantic-ui-react';
import {
  HelpWrapperContainer,
  StyledAccordion,
  ScrollingHelpContainer,
  AccordionContainer,
  SectionWrapper,
  SectionHeadingWrapper,
  HelpSectionHeader,
  HelpAnchor,
} from '../components/common/common-styles';
import LayoutHeader from '../components/common/layout-header';
import { leftSectionPanels } from '../utils/help-section-panel';
import styled from 'styled-components';

const PermitContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 11px;
`;

const QuarterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 11px;
`;
const Help = () => (
  <>
    <LayoutHeader isHelp={true} />
    <HelpWrapperContainer>
      <Grid relaxed stackable>
        <Grid.Column width={4}>
          <AccordionContainer>
            <StyledAccordion
              panels={leftSectionPanels}
              defaultActiveIndex={[0, 1]}
              exclusive={false}
            />
          </AccordionContainer>
        </Grid.Column>
        <Grid.Column width={12}>
          <ScrollingHelpContainer>
            <HelpSectionHeader>Transient Occupancy Tax (TOT)</HelpSectionHeader>
            <SectionWrapper id="what-is-tot">
              <SectionHeadingWrapper>What is Transient Occupancy Tax (TOT)</SectionHeadingWrapper>
              <p>
                Transient Occupancy Tax (TOT) is a rental tax that is paid by the guest for short-term rentals within a period of 30 consecutive calendar days or less. It is also known by names such as room tax, a use tax, a tourist tax, or hotel tax. The property owners/management companies must collect and remit the tax on the County’s behalf. TOT is authorized under State Revenue and Taxation Code Section 7280 and Placer County Code 4.16. TOT taxes paid by visitors help fund initiatives that benefit the County as a whole including:
              </p>
              <ul style={{ marginTop: '12px' }}>
                <li>Historical and Environmental Preservation</li>
                <li>Infrastructure Improvements</li>
                <li>Libraries</li>
                <li>Public Parks</li>
                <li>Public Safety</li>
                <li>Transportation</li>
              </ul>
            </SectionWrapper>
            <SectionWrapper id="tot-due-date">
              <SectionHeadingWrapper>Transient Occupancy Tax Due Date</SectionHeadingWrapper>
              <p>
                It is due on or before the last day of the month following the close of each quarter. If the due date
                falls on a United States Post Office Closure date, the tax due date will be the next business day
                (excluding federal holidays).
              </p>
              <p>
                As a registered operator, you will receive quarterly Transient Occupancy Tax returns by mail. You must
                declare total rents charged and received, and taxes collected from guests. You are responsible to pay
                the full amount of TOT for the quarter, even if you did not collect it from the guest. You must submit a
                tax return even if there were no rentals during the quarter.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-occupancy-tax">
              <SectionHeadingWrapper>Transient Occupancy Tax (TOT) Rate</SectionHeadingWrapper>
              <p>
                The tax rate depends on the location of the rental unit within the Eastern or Western slope. The
                division line is near Rainbow Road and I-80. The tax rate for the area known as
              </p>

              <ul>
                <li>“North Lake Tahoe Area, Eastern Slope” is 10%</li>
                <li>“Western Slope” is 8%</li>
              </ul>

              <p>
                You can <HelpAnchor target="_blank" href='https://www.placer.ca.gov/1463/TOT-Tax-Rate'>look up your tax rate</HelpAnchor> with the APN number of the property, or contact <HelpAnchor target="_blank" href="https://www.placer.ca.gov/Directory.aspx?DID=15">Revenue Services</HelpAnchor> with the exact address of the rental unit to determine the correct tax rate <HelpAnchor target="_blank" href="http://qcode.us/codes/placercounty/view.php?topic=4-4_16-4_16_030&frames=on">(Section 4.16.030)</HelpAnchor>.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-responsible">
              <SectionHeadingWrapper>Who is responsible for collecting TOT from guests if I rent via Airbnb, VRBO, or other internet based rental services?</SectionHeadingWrapper>
              <p>
                Property owners and managing agents, which includes property management companies, online platforms, or any other person or entity acting on the owner’s behalf, are responsible for ensuring compliance with the County’s Transient Occupancy Tax (TOT) Ordinance, including the collection and remittance of any TOT taxes that apply to their accommodations.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-property-manager">
              <SectionHeadingWrapper>If I use a property manager for my rental, do I still need to file a return?</SectionHeadingWrapper>
              <p>
                If Transient Occupancy Tax administration for your property is maintained by a property management company, and that company collects and remits all of your rental income and Transient Occupancy Tax, you do not need to file a return. You are responsible for their compliance with the County’s Transient Occupancy Tax Ordinance on your behalf. If you collect any rents outside of your agreement with the property management company, you would be required to file a return for the portion of rental income that you collected.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-fees-included">
              <SectionHeadingWrapper>What fees are included as rent for Transient Occupancy Tax?</SectionHeadingWrapper>
              <p>
                Rent and fees include, but are not limited to:
              </p>
              <ul>
                <li>Cleaning Fees</li>
                <li>Late Check-out Fees</li>
                <li>Nightly Rents</li>
                <li>Pet Fees</li>
                <li>Rollaway Bed Fees</li>
                <li>Room Upgrade Fees</li>
                <li>Weekly Rents</li>
              </ul>
              <p>Any operator that collects transient occupancy taxes in excess of $400,000 in a given fiscal year shall make remittance on a monthly basis in the succeeding fiscal year.</p>
            </SectionWrapper>
            <SectionWrapper id="tot-how-often">
              <SectionHeadingWrapper>How often do I have to file my TO</SectionHeadingWrapper>
              <p>
                You must file every Quarter
              </p>
              <QuarterContent>
              <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Quarter</div>
              <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Quarter Ending</div>
              <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Taxes Due By(Postmark)</div>
              <div>Quarter 1</div>
              <div>September 30</div>
              <div>October 31</div>
              <div>Quarter 2</div>
              <div>December 31</div>
              <div>January 31</div>
              <div>Quarter 3</div>
              <div>March 31</div>
              <div>April 30</div>
              <div>Quarter 4</div>
              <div>June 30</div>
              <div>July 31</div>
              </QuarterContent>
            </SectionWrapper>
            <SectionWrapper id="tot-still-need">
              <SectionHeadingWrapper>Do I still need to file a tax return even if I did not collect any rent during the quarter?</SectionHeadingWrapper>
              <p>
                Yes. You must submit a tax return even if there were no rentals during the quarter <HelpAnchor target="_blank" href='http://qcode.us/codes/placercounty/view.php?topic=4-4_16-4_16_070&frames=on'>(Section 4.16.070)</HelpAnchor>.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-if-sell">
              <SectionHeadingWrapper>What if I sell or transfer or terminate operation of my business or rental?</SectionHeadingWrapper>
              <p>
                A certificate holder must notify the County Tax Administrator in writing 30 days in advance of the sale or transfer date, to include the name and address of the purchaser or transferee (Section 4.16.080). Send your notification to:
              </p>
              <p>
                <div>Placer County Administrative Services Department</div>
                <div>Revenue Services Division</div>
                <div>10810 Justice Center Drive</div>
                <div>Suite 100</div>
                <div>Roseville, CA 95678</div>
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-dispute-penalty">
              <SectionHeadingWrapper>What do I do if I dispute a penalty and interest assessment by the County?</SectionHeadingWrapper>
              <p>
                A certificate holder may request a waiver of penalties and interest by the County Executive Officer or their designee by submitting a written request to Revenue Services within seven working days after notification
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-options-denied">
              <SectionHeadingWrapper>What are my options if my appeal is denied?</SectionHeadingWrapper>
              <p>
                Any operator aggrieved by any decision of the County Executive Officer or their designee with respect to the amount of such tax, interest and/or penalties, as assessed under the provisions of Section 4.16.080 or 4.16090, may file a written notice of appeal within 10 working days of the mailing of the determination.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-exemption">
              <SectionHeadingWrapper>Exemption to TOT</SectionHeadingWrapper>
              <p>
                If the renter is a federal or state officer or employee on official business, they can apply for exemption to TOT by completing and filing the <HelpAnchor target="_blank" href="https://www.placer.ca.gov/DocumentCenter/View/554/Exemption-Application-PDF"> exemption application (PDF)</HelpAnchor> at the time the occupancy is agreed to, prior to taking occupancy of the rental. A contractor for the government agency cannot apply for exemption.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-online-payment">
              <SectionHeadingWrapper>Online Payment</SectionHeadingWrapper>
              <p>
                Make an online payment using a debit or credit card (Discover, MasterCard, VISA and American Express accepted), or an electronic fund transfer (EFT). For processing the payment transaction, you will be informed of any charges and fees before you authorize payment. The fee is non-refundable.
              </p>
            </SectionWrapper>
            <SectionWrapper id="tot-copy-uniform">
              <SectionHeadingWrapper>Where can I find a copy of the Uniform Transient Occupancy Tax Code?</SectionHeadingWrapper>
              <p>
                The full tax code is available in the <HelpAnchor target="_blank" href="http://qcode.us/codes/placercounty/view.php?topic=4-4_16&frames=on">Placer County Code website</HelpAnchor>.
              </p>
            </SectionWrapper>

            <HelpSectionHeader style={{ marginTop: 80 }}>Short Term Rental</HelpSectionHeader>
            <SectionWrapper id="short-term-rental">
              <SectionHeadingWrapper>Short-Term Rental</SectionHeadingWrapper>
              <p>
                Short-term rentals are residential units rented for 30 days or less, typically advertised as vacation rentals.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-permitting-information">
              <SectionHeadingWrapper>Permitting Information</SectionHeadingWrapper>
              <p>
                All short-term rentals in eastern Placer County that are located above the 5,000-foot elevation must have a permit to operate along with a Transient Occupancy Tax (TOT) certificate. March 31* of each year is the deadline for property owners to apply, and that authority may be delegated to an agent with written permission. This web page is here to conveniently guide you through the simple online process of registering as well as submitting taxes and fees.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-permit-costs">
              <SectionHeadingWrapper>Permit Costs</SectionHeadingWrapper>
              <PermitContent>
                <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Property Management</div>
                <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>STR Permit</div>
                <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Fire Inspection</div>
                <div style={{ color: "#5c5d5d", fontWeight: 'bold' }}>Total Fee</div>
                <div>Professionally Managed Properties</div>
                <div>$105.00</div>
                <div>$95.18</div>
                <div>$200.18</div>
                <div>Privately Managed Properties</div>
                <div>$179.00</div>
                <div>$158.13</div>
                <div>$337.13</div>
              </PermitContent>
            </SectionWrapper>
            <SectionWrapper id="maximum-occupancy">
              <SectionHeadingWrapper>Maximum Occupancy</SectionHeadingWrapper>
              <p>
                Maximum occupancy is defined as two people per bedroom, plus two additional people, excluding children under 16 years of age. A “bedroom” means a room containing a minimum of 70 square feet that meets all requirements of the California Residential Code and contains a window or opening for emergency egress structures.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-renewal">
              <SectionHeadingWrapper>Renewal</SectionHeadingWrapper>
              <p>
                Renewal of the 12-month permit must occur prior to the expiration date unless revoked or suspended earlier. The permit and renewal fees are non-refundable. To be eligible for renewal the unit must have a bear bin installed by March 31, 2021.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-permit-exemptions">
              <SectionHeadingWrapper>Permit Exemptions</SectionHeadingWrapper>
              <p>
                Short-term rentals within existing resorts and residential associations may be exempt from permit requirements at the discretion of the Community Development Resource Agency Director. To determine if the resort or association is exempt from permit requirements, please call 530-581-6234 or email <HelpAnchor target="_blank" href="mailto:str@placer.ca.gov">str@placer.ca.gov</HelpAnchor> to schedule an appointment with County staff.  Interested parties (i.e. resort or HOA representative) should bring a written exemption request and the supplemental information described in the <HelpAnchor target="_blank" href="https://www.placer.ca.gov/DocumentCenter/View/41998/Exemption-Request-List-of-Required-Information">list of required</HelpAnchor> information for staff to review, discuss and render a decision if satisfactory information is provided to support the requested exemption.
              </p>
              <p>
              HOA’s may collectively represent the parcels within the association if so desired. In these instances, a representative of the association is to remit a schedule of parcels/addresses represented along with the items identified above for exemption consideration.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short=property-management">
              <SectionHeadingWrapper>Property Management</SectionHeadingWrapper>
              <p>
                A participating property management company must be a licensed firm to request inspections, pay the permit/renewal fees and the Transient Occupancy Tax.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-ordinance-standards">
              <SectionHeadingWrapper>Short-Term Rental Ordinance Standards</SectionHeadingWrapper>
              <p>
                Property owners are responsible for reviewing the entire short-term rental ordinance, but the following standards provide an abbreviated version:
              </p>
              <ul>
                <li>
                  <span>Property Owner&apos;s Responsibility</span>
                  <div>It is the responsibility of the property owner to prevent nuisance behavior and maintain neighborhood peace and quiet.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Local Contact</span>
                  <div>A local contact person must be personally available by telephone on a 24-hour basis with the ability to be physically present at the rental within one hour of being contacted by code compliance, the Placer County Sheriff’s Office or the guest(s).</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Parking</span>
                  <div>On-site parking must be provided, or an off-site parking plan needs to be submitted for county approval if no on-site parking is available.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Noise</span>
                  <div>Nighttime noise limits are from 10 p.m. to 7 a.m. and must be posted within the rental.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Trash and Refuse</span>
                  <div>Weekly trash collection must be provided, and guests are prohibited from accumulating trash outside unless it is deposited in a proper “animal proof” enclosure such as a bear bin.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Interior Posting Requirements</span>
                  <div>The Placer County Good Neighbor Flyer shall be posted within the interior of the rental in a visual location. The posting must contain the local contact person’s phone number, emergency evacuation information and a notice of financial penalties for violations.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Visible Address</span>
                  <div>The property’s address must be visible from the street.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Emergency Communications</span>
                  <div>A telephone landline, Voice Over Internet Protocol or a monitored alarm system must be available in homes with poor cellular service.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Fire Inspections</span>
                  <div>Life safety fire inspections are required once every three years by the local fire department to ensure the rental complies with requirements related to fire extinguishers, smoke and carbon monoxide alarms, emergency communications, fireplaces, grills and barbeques.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Fire</span>
                  <div>Guests are prohibited from having open fire pits, bonfires or campfires.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Grills and Barbeques</span>
                  <div>Charcoal grills of any type are prohibited but gas grills and barbeques are permitted outside if they are located at least 10 feet from a structure or flammable source.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Camping</span>
                  <div>Overnight camping and recreational vehicles are not allowed on the property. </div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Special Events</span>
                  <div>A county permit must be obtained to host an event that has the potential to cause noise, traffic and parking problems. Examples of special events requiring a permit include but is not limited to weddings, corporate events, and commercial functions.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Subletting</span>
                  <div>Guests are prohibited from subletting a short-term rental.</div>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Deed Restrictions</span>
                  <div>A property with a deed restriction or agreement restricting its use cannot be used as a short-term rental.</div>
                </li>
              </ul>
            </SectionWrapper>
            <SectionWrapper id="short-violations">
              <SectionHeadingWrapper>Violations</SectionHeadingWrapper>
              <p>
                The fine for a code violation is up to $500 per day for each violation in the first citation and up to $1,000 per day for each violation in the second citation. A second citation is one that occurs within the same 12-month period as the first citation. Placer County Code Compliance may deny, revoke or suspend a short-term rental permit. Please refer to the ordinance for details regarding non-compliance.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-appeal">
              <SectionHeadingWrapper>Appeal</SectionHeadingWrapper>
              <p>
                An appeal of a penalty or decision on a permit application may be filed in writing within 10 calendar days of the notification. Written appeals can be sent to 775 North Lake Boulevard, Tahoe City, CA 96145 or emailed to str@placer.ca.gov.
              </p>
            </SectionWrapper>
            <SectionWrapper id="short-contact">
              <SectionHeadingWrapper>Contact</SectionHeadingWrapper>
              <p>
                For questions about Placer County’s Short-Term Rental Program, please contact:
              </p>
              <div>530-581-6234</div>
              <div><HelpAnchor target="_blank" href="mailto:str@placer.ca.gov">str@placer.ca.gov</HelpAnchor></div>
              <div>Code Compliance Services</div>
            </SectionWrapper>
          </ScrollingHelpContainer>
        </Grid.Column>
      </Grid>
    </HelpWrapperContainer>
  </>
);

export default Help;
