import { Input, Search } from 'semantic-ui-react';
import styled from 'styled-components';

export const SectionHeadingWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

export const RowWrapper = styled.div`
  margin: 1rem 0;
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const FieldInput = styled(Input)`
  margin: 0.5rem 0;
  width: 250px;
`;

export const LocationInput = styled(Search)`
  width: 100%;
  margin: 0.5rem 0;
  > .ui.icon.input {
    width: 100%;
    > .prompt {
      border-radius: 4px;
    }
  }

  > .results.transition {
    width: 100%;
  }
  ::focus {
    border-color: #027680;
  }
`;
