import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Input } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';
import {
  UPDATE_HAS_UNIT_NUMBER,
  UPDATE_MULTIPLE_UNIT,
  UPDATE_NO_OF_UNITS,
  UPDATE_SECONDARY_DWELLING,
  UPDATE_TOT_CERTIFICATION_STEP,
  UPDATE_UNIT_NUMBER,
} from '../../state/actions';
import { CertificateContext } from '../../state/context';
import { INPUT_MAX_LENGTHS } from '../common/constants';
import { ERROR_MESSAGES } from '../common/error-constants';
import { ButtonWrapper } from './navigation-buttons-styles';
import { StyledErrorMessage } from './tot-address-styles';
import { RentalTypesRadioMenu } from './tot-property-rental-types';
import {
  HeaderWrapper,
  RentalTypeDetailsWrapper,
  CheckboxWrapper,
  WrapperDiv,
} from './tot-property-styles';

const TotProperty = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CertificateContext);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(ERROR_MESSAGES.NoErrors);

  const {
    rentalType,
    unitNumber,
    noOfUnits,
    hasUnitNumber,
    multipleUnit,
    secondaryDwelling,
  } = state;

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 1 },
    });
  }, [dispatch]);

  //Validations to proceed to the next screen
  useEffect(() => {
    let isValidUnitNumber = false;
    let hasValidMultipleUnits = false;

    if (rentalType) {
      if (hasUnitNumber && Boolean(unitNumber)) isValidUnitNumber = true;
      if (multipleUnit && Boolean(noOfUnits)) hasValidMultipleUnits = true;

      if (!hasUnitNumber && !multipleUnit) {
        setIsNextDisabled(false);
      } else if (hasUnitNumber && isValidUnitNumber && !multipleUnit) {
        setIsNextDisabled(false);
      } else if (!hasUnitNumber && multipleUnit && hasValidMultipleUnits) {
        setIsNextDisabled(false);
      } else if (isValidUnitNumber && hasValidMultipleUnits) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    }
  }, [noOfUnits, rentalType, hasUnitNumber, multipleUnit, unitNumber]);

  // Dispatches
  const handleRentalUnitInfoChange = (e, { name, value }) => {
    if (name === 'unitNumber') {
      dispatch({ type: UPDATE_HAS_UNIT_NUMBER, payload: { hasUnitNumber: !hasUnitNumber } });
    } else if (name === 'secondaryDwelling') {
      dispatch({ type: UPDATE_SECONDARY_DWELLING, payload: { secondaryDwelling: !secondaryDwelling } });
    } else {
      dispatch({ type: UPDATE_MULTIPLE_UNIT, payload: { multipleUnit: !multipleUnit } });
    }
  };

  const handleInputChange = (e, {name, value}) => {
    let parsedValue = value;
    if (value < 1) parsedValue = 0;
    if (value > Number.MAX_SAFE_INTEGER) parsedValue = Number.MAX_SAFE_INTEGER;

    if (name === 'unitNumber') {
      dispatch({
        type: UPDATE_UNIT_NUMBER,
        payload: { unitNumber: parsedValue },
      });
    } else if (name === 'noOfUnits') {
      dispatch({
        type: UPDATE_NO_OF_UNITS,
        payload: { noOfUnits: parsedValue },
      });
    }
  };

  const handleNextClick = () => {
    setIsLoading(true);
    setErrors(ERROR_MESSAGES.NoErrors);

    if (!rentalType) {
      setIsLoading(false);
      setErrors(ERROR_MESSAGES.MissingRentalType);
    } else {
      setIsLoading(false);
      history.push(ApplyTotRouteMap[history.location.pathname]);
    }
  }

  return (
    <>
      <HeaderWrapper data-cy="select-rental-label">
        <p>Select the type of the rental: (Select One) </p>
      </HeaderWrapper>

      <RentalTypesRadioMenu/>

      <RentalTypeDetailsWrapper>
        <HeaderWrapper>
          <p>Select all that apply: </p>
        </HeaderWrapper>
        <CheckboxWrapper>
          <Checkbox
            label='The property has a unit number.'
            name='unitNumber'
            checked={hasUnitNumber}
            onChange={handleRentalUnitInfoChange}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            label='The rental unit is a secondary dwelling.'
            name='secondaryDwelling'
            checked={secondaryDwelling}
            onChange={handleRentalUnitInfoChange}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            label=' More than one residential unit is proposed to be used as a short-term rental.'
            name='multipleUnit'
            checked={multipleUnit}
            onChange={handleRentalUnitInfoChange}
          />
        </CheckboxWrapper>
      </RentalTypeDetailsWrapper>

      <RentalTypeDetailsWrapper>
        {hasUnitNumber && (
          <>
            <HeaderWrapper>
              <p>What is the unit number?</p>
            </HeaderWrapper>
            <WrapperDiv>
              <Input
                type='number'
                name='unitNumber'
                value={unitNumber}
                onChange={(e, data) => handleInputChange(e, data)}
                label={{ icon: 'asterisk' }}
                labelPosition='right corner'
                maxLength={INPUT_MAX_LENGTHS.UNIT_NUMBER}
              />
            </WrapperDiv>
          </>
        )}

        {multipleUnit && (
          <>
            <HeaderWrapper>
              <p>How many units are proposed to be used as a short-term rental?</p>
            </HeaderWrapper>
            <WrapperDiv>
              <Input
                type='number'
                name='noOfUnits'
                value={noOfUnits}
                onChange={(e, data) => handleInputChange(e, data)}
                label={{ icon: 'asterisk' }}
                labelPosition='right corner'
              />
            </WrapperDiv>
          </>
        )}
      </RentalTypeDetailsWrapper>

      {errors && <StyledErrorMessage negative>{errors}</StyledErrorMessage>}

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button
          onClick={handleNextClick}
          color='black'
          loading={isLoading}
          disabled={isLoading || isNextDisabled}
        >
          Continue
        </Button>
      </ButtonWrapper>


      <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '3rem 0' }}>
        <img src='../../../assets/apply-tot-prop-details.svg' alt='property details' />
      </div>
    </>
  );
};

export default TotProperty;
