import React, { useEffect, useState, useRef, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { APP_CONTEXT } from '../components/common/constants';
import {
  CertificateContext,
  PaymentContext,
  UpdateTOTContext,
} from '../state/context';
import {
  UPDATE_PAYMENT_STEP,
  UPDATE_TOT_CERTIFICATION_STEP,
} from '../state/actions';
import { Auth } from 'aws-amplify';

// state context objects
const availableContext = {
  [APP_CONTEXT.certificate]: CertificateContext,
  [APP_CONTEXT.payment]: PaymentContext,
  [APP_CONTEXT.updatetot]: UpdateTOTContext,
};

// state actions for updating current step
const stepUpdater = {
  [APP_CONTEXT.certificate]: UPDATE_TOT_CERTIFICATION_STEP,
  [APP_CONTEXT.payment]: UPDATE_PAYMENT_STEP,
};

// route to bounce back to in case of authentication error
const closestUnauthenticatedRoute = {
  [APP_CONTEXT.certificate]: '/apply-tot',
  [APP_CONTEXT.payment]: '/pay-tot',
  [APP_CONTEXT.updatetot]: '/update-info',
};

/**
 * Route Wrapper for checking if user has the correct authentication to view
 * the current component at this route. Comparison is performed by checking the
 * route context totCertId against the current "loggedIn" user. If the user
 * does not match, the user is logged out and sent to the nearest unauthenticated
 * route.
 * @param {ReactChildren} children component(s) to render when authenticated
 * @param {string} context current workflow for the authenticated route
 */
export function AuthenticatedRoute({ children, context }) {
  // Can user see this component
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  // is the component mounted in the dom
  const isMounted = useRef(null);
  // location via hook
  const location = useLocation();
  // current workflow context based on param context
  const currentContext = availableContext[context];
  const { state, dispatch } = useContext(currentContext);
  // the user IS the the totCertId
  const currentUser = state?.totCertId;

  // utility function for checking mount state before updating state
  const setAuthenticationIfMounted = (state) => {
    if (isMounted.current) {
      setIsAuthenticated(state);
    }
  };

  useEffect(() => {
    // is the route in the dom or not
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  /**
   * Here runs the logic for bouncing user back to nearest unauthenticated route
   * if the user is not authenticated
   */
  useEffect(() => {
    async function getCurrentAuthenticatedUser() {
      const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => {
        setAuthenticationIfMounted(false);
      });
      if (currentUser && cognitoUser?.username === currentUser.toString()) {
        setAuthenticationIfMounted(true);
      } else {
        // User has outdated credentials
        setAuthenticationIfMounted(false);
        dispatch({
          type: stepUpdater[context],
          payload: { currentStep: 0 },
        });
        Auth.signOut();
      }
    }
    getCurrentAuthenticatedUser();
  });

  // if the user is auth, render component, else bounce back
  return isAuthenticated ? (
    <Route path={location.pathname}>{children}</Route>
  ) : (
    <Redirect
      to={{
        pathname: closestUnauthenticatedRoute[context],
      }}
    />
  );
}
