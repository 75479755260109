import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';
import {
  UPDATE_LOCAL_CONTACT_INFO,
  UPDATE_MANAGER_INFO,
  UPDATE_TOT_CERTIFICATION_STEP,
} from '../../state/actions';
import { CertificateContext } from '../../state/context';
import {
  contactFormValidation,
  getAddressSuggestions,
} from '../../utils/helper-functions';
import { contactInfoWhiteSpaceTrimmer } from '../../utils/utility-functions';
import { HeaderWrapper } from '../common/common-styles';
import { localContactLabels, propertyManagerLabels } from '../common/constants';
import { ERROR_MESSAGES } from '../common/error-constants';
import ContactSectionAddress from './contact-section-address';
import { ButtonWrapper } from './navigation-buttons-styles';
import { StyledErrorMessage } from './tot-address-styles';
import { SectionHeadingWrapper } from './tot-contact-manager-styles';


const TotContactManager = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CertificateContext);
  const [initialDataLoading, setInitialDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(ERROR_MESSAGES.NoErrorsArray);

  // For auto-suggestions of property manager address
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // For auto-suggestions of local point of contact address
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [localSuggestions, setLocalSuggestions] = useState([]);

  const { managerInfo, localContactInfo, manageRental, localContact } = state;

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 3 },
    });
  }, [dispatch]);

  useEffect(() => {
    /**
     * Address may come in as null
     * Default to empty string, if address is null
     * Prevents React error about value of input being null instead empty string
     */
    setSearchTerm(managerInfo.address || '');
    setLocalSearchTerm(localContactInfo.address || '');
    setInitialDataLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Property manager address suggestions
  useEffect(() => {
    if (Boolean(searchTerm)) {
      (async () => setSuggestions(await getAddressSuggestions(searchTerm)))();
    } else if (initialDataLoading) {
      updateManagerInfo({ ...managerInfo, address: searchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // Local point of contact address suggestions
  useEffect(() => {
    if (Boolean(localSearchTerm)) {
      (async () => setLocalSuggestions(await getAddressSuggestions(localSearchTerm)))();
    } else if (initialDataLoading) {
      updateLocalContactInfo({ ...localContactInfo, address: localSearchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchTerm]);

  // Dispatches
  const updateManagerInfo = (payload) => {
    dispatch({
      type: UPDATE_MANAGER_INFO,
      payload,
    });
  };

  const updateLocalContactInfo = (payload) => {
    dispatch({
      type: UPDATE_LOCAL_CONTACT_INFO,
      payload,
    });
  };

  // Click Handlers
  const handleNextClick = () => {
    setIsLoading(true);
    setErrors(ERROR_MESSAGES.NoErrorsArray);
    let errorMsgs = [];

    /**
     * Validate property manager info if and only if,
     *  manageRental (operatesRental) is the property manager
     */
    if (manageRental === 'property_mgr') {
      updateManagerInfo(contactInfoWhiteSpaceTrimmer(managerInfo));

      const managerLabel = 'property manager';
      const formErrors = contactFormValidation(
        managerInfo,
        ['company'],
        managerLabel,
      );

      if (formErrors) errorMsgs.push(formErrors);
    }

    // Validate local point of contact info
    if (manageRental === 'owner' && localContact !== 'property_mgr') {
      updateLocalContactInfo(
        contactInfoWhiteSpaceTrimmer(localContactInfo)
      );

      const localPOCLabel = 'local contact person';
      const localPOCFormErrors = contactFormValidation(
        localContactInfo,
        ['address'],
        localPOCLabel,
      );

      if (localPOCFormErrors) errorMsgs.push(localPOCFormErrors);
    }

    if (errorMsgs.length) {
      setErrors(errorMsgs);
    } else {
      // Go to online marketplace listings if there are no errors
      history.push(ApplyTotRouteMap[history.location.pathname]);
    }
    setIsLoading(false);
  }

  return (
    <>
      <HeaderWrapper>Local Point of Contact Information</HeaderWrapper>

      <Grid stackable>
        <Grid.Column width={8}>
          {manageRental === "property_mgr" &&
            <>
              <SectionHeadingWrapper>Property Manager Information</SectionHeadingWrapper>
              <ContactSectionAddress
                labels={propertyManagerLabels}
                info={managerInfo}
                updateInfo={updateManagerInfo}
                searchTerm={searchTerm}
                suggestions={suggestions}
                setSearchTerm={setSearchTerm}
              />
            </>
          }

          {manageRental === 'property_mgr' && localContact === 'property_mgr' ? null : (
            <span>
              <SectionHeadingWrapper>24-Hour Local Contact Person Information</SectionHeadingWrapper>
              <ContactSectionAddress
                labels={localContactLabels}
                info={localContactInfo}
                updateInfo={updateLocalContactInfo}
                searchTerm={localSearchTerm}
                suggestions={localSuggestions}
                setSearchTerm={setLocalSearchTerm}
              />
            </span>
          )}
        </Grid.Column>
      </Grid>

      {Boolean(errors.length) &&
        <StyledErrorMessage negative>
          {errors.map((error, idx) => error)}
        </StyledErrorMessage>
      }

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>
        <Button
          onClick={handleNextClick}
          color='black'
          loading={isLoading}
          disabled={isLoading}
        >
          Continue
        </Button>
      </ButtonWrapper>

    </>
  );
};

export default TotContactManager;
