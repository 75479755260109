import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import TotOwnerInfo from '../components/apply-tot/apply-tot-owner-info';
import TotAddress from '../components/apply-tot/tot-address';
import TotConfirmDetails from '../components/apply-tot/tot-confirm-details';
import TotConfirmation from '../components/apply-tot/tot-confirmation';
import TotContactManager from '../components/apply-tot/tot-contact-manager';
import TotListings from '../components/apply-tot/tot-listings';
import TotProperty from '../components/apply-tot/tot-property';
import TotSign from '../components/apply-tot/tot-sign';
import { CertificateContext } from '../state/context';


export const ApplyTotRoutes = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { state } = useContext(CertificateContext);

  // case use hit reload button, but not in root path
  if (!state.apn && path !== pathname) {
    return <Route render={() => <Redirect to={{ pathname: path }} />} />
  }

  return (
    <Switch>
      <Route exact path={path}>
        <TotAddress />
      </Route>
      <Route path={`${path}/property`}>
        <TotProperty />
      </Route>
      <Route path={`${path}/confirm`}>
        <TotConfirmation />
      </Route>
      <Route path={`${path}/owner-info`}>
        <TotOwnerInfo />
      </Route>
      <Route path={`${path}/tot-sign`}>
        <TotSign />
      </Route>
      <Route path={`${path}/confirm-details`}>
        <TotConfirmDetails />
      </Route>
      <Route path={`${path}/listings`}>
        <TotListings />
      </Route>
      <Route path={`${path}/contact-manager`}>
        <TotContactManager />
      </Route>
      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  );
};
