import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
`;

export const RentalTypeWrapper = styled.div`
  margin: 1rem 0;
`;

export const RentalTypeDetailsWrapper = styled.div`
  margin: 2rem 0;
`;

export const CheckboxWrapper = styled.div`
  margin: 1rem 0;
`;

export const WrapperDiv = styled.div`
  margin: 1rem 0;
  @media only screen and (max-width: 768px) {
    .input {
      display:flex!important;
    }
  }
`;
