import { Link } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
`;

export const ButtonWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  color: #027680;
  text-decoration: underline;
  &:hover {
    color: #027680;
  }
`;

export const StyledInput = styled(Input)`
  width: 200px;
  input {
    &&& {
      ::focus {
        border-color: #027680 !important;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  width: 100px;
`;

export const SectionHeader = styled.div`
  color: #7f8fa4;
  font-size: 10px;
  font-weight: bold;
`;

export const ContentWrapper = styled.div`
  padding: 1rem 0;
`;

export const ImageWrapper = styled.div`
  margin-top: 8rem;
`;

export const SectionContent = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
