import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { AuthenticatedRoute } from './authenticated-route';
import { APP_CONTEXT } from '../components/common/constants';
import FindTot from '../components/update-tot/find-tot';
import UpdateDetails from '../components/update-tot/update-details';
import UpdateConfirmation from '../components/update-tot/update-confirmation';
import { UpdateContact } from '../components/update-tot/update-contact';
import { UpdateListings } from '../components/update-tot/update-listings';
import { UpdateReview} from '../components/update-tot/update-review'
const UpdateTotRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <FindTot />
      </Route>

      <AuthenticatedRoute
        path={`${path}/update-details`}
        context={APP_CONTEXT.updatetot}
      >
        <UpdateDetails />
      </AuthenticatedRoute>

      <AuthenticatedRoute
        path={`${path}/update-contact`}
        context={APP_CONTEXT.updatetot}
      >
        <UpdateContact/>
      </AuthenticatedRoute>

      <AuthenticatedRoute
        path={`${path}/update-review`}
        context={APP_CONTEXT.updatetot}
      >
        <UpdateReview/>
      </AuthenticatedRoute>

      <AuthenticatedRoute
        path={`${path}/update-listings`}
        context={APP_CONTEXT.updatetot}
      >
        <UpdateListings/>
      </AuthenticatedRoute>

      <Route
        path={`${path}/confirm`}
        context={APP_CONTEXT.updatetot}
      >
        <UpdateConfirmation />
      </Route>

      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  );
};

export default UpdateTotRoutes;
