import Axios from "axios";
import { ERROR_MESSAGES } from "../components/common/error-constants";

const parseDigits = (string) => (string.match(/\d+/g) || []).join('');

export const formatParcelNumber = (string) => {
  // console.log('string', string);
  const digits = parseDigits(string);
  // console.log('digits', digits);
  const chars = digits.split('');
  // console.log('chars', chars);
  return chars
    .reduce((r, v, index) => (index === 3 || index === 6 || index === 9 ? `${r}-${v}` : `${r}${v}`), '')
    .substr(0, 15);
};

export const formatPhoneNumber = (string) => {
  if (!string) return '';
  // console.log('string', string);
  const digits = parseDigits(string);
  // console.log('digits', digits);
  const chars = digits.split('');
  // console.log('chars', chars);
  return chars.reduce((r, v, index) => (index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`), '').substr(0, 12);
};

export const checkIsValidNumber = (input) => {
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export const checkIsValidDecimal = (input) => {
  // const pattern = /^\d*\.{0,1}\d+$/;
  const pattern = /^\d*(\.\d{0,2})?$/;
  return pattern.test(input);
};

export const checkIfValidEmail = (input) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(input);
};

/**
 * Returns an error message based on the data pass in.
 * Do NOT add optional data/inputs.
 * @param {Object} contactInfo dictionary that contains the key-value pairs to check
 * @param {string[]} [ignoredFields=[]] list of keys to ignore in contact info
 * @param {string} [personLabel] either "property manager" or "local contact person"
 *  can come in as undefined/null when called to validate "owner" information
 */
export const contactFormValidation = (contactInfo, ignoredFields = [], personLabel) => {
  for (let key in contactInfo) {
    if (ignoredFields && ignoredFields.includes(key)) continue;
    if (!Boolean(contactInfo[key])) {
      return Boolean(personLabel) ?
        ERROR_MESSAGES.EmptyFieldsLabeled(personLabel) :
        ERROR_MESSAGES.EmptyFields
    }
  }

  const { phone, email } = contactInfo;
  const phoneIsInvalid = phone.length !== 12;
  const emailIsInvalid = !checkIfValidEmail(email);

  if (phoneIsInvalid && emailIsInvalid) {
    return Boolean(personLabel) ?
      ERROR_MESSAGES.InvalidPhoneAndEmailLabeled(personLabel) :
      ERROR_MESSAGES.InvalidPhoneAndEmail;
  } else if (phoneIsInvalid) {
    return Boolean(personLabel) ?
      ERROR_MESSAGES.InvalidPhoneNumberLabeled(personLabel) :
      ERROR_MESSAGES.InvalidPhoneNumber;
  } else if (emailIsInvalid) {
    return Boolean(personLabel) ?
      ERROR_MESSAGES.InvalidEmailAddressLabeled(personLabel) :
      ERROR_MESSAGES.InvalidEmailAddress;
  }
}

/**
 * Returns an array (length 10) of suggested address within California
 * TODO: Update for places beyond California
 * @param {string} searchTerm an address
 */
export const getAddressSuggestions = async (searchTerm) => {
  const url = 'https://us-autocomplete-pro.api.smartystreets.com/lookup';
  const params = {
    search: searchTerm,
    key: '53172441788738393',
    license: 'us-autocomplete-pro-cloud',
    include_only_cities: 'CA',
  };

  try {
    const { data } = await Axios({
      method: 'get',
      url,
      params,
      headers: { 'Content-Type': 'application/json' },
    });

    let addressSuggestions = [];

    if (data.suggestions) {
      addressSuggestions = data.suggestions.map(({ street_line, secondary, city, state, zipcode }) => ({
        title: `${street_line} ${secondary} ${city} ${state} ${zipcode}`,
      }));
    }

    return addressSuggestions;
  } catch (err) {
  }
}

/**
 * Returns raw value for localContact as it is consumed by the internals of our forms
 *
 * @param {*} manageRental certificate owner
 * @param {*} localContact local contact value we would in the payload to a server request
 */
export function getRawLocalContact(manageRental, localContact) {
  if (!localContact) return null;
  return (manageRental === localContact) ? 'certificateHolder' : localContact;
}

/**
 * Returns the localContact value as interpreted from who is the certificateOwner, and
 * the value of the rawLocalContact as consumed in our forms
 *
 * @param {string} manageRental certifcate owner
 * @param {string | null} rawLocalContact raw value for local contact as consumed by forms
 */
export function localContactFromRawLC(manageRental, rawLocalContact) {
  let newLocalContactValue = null;
  if (manageRental) {
    newLocalContactValue = (rawLocalContact === "certificateHolder") ?
      manageRental : rawLocalContact;
  } else {
    newLocalContactValue = (rawLocalContact === "certificateHolder") ?
      null : rawLocalContact;
  }
  return newLocalContactValue;
}
