import styled from "styled-components";
import { Container } from "semantic-ui-react";

export const HomeContainer = styled(Container)`
  h1 {
    margin-top: 9rem!important;
    margin-bottom: 3rem!important;
    @media (max-width: 768px) {
      margin-top: 0!important;
      margin-bottom: 0!important;
    }
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  & > * {
    display: flex;
  }
  @media (max-width: 1028px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const CardGridItem = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
`;

