import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Routes from './routes';
import { appActions } from './app/app-slice';
import './App.css';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchAppData() {
      // TODO: Send request to our server
      const res = await fetch('https://b3ad03af-79f6-4748-af43-14c0adfd0fa3.mock.pstmn.io/stats');
      const stats = await res.json();
      dispatch(appActions.serverReturnedStats({ stats }));
    })();
  }, []);

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
