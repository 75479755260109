import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';
import { UPDATE_LISTINGS, UPDATE_TOT_CERTIFICATION_STEP } from '../../state/actions';
import { CertificateContext } from '../../state/context';
import { ListingOptions } from '../../utils/dropdown-options';
import { HeaderWrapper, HeaderWrapperBold } from '../common/common-styles';
import { INPUT_MAX_LENGTHS } from '../common/constants';
import { ButtonWrapper } from './navigation-buttons-styles';
import {
  ButtonSectionWrapper,
  GridWrapper,
  FieldLabel,
  FieldInputWrapper,
  FullWidthInput,
  ImageWrapper,
  ListingUrlExample,
} from './tot-listings-styles';
import isURL from 'validator/es/lib/isURL';

const TotListings = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CertificateContext);

  const { listingUrls: listings } = state;

  const validUrlListings = [...listings.filter((item) => isURL(item))]

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 4 },
    });
  }, [dispatch]);

  // Dispatches
  const handleAddListing = () => {
    dispatch({
      type: UPDATE_LISTINGS,
      payload: { listingUrls: [...validUrlListings, ''] },
    });
  };

  const handleListingChange = (e, data, index) => {
    dispatch({
      type: UPDATE_LISTINGS,
      payload: {
        listingUrls: [
          ...listings.map((item, i) => {
            if (i === index) {
              item = data.value;
            }
            return item;
          }),
        ],
      },
    });
  };

  // Click Handlers
  const handleNextClick = () =>
    history.push(ApplyTotRouteMap[history.location.pathname]);

  const sanitizeAndNextClick = () => {
    handleAddListing();
    handleNextClick();     
  }
  return (
    <>
      <HeaderWrapper>
        Please report each listing advertising your short-term rental. Adding
        your listing makes payment verification process easier.
      </HeaderWrapper>
      <HeaderWrapperBold>This is optional.</HeaderWrapperBold>

      <GridWrapper>
        <Grid stackable>
          {listings &&
            listings.map((listing, $index) => (
              <>
                <Grid.Column key={$index} width={4}>
                  <div>
                    <FieldLabel>Listing Platform</FieldLabel>
                    <FieldInputWrapper>
                      <Dropdown fluid selection options={ListingOptions} />
                    </FieldInputWrapper>
                  </div>
                </Grid.Column>

                <Grid.Column width={12}>
                  <div>
                    <FieldLabel>URL</FieldLabel>
                    <FieldInputWrapper>
                      <FullWidthInput
                        value={listing}
                        onChange={(e, data) =>
                          handleListingChange(e, data, $index)
                        }
                        maxLength={INPUT_MAX_LENGTHS.URL}
                      />
                    </FieldInputWrapper>
                  </div>
                </Grid.Column>
              </>
            ))}
        </Grid>
        <ListingUrlExample>
          Example: https://www.airbnb.com/rooms/12345678
        </ListingUrlExample>
      </GridWrapper>

      <ButtonSectionWrapper>
        <Button onClick={handleAddListing}>
          <Icon name='add' />
          <span>Add listing</span>
        </Button>
      </ButtonSectionWrapper>

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button onClick={sanitizeAndNextClick} color='black'>
          Continue
        </Button>
      </ButtonWrapper>

      <ImageWrapper>
        <img src='../../../assets/apply-tot-listing.svg' alt='listings' />
      </ImageWrapper>
    </>
  );
};

export default TotListings;
