import React, { useReducer } from 'react';
import { Grid } from 'semantic-ui-react';
import { WrapperContainer } from '../components/common/common-styles';
import LayoutHeader from '../components/common/layout-header';
import { UpdateCertificateInfo } from '../components/update-tot/update-certificate-info';
import UpdateTotRoutes from '../routes/updateTot';
import { UpdateTOTContext } from '../state/context';
import { initialUpdateTotState } from '../state/initial-state';
import { updateTotReducer } from '../state/reducer';

const UpdateTOT = () => {
  const [state, dispatch] = useReducer(updateTotReducer, initialUpdateTotState);
  return (
    <>
      <UpdateTOTContext.Provider value={{ state, dispatch }}>
        <LayoutHeader title='Placer County Update TOT Certificate Information' />
        <WrapperContainer>
          <Grid stackable>
            <Grid.Column width={13}>
              <UpdateTotRoutes />
            </Grid.Column>
            <Grid.Column width={3}>
              <UpdateCertificateInfo/>
            </Grid.Column>
          </Grid>
        </WrapperContainer>
      </UpdateTOTContext.Provider>
    </>
  );
};

export default UpdateTOT;
