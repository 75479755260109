export function getCertHolderInfo(manageRental, ownerInfo, managerInfo) {
  if (manageRental === "owner") {
    return { name: ownerInfo.name, email: ownerInfo.email };
  } else if (manageRental === "property_mgr") {
    return { name: managerInfo.name, email: managerInfo.email };
  } else {
    if (!managerInfo.name === "" || !ownerInfo === "") { // initial values in empty state
      throw new Error('Invalid manageRental state');
    }
  }
}
