/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from 'react';
import { UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS, UPDATE_PAYMENT_STEP } from '../../state/actions';
import { PaymentContext } from '../../state/context';
import { getCurrentDate } from '../../utils/utility-functions';
import NavigationButtons from '../common/navigation-buttons';
import TableResponsive from '../common/table-responsive';
import {
  HeaderWrapper,
  SectionContainer,
  SignatoryWrapper,
  SignatoryInput,
  SignatoryLabel,
  AcknowledgementSectionWrapper,
  NameSectionWrapper,
  StyledCheckbox,
} from './confirm-and-sign-styles';

const ConfirmAndSign = () => {
  const { state, dispatch } = useContext(PaymentContext);
  const initialSignatoryName = state.signatoryName;
  const [signatoryName, setSignatoryName] = useState(initialSignatoryName);
  const [proceedPaymentDisabled, setProceedPaymentDisabled] = useState(true);
  useEffect(() => {
    dispatch({
      type: UPDATE_PAYMENT_STEP,
      payload: { currentStep: 2 },
    });
  }, [dispatch]);

  useEffect(() => {
    if (signatoryName.length > 0 && state.acknowledgeCheckoutDetails) {
      setProceedPaymentDisabled(false);
    } else {
      setProceedPaymentDisabled(true);
    }
  }, [signatoryName, state.acknowledgeCheckoutDetails]);
  const handleAcknowledgement = () => {
    dispatch({
      type: UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS,
      payload: {
        acknowledgeCheckoutDetails: !state.acknowledgeCheckoutDetails,
      },
    });
  };
  const details = state.checkoutDetails?.details;
  const data = details
    ?.map((detail) => ({
      dateRange: `${detail.period} ${detail.year}`,
      totDue: `$${detail.totDue.toFixed(2)}`,
      assessment: `$${detail.assessment.toFixed(2)}`,
      tbidDue: `$${detail.tbidDue.toFixed(2)}`,
      latePenalties: `$${detail.latePenalties.toFixed(2)}`,
      accruedInterest: `$${detail.accruedInterest.toFixed(2)}`,
      total: `$${detail.total.toFixed(2)}`,
    }))
    .map(({ year, period, ...rest }) => ({/* eslint-disable-line */   
      ...rest,
    })); 

  const columns = [
    { header: ' ', accessor: 'dateRange' },
    { header: 'TOT', accessor: 'totDue' },
    { header: 'Assessment', accessor: 'assessment' },
    { header: 'TBID', accessor: 'tbidDue' },
    { header: 'Late Penalties', accessor: 'latePenalties' },
    { header: 'Accrued Interests', accessor: 'accruedInterest' },
    { header: 'Total',accessor: 'total' },
  ];

  return (
    <SectionContainer>
      <HeaderWrapper>
        Please confirm the following information, and sign below.
      </HeaderWrapper>
      <TableResponsive
        columns={columns}
        data={data}
        getRowKey={(row) => row.dateRange}
        title="Amounts by quarter"

      />      
      <SignatoryWrapper>
        <NameSectionWrapper>
          <SignatoryLabel>Legal Name of Authorized Signatory</SignatoryLabel>
          <SignatoryInput
            placeholder='Enter your full name'
            value={signatoryName}
            onChange={(e, { value }) => setSignatoryName(value)}
            label={{ icon: 'asterisk' }}
            labelPosition='right corner'
          />
        </NameSectionWrapper>
        <div>
          <SignatoryLabel>Date (MM/DD/YYYY)</SignatoryLabel>
          <SignatoryInput value={getCurrentDate()} disabled />
        </div>
      </SignatoryWrapper>
      <AcknowledgementSectionWrapper>
        <StyledCheckbox
          label='I certify under penalty of perjury
        that this information is accurate and I am an authorized representative
        or owner of this property. I acknowledge that my signature is legally
        binding.'
          checked={state.acknowledgeCheckoutDetails}
          onChange={handleAcknowledgement}
        />
      </AcknowledgementSectionWrapper>
      <NavigationButtons
        nextLabel='Proceed to payment'
        isNextDisabled={proceedPaymentDisabled}
        nextPayload={signatoryName}
        nextClick='payment'
        backClick='revenue'
      />
    </SectionContainer>
  );
};

export default ConfirmAndSign;
