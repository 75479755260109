import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
  currentStep: 0,
  steps: [],
};

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState: INIT_STATE,
  reducers: {
    reset: (state) => { state.currentStep = 0; },
    increment: (state) => { state.currentStep += 1; },
    decrement: (state) => { if (state.currentStep > INIT_STATE.currentStep) state.currentStep -= 1; },
    updateSteps: (state, action) => { state.steps = action.payload },
  },
})

export const stepperActions = stepperSlice.actions;

export const stepperReducer = stepperSlice.reducer;
