import React, { useState, useCallback, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button } from 'semantic-ui-react';
import { secToMinSec } from '../../utils/utility-functions';

const DELAY_SECONDS = 180;

const CountdownButton = ({ label, onClick, forceEnabled, disabled }) => {
  const [buttonColor, setButtonColor] = useState(null);
  const springProps = useSpring({
    from: { val: 0 },
    to: { val: DELAY_SECONDS },
    config: { duration: DELAY_SECONDS * 1000 },
    onRest: () => {
      setButtonColor('black');
    },
  });

  const clickableRef = useRef(false)

  const handleButtonClick = useCallback(() => {
    if (clickableRef.current) {
      clickableRef.current = false;
      onClick();
    }
  }, [onClick]);

  if (forceEnabled) {
    return (
      <Button
        onClick={onClick}
        color='black'
        data-cy="email-verification-modal-resend"
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      onClick={handleButtonClick}
      color={buttonColor}
      disabled={disabled}
      data-cy="email-verification-modal-resend"
    >
      <span>{label}</span>
      <animated.span style={{ marginLeft: 5 }}>
        {springProps.val.interpolate((val) => {
          const secondsInteger = Math.floor(val);
          if (!clickableRef.current && (secondsInteger === DELAY_SECONDS)) {
            clickableRef.current = true;
          }
          if (secondsInteger === DELAY_SECONDS) return '';
          return `(${secToMinSec(DELAY_SECONDS - secondsInteger)})`;
        })}
      </animated.span>
    </Button>
  );
};

export default CountdownButton;
