export const CONSTSTRINGS = {
  PAYMENT_METHOD: 'Payment Method',
  CARD_INFORMATION: 'Card Information',
  TOT_CERTIFICATE: 'TOT Certificate',
  EMAIL: 'Email Address for Payment Confirmation',
};

export const CREDIT_CARD_TAB = 0;

export const ACH_PAYMENT_TAB = 1;
