import React from 'react';
import { PageBanner } from '../components/common/page-banner';
import { ApplyStrLicensesRoutes } from '../flows/apply-str-licence/apply-str-license-routes';

// TODO: Remove when state management is setup (info to be received from stats call)
const headerLabel = "Short-Term Rental License Application";
const headerLogo = "https://pbs.twimg.com/profile_images/1329221899591716865/lOftElNT_400x400.jpg";

const ApplySTR = () => (
  <>
    <PageBanner
      city="City of Burlingame"
      label={headerLabel}
      logo={headerLogo}
    />
    <ApplyStrLicensesRoutes />
  </>

);

export default ApplySTR;
