import { Accordion, Container } from 'semantic-ui-react';
import styled from 'styled-components';

export const WrapperContainer = styled(Container)`
  padding-top: 8rem;
  @media only screen and (max-width: 768px) {
    padding-top: 0rem;
  }
`;

export const WrapperDiv = styled.div`
  margin: 1rem 0;
`;

export const HeaderWrapper = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
`;

export const HeaderWrapperBold = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
  font-weight: bold;
`;

// help section styled components

export const HelpWrapperContainer = styled(Container)`
  margin-top: 8rem;
  margin-bottom: 8rem;
`;

export const StyledAccordion = styled(Accordion)`
  > .title {
    font-weight: bold;
  }
`;

export const ScrollingHelpContainer = styled.div`
  color: #5c5d5d;
  display: block;
  height: 200vh;
  min-height: 600px;
  scroll-behavior: smooth;
`;

export const AccordionContainer = styled.div`
  position: sticky;
  top: 5%;
  overflow: auto;
`;

export const SectionWrapper = styled.section`
  margin-bottom: 2rem;
  scroll-margin-top: 5rem;
`;

export const SectionHeadingWrapper = styled.div`
  color: #000;
  font-weight: bold;
  margin: 1rem 0;
`;

export const HelpSectionHeader = styled.div`
  font-size: 26px;
  margin-bottom: 3rem;
`;

export const HelpAnchor = styled.a`
  color: #027680;
  text-decoration: underline;
  :hover {
    color: #027680;
  }
`;

export const HelpContactUsWrapper = styled.div`
  margin-top: 100px;
`;

export const Link = styled.a`
  color: #027680;
  font-weight: bold;
  text-decoration: underline;
`;

export const Wrapper = styled.div`
  width: 1000px;
`;

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 8rem;
`;

export const ImageWrapper = styled.div`
  margin-top: 200px;
  display: flex;
  justify-content: flex-end;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexResponsive = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  & > * {
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
`;

export const HideMobile = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;