import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const AvailableDaysWarningModal = ({ open, setOpen }) => {
  return (
    <Modal size='mini' open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <Modal.Header> Days Exceed</Modal.Header>
      <Modal.Content>Days occupied cannot exceed days available</Modal.Content>
      <Modal.Actions>
        <Button basic content='Close' onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

export default AvailableDaysWarningModal;
