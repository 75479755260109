import React from 'react';
import { Header, Image } from 'semantic-ui-react';

const UpdateConfirmation = () => (
  <>
    <Header
      style={{ color: '#5c5d5d', fontSize: '50px', fontWeight: 'normal' }}
      size='huge'
    >
      Success!
    </Header>
    <Header style={{ fontWeight: 'normal' }}>
      Your TOT certificate information has been updated successfully! We have
      sent you an email with the updated certificate information.
    </Header>
    <Image src='../../assets/update-info-confirm.svg' />
  </>
);

export default UpdateConfirmation;
