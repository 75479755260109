import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UPDATE_TOT_CERTIFICATION_STEP } from '../../state/actions';
import { CertificateContext } from "../../state/context";
import TOTPaymentFooter from '../tot-pay/tot-pay-footer';
import { ExistingCertificatesDisplay } from './modules/tot-address/existing-certificates-display';
import { ParcelNumberSearch } from './modules/tot-address/parcel-number-search';
import { ImageWrapper } from './tot-address-styles';

function TotAddress() {
  const { dispatch } = useContext(CertificateContext);

  const [showResults, setShowResults] = useState(false);

  const View = useMemo(() => {
    if (showResults) return ExistingCertificatesDisplay;
    return ParcelNumberSearch;
  }, [showResults]);

  useEffect(() => {
    // Updates the stepper component
    dispatch({ type: UPDATE_TOT_CERTIFICATION_STEP, payload: { currentStep: 0 } });
  }, [dispatch]);

  return (
    <>
      <View setShowResults={setShowResults} />

      <ImageWrapper>
        <img src='../../../assets/apply-tot-address.svg' alt='tot address' />
      </ImageWrapper>

      <TOTPaymentFooter />
    </>
  );
}

export default TotAddress;
