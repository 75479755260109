import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Icon, Image, Message } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import { ROUTES } from '../common/constants';
import EmailVerificationModal from './email-verification-modal';
import TOTPaymentFooter from './tot-pay-footer';
import { uiSender } from '../../utils/ui-sender';
import {
  ButtonWrapper,
  SectionHeader,
  SectionContent,
  ContentWrapper,
  StyledLink,
  StyledInput,
  HeaderWrapper,
  StyledButton,
  ImageWrapper,
  StyledSubmitButton,
  StyledInputs,
} from './find-tot-styles';
import { PaymentContext } from '../../state/context';
import { UPDATE_TOT_CERT_ID } from '../../state/actions';
import {
  isAuthenticated,
  currentAuthenticatedUser,
} from '../../utils/auth-functions';
import NavigationButtonsModal from '../common/navigation-buttons-with-modal';
import * as Sentry from '@sentry/browser';
import { Alert } from 'rsuite';
import SubmitButton from '../common/submitButton';

const checkIsValidNumber = (input) => {
  const pattern = /^\d+$/;
  return pattern.test(input);
};

const FindTOT = () => {
  const [tot, setTot] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNotValidInput, setIsNotValidInput] = useState(true);
  const [isValidTOT, setIsValidTOT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [totDetails, setTOTDetails] = useState({
    totCertificate: null,
    address: null,
    certificateHolder: null,
    certificateHolderEmail: null,
  });
  const { dispatch } = useContext(PaymentContext);
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [isAuthingUser, setIsAuthingUser] = useState(false);

  useEffect(() => {
    const totNumberFromApplyTOT = location?.state?.totCertNumber;
    if (totNumberFromApplyTOT) {
      setTot(totNumberFromApplyTOT);
      const isValidNumber = checkIsValidNumber(totNumberFromApplyTOT);
      setIsNotValidInput(!isValidNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * This ref will be used for storing a reference to the cognito user instance that
   * we want to login into the application
   */
  const cognitoUserRef = useRef();

  const initiateLogin = useCallback(
    async (openModalCB) => {
      try {
        if (openModalCB) openModalCB();
        cognitoUserRef.current = await Auth.signIn(`${tot}`);
        setDisableResend(false);
      } catch (err) {
        Alert.error('Error Initiating Verification', 0, () => setOpen(false));
        setDisableResend(false);
        // cert number not found.. must notify user. Talk to James specify this state in the design
      }
    },
    [tot]
  );

  /**
   * Callback for when next button is clicked
   */
  const handleNextClick = useCallback(
    async (isOpen) => {
      setIsAuthingUser(true);
      const currentAuthUser = await currentAuthenticatedUser();
      // If the user is logged in under same tot, no need to open login modal
      if (currentAuthUser === tot) {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: tot });
        });

        setIsAuthingUser(false);
        history.push('/pay-tot/revenue');
      } else {
        setIsAuthingUser(false);
        Auth.signOut();
        initiateLogin(() => setOpen(isOpen));
      }
    },
    [tot, history, initiateLogin]
  );

  /**
   * This callback will be triggered when the user submits the authentication code.
   * On success of this operation, the user will be logged in.
   *
   * @param {string} code Authentication code
   */
  const submitCode = useCallback(
    async (code) => {
      try {
        setIsAuthingUser(true);

        // this will login the user and save tokens to localStorage
        await Auth.sendCustomChallengeAnswer(cognitoUserRef.current, code);
        const loginSuccess = await isAuthenticated();
        if (loginSuccess) {
          history.push('/pay-tot/revenue');
        } else {
          throw new Error('Bad login code'); // wrong code
        }
      } catch (err) {
        // let users know there was a problem with the login
        // console.log('err', err);
        setLoginError(true);
      }

      setIsAuthingUser(false);
    },
    [cognitoUserRef, history]
  );

  useEffect(() => {
    const isValidNumber = checkIsValidNumber(tot);
    setIsNotValidInput(!isValidNumber);
    setErrorMessage(null);
  }, [tot, dispatch]);

  const handleTotSubmit = async () => {
    setIsLoading(true);
    const { data } = await uiSender.get(`${ROUTES.totCertLookup}/${tot}`);
    const { error } = data;
    if (!error) {
      setTOTDetails({
        totCertificate: data.certId,
        address: data.propertyAddress,
        certificateHolder: data.certificateHolder,
        certificateHolderEmail: data.email,
      });
      setIsValidTOT(true);
      setErrorMessage(null);
      dispatch({
        type: UPDATE_TOT_CERT_ID,
        payload: {
          totCertId: data.certId,
          certificateHolder: data.certificateHolder,
        },
      });
    } else {
      setErrorMessage(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <EmailVerificationModal
        open={open}
        setOpen={setOpen}
        submitCode={submitCode}
        resendCode={initiateLogin}
        loginError={loginError}
        resetLoginError={() => setLoginError(false)}
        disableResend={disableResend}
        setDisableResend={setDisableResend}
      />
      <HeaderWrapper>
        <p>Let’s get started by entering your TOT certificate number:</p>
      </HeaderWrapper>
      <StyledInputs>
        <StyledInput
          icon={isValidTOT ? <Icon name="check" /> : null}
          onChange={(event) => setTot(event.target.value)}
          value={tot}
          // ref={register({ pattern: /^\d+$/ })}
          name="tot"
        />
        <SubmitButton
          onClick={handleTotSubmit}
          disabled={isNotValidInput}
          loading={isLoading}
        />
      </StyledInputs>

      {errorMessage && (
        <Message style={{ width: '300px' }} error>
          {errorMessage}
        </Message>
      )}

      <ButtonWrapper>
        <StyledLink to={`${url}/address`}>
          Help me find my certificate number
        </StyledLink>
      </ButtonWrapper>

      {isValidTOT ? (
        <div>
          <ContentWrapper>
            <SectionHeader>TOT CERTIFICATE</SectionHeader>
            <SectionContent>{totDetails.totCertificate}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>PROPERTY ADDRESS</SectionHeader>
            <SectionContent>{totDetails.address}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>CERTIFICATE HOLDER</SectionHeader>
            <SectionContent>{totDetails.certificateHolder}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>CERTIFICATE HOLDER EMAIL</SectionHeader>
            <SectionContent>{totDetails.certificateHolderEmail}</SectionContent>
          </ContentWrapper>
          <NavigationButtonsModal
            isNextDisabled={false}
            backClick='home'
            nextClick={handleNextClick}
            isLoading={isAuthingUser}
          />
        </div>
      ) : (
        <div>
          <ButtonWrapper>
            <StyledButton
              basic
              content='Back'
              onClick={history.goBack}
            />
          </ButtonWrapper>
          <ImageWrapper>
            <Image src='../../../assets/undraw-inspection-re-tbt-7.svg' />
          </ImageWrapper>
        </div>
      )}

      <TOTPaymentFooter />
    </>
  );
};

export default FindTOT;
