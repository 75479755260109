/* eslint-disable indent */

import {
  UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS,
  UPDATE_ACKNOWLEDGE_DETAILS,
  UPDATE_CHECKOUT_DETAILS,
  UPDATE_EMAIL_VERIFICATION,
  UPDATE_PAYMENT_STEP,
  UPDATE_TOT_CERT_ID,
  UPDATE_TOT_CERTIFICATION_STEP,
  UPDATE_APN_FOR_APPLY_TOT,
  UPDATE_SIGNATORY,
  UPDATE_REPORT_REVENUE_DETAILS,
  UPDATE_PROPERTY_DETAILS,
  UPDATE_OWNER_INFO,
  UPDATE_MANAGER_INFO,
  UPDATE_LOCAL_CONTACT_INFO,
  UPDATE_COMPANY_NAME,
  UPDATE_SAME_AS_RENTAL,
  UPDATE_RENTAL_MANAGER,
  UPDATE_LOCAL_CONTACT,
  UPDATE_RENTAL_TYPE,
  UPDATE_UNIT_NUMBER,
  UPDATE_NO_OF_UNITS,
  UPDATE_RENTAL_UNIT_INFO,
  UPDATE_HAS_UNIT_NUMBER,
  UPDATE_SECONDARY_DWELLING,
  UPDATE_MULTIPLE_UNIT,
  PAYMENT_METHOD_TAB_SELECTED,
  UPDATE_LISTINGS,
  UPDATE_PARCEL_NUMBER,
  UPDATE_PAYMENT_CONFIRMATION,
  UPDATE_CERT,
  UPDATE_TOT_NUMBER,
  UPDATE_INFO_UPDATE_OWNER_INFO,
  UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO,
  UPDATE_TOT_DETAILS,
  UPDATE_LOCAL_CONTACT_RAW,
} from './actions';

export const paymentReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_TOT_CERT_ID:
      return {
        ...state,
        totCertId: action.payload.totCertId,
        certificateHolder: action.payload.certificateHolder,
      };
    case UPDATE_PAYMENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case UPDATE_EMAIL_VERIFICATION:
      return {
        ...state,
        isEmailVerified: action.payload.isEmailVerified,
      };
    case UPDATE_CHECKOUT_DETAILS:
      return {
        ...state,
        checkoutDetails: action.payload.checkoutDetails,
        checkoutItems: action.payload.checkoutItems,
      };
    case UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS:
      return {
        ...state,
        acknowledgeCheckoutDetails: action.payload.acknowledgeCheckoutDetails,
      };
    case UPDATE_SIGNATORY:
      return {
        ...state,
        signatoryName: action.payload.signatoryName,
      };
    case UPDATE_REPORT_REVENUE_DETAILS:
      return {
        ...state,
        isReportReveneueDetailsSet: true,
        quarters: action.payload.quarters,
        previousQuarters: action.payload.previousQuarters,
        selectedQuarters: action.payload.selectedQuarters,
        previousQuartersChecked: action.payload.previousQuartersChecked,
        noRevenueQuarter: action.payload.noRevenueQuarter,
      };
    case PAYMENT_METHOD_TAB_SELECTED:
      return {
        ...state,
        activePaymentMethodTab: action.payload.activePaymentMethodTab,
      };
    case UPDATE_PAYMENT_CONFIRMATION:
      return {
        ...state,
        paymentConfirmation: action.payload.paymentConfirmation,
      };
    default:
      return state;
  }
};

export const certificateReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_TOT_CERTIFICATION_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case UPDATE_PARCEL_NUMBER:
      return {
        ...state,
        parcelNumber: action.payload.parcelNumber,
      };

    case UPDATE_ACKNOWLEDGE_DETAILS:
      return {
        ...state,
        userConfirmedInfoAccuracy: action.payload.userConfirmedInfoAccuracy,
      };
    case UPDATE_APN_FOR_APPLY_TOT:
      return {
        ...state,
        apn: action.payload.apn,
        propertyAddress: action.payload.propertyAddress,
        certs: action.payload.certs,
      };
    case UPDATE_PROPERTY_DETAILS:
      return {
        ...state,
        rentalType: action.payload.rentalType,
        secondaryDwelling: action.payload.secondaryDwelling,
        unitNumber: action.payload.unitNumber,
        noOfUnits: action.payload.noOfUnits,
      };
    case UPDATE_OWNER_INFO:
      return {
        ...state,
        ownerInfo: action.payload,
      };
    case UPDATE_MANAGER_INFO:
      return {
        ...state,
        managerInfo: action.payload,
      };
    case UPDATE_LOCAL_CONTACT_INFO:
      return {
        ...state,
        localContactInfo: action.payload,
      };
    case UPDATE_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload.companyName,
      };
    case UPDATE_SAME_AS_RENTAL:
      return {
        ...state,
        sameAsRental: action.payload.sameAsRental,
      };
    case UPDATE_RENTAL_MANAGER:
      return {
        ...state,
        manageRental: action.payload.manageRental,
      };
    case UPDATE_LOCAL_CONTACT:
      return {
        ...state,
        localContact: action.payload.localContact,
      };
    case UPDATE_RENTAL_TYPE:
      return {
        ...state,
        rentalType: action.payload.rentalType,
      };
    case UPDATE_UNIT_NUMBER:
      return {
        ...state,
        unitNumber: action.payload.unitNumber,
      };
    case UPDATE_NO_OF_UNITS:
      return {
        ...state,
        noOfUnits: action.payload.noOfUnits,
      };
    case UPDATE_RENTAL_UNIT_INFO:
      return {
        ...state,
        rentalUnitInfo: action.payload.rentalUnitInfo,
      };
    case UPDATE_HAS_UNIT_NUMBER:
      return {
        ...state,
        hasUnitNumber: action.payload.hasUnitNumber,
      };
    case UPDATE_SECONDARY_DWELLING:
      return {
        ...state,
        secondaryDwelling: action.payload.secondaryDwelling,
      };
    case UPDATE_MULTIPLE_UNIT:
      return {
        ...state,
        multipleUnit: action.payload.multipleUnit,
      };
    case UPDATE_LISTINGS:
      return {
        ...state,
        listingUrls: action.payload.listingUrls,
      };
    case UPDATE_CERT:
      return {
        ...state,
        certNumber: action.payload.certNumber,
      };
    default:
      return state;
  }
};

export const updateTotReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_TOT_NUMBER:
      return {
        ...state,
        totCertId: action.payload.totCertId,
        certificateHolder: action.payload.certificateHolder,
      };
    case UPDATE_INFO_UPDATE_OWNER_INFO:
      return {
        ...state,
        ownerInfo: action.payload,
      };
    case UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO:
      return {
        ...state,
        localContactInfo: action.payload,
      };
    case UPDATE_TOT_DETAILS:
      return {
        ...state,
        ownerInfo: action.payload.ownerInfo,
        localContactInfo: action.payload.localContactInfo,
        companyName: action.payload.companyName,
        managerInfo: action.payload.managerInfo,
        parcelNumber: action.payload.parcelNumber,
        propertyAddress: action.payload.propertyAddress,
        totCertificateNumber: action.payload.totCertificateNumber,
        manageRental: action.payload.manageRental,
        listingUrls: action.payload.listingUrls,
        rentalType: action.payload.rentalType,
      };
    case UPDATE_RENTAL_MANAGER:
      return {
        ...state,
        manageRental: action.payload.manageRental,
      };
    case UPDATE_LOCAL_CONTACT_RAW:


      const manageRental = state.manageRental;
      const rawLocalContact = action.payload.rawLocalContact;
      const localContact = (rawLocalContact === 'certificateHolder') ?
        manageRental : rawLocalContact;

      return {
        ...state,
        localContactInfo: {
          ...state.localContactInfo,
          whoIs: localContact,
        },
      };
    case UPDATE_LISTINGS:
      return {
        ...state,
        listingUrls: action.payload.listingUrls,
      };
    case UPDATE_MANAGER_INFO:
      return {
        ...state,
        managerInfo: action.payload,
      };
    default:
      return state;
  }
};
