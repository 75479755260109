import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { ButtonWrapper } from './navigation-buttons-styles';

const NavigationButtonsModal = (props) => {
  const {
    nextLabel = 'Continue',
    isNextDisabled = true,
    nextClick,
    backClick,
    isLoading,
  } = props;
  const history = useHistory();

  const handleBackClick = () => {
    if (backClick === 'pay-tot') {
      history.push(`/${backClick}`);
    } else if (backClick === 'revenue') {
      history.push(`/${backClick}`);
    } else if (backClick === 'home') {
      history.push('/');
    }
  };

  return (
    <ButtonWrapper>
      <Button
        basic
        content='Back'
        onClick={handleBackClick}
        data-cy='navi-back'
      />
      <Button
        color='black'
        content={nextLabel}
        loading={isLoading}
        disabled={isNextDisabled || isLoading}
        onClick={() => nextClick(true)}
        data-cy='navi-next'
      />
    </ButtonWrapper>
  );
};

export default NavigationButtonsModal;
