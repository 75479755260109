import React, { useReducer } from 'react';
import { Grid } from 'semantic-ui-react';
import { WrapperContainer, HideMobile } from '../components/common/common-styles';
import LayoutHeader from '../components/common/layout-header';
import { CertificateContext } from '../state/context';
import Stepper from '../components/common/stepper';
import { certificateReducer } from '../state/reducer';
import { initialCertificateState } from '../state/initial-state';
import { ApplyTotRoutes } from '../routes/applyTot';
import { CertificateSteps } from '../utils/steps';

const ApplyTOT = () => {
  const [state, dispatch] = useReducer(certificateReducer, initialCertificateState);

  return (
    <>
      <CertificateContext.Provider value={{ state, dispatch }}>
        <LayoutHeader title='Placer County TOT Certificate Application' />
        <WrapperContainer>
          <Grid stackable>
            <Grid.Column width={13}>
              <ApplyTotRoutes />
            </Grid.Column>
            <Grid.Column className='Stepper Col' width={3}>
              <HideMobile>
                <Stepper steps={CertificateSteps} currentStep={1} context='certificate' />
              </HideMobile>
            </Grid.Column>
          </Grid>
        </WrapperContainer>
      </CertificateContext.Provider>
    </>
  );
};

export default ApplyTOT;
