import React, { useState } from 'react';
import { connect } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as cs from '../components/common/common-styles';
import * as ps from './parcel-lookup-form-styles';
import { stepperActions } from '../app/stepper-slice';
import { OrSeparator } from './or-separator';
import { VerticalStepper } from '../components/common/vertical-stepper';

function LocationPinAdornment() {
  return (
    <InputAdornment position="end">
      <img src="../../assets/location-pin.svg" alt="Map Pin"/>
    </InputAdornment>
  );
}

function ParcelLookupFormComponent() {
  const [address, setAddress] = useState('');
  const [parcelNumber, setParcelNumber] = useState('')

  return (
    <>
      <cs.Wrapper>
        <ps.HeaderText>Address of the rental property</ps.HeaderText>
        <ps.AddressInput
          name="Address Input"
          variant="outlined"
          placeholder="Rental Property Address"
          InputProps={{
            endAdornment: <LocationPinAdornment />,
          }}
          inputProps={{
            maxLength: 2000,
          }}
          value={address}
          onChange={(e) => { setAddress(e.target.value); }}
        />

        <OrSeparator />

        <ps.HeaderText>Parcel number</ps.HeaderText>

        <NumberFormat
          format="###-###-###-###"
          name="Parcel Number Input"
          variant="outlined"
          placeholder="Parcel Number"
          customInput={ps.ParcelInput}
          value={parcelNumber}
          onChange={(e) => { setParcelNumber(e.target.value); }}
        />

        <div>
          <cs.Link href="http://google.com" target="_blank" rel="noreferrer">
            Lookup parcel number by address
          </cs.Link>
        </div>

        <cs.ImageWrapper>
          <img
            src="../../assets/undraw-at-home-octe-2.svg"
            alt="Image of a house with trees"
          />
        </cs.ImageWrapper>
      </cs.Wrapper>

      <VerticalStepper/>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    resetStepper: () => dispatch(stepperActions.reset()),
    incrementStepper: () => dispatch(stepperActions.increment()),
  }
}

export const ParcelLookupForm = connect(
  null,
  mapDispatchToProps,
)(ParcelLookupFormComponent)
