import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const ButtonSectionWrapper = styled.div`
  margin: 1rem 0;
`;

export const GridWrapper = styled.div`
  margin: 2rem 0;
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const FieldInputWrapper = styled.div`
  margin: 0.5rem 0;
`;

export const FullWidthInput = styled(Input)`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 3rem 0;
`;

export const ListingUrlExample = styled.div`
  text-align: right;
`;