import { FLOW_PATHS } from '../../routes/constants'

export const APPLY_STR_PATHS = {
  requirements: `${FLOW_PATHS.applyStrLicense}/requirements`,
  property: `${FLOW_PATHS.applyStrLicense}/property`,
  licenseHolder: `${FLOW_PATHS.applyStrLicense}/license-holder`,
  authorizedAgent: `${FLOW_PATHS.applyStrLicense}/authorized-agent`,
  documents: `${FLOW_PATHS.applyStrLicense}/documents`,
  confirmation: `${FLOW_PATHS.applyStrLicense}/confirmation`,
  payment: `${FLOW_PATHS.applyStrLicense}/payment`,
  success: `${FLOW_PATHS.applyStrLicense}/success`,
};
