import React, { useContext, useEffect } from 'react';
import { Grid, Button, Image, Popup, Icon } from 'semantic-ui-react';
import { CheckoutForm } from '../../checkout-form';
import { UPDATE_PAYMENT_STEP } from '../../state/actions';
import { PaymentContext } from '../../state/context';
import { useHistory } from 'react-router-dom';
import { numberStartsWithDollarSign } from '../../utils/utility-functions';
import {
  SectionContainer,
  HeaderWrapper,
  FieldLabel,
  FieldWrapper,
  ButtonContainer,
  ImageContainer,
  StyledAmountContent,
  StyledAmountWrapper,
} from './tot-payment-styles';
import { CREDIT_CARD_TAB, ACH_PAYMENT_TAB } from '../../checkout-form/checkout-constants';

const CC_PAYMENT = CREDIT_CARD_TAB;
const ACH_PAYMENT = ACH_PAYMENT_TAB;

function paymentFeeFactory(paymentType, checkoutDetails) {
  switch (paymentType) {
    case CC_PAYMENT:
      return {
        total: checkoutDetails.ccTotal,
        processingFee: checkoutDetails.ccFee,
        processingFeeLabel: 'Card Processor Fee',
      };
    case ACH_PAYMENT:
      return {
        total: checkoutDetails.achTotal,
        processingFee: checkoutDetails.achFee,
        processingFeeLabel: 'Bank Processor Fee',
      };
    default:
      break;
  }
}

const FieldItem = ({ label, content, accent, popupMessage }) => {
  const labelProps = accent ?
    { style: { fontWeight: '900' } } :
    {};
  const valueProps = accent ?
    { style: { fontWeight: '900', color: 'black' } } :
    {};

  return (
    <FieldWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FieldLabel {...labelProps}>{label}</FieldLabel>
        {popupMessage &&
          <Popup
            content={popupMessage}
            trigger={<Icon style={{ marginTop: '-5px', marginLeft: '3px' }} name='info circle' />}
            inverted
          />
        }
      </div>
      <div {...valueProps}>{content}</div>
    </FieldWrapper>
  );
};

const TOTStripePayment = () => {
  const { state, dispatch } = useContext(PaymentContext);
  const { checkoutDetails } = state;
  const history = useHistory();
  const summary = checkoutDetails?.summary;

  useEffect(() => {
    dispatch({ type: UPDATE_PAYMENT_STEP, payload: { currentStep: 3 } });
  }, [dispatch]);

  // user landed on payment directly - bounce'em back!
  if (!summary) return null;

  const fees = paymentFeeFactory(state.activePaymentMethodTab, checkoutDetails);

  return (
    <SectionContainer>
      <Grid stackable>
        <Grid.Column width={6}>
          <StyledAmountWrapper>
            <HeaderWrapper>Total Amount Due</HeaderWrapper>
            <StyledAmountContent>{`${numberStartsWithDollarSign(fees.total)}`}</StyledAmountContent>
          </StyledAmountWrapper>

          <FieldItem
            label=" Assigned TOT Rate"
            content={`${numberStartsWithDollarSign(summary.totDue)}`}
          />
          <FieldItem
            label="TBID"
            content={`${numberStartsWithDollarSign(summary.tbidDue)}`}
          />
          <FieldItem label="Assessment (1%)" content={`${numberStartsWithDollarSign(summary.assessment)}`}/>
          <FieldItem label="Accrued Interest" content={`${numberStartsWithDollarSign(summary.accruedInterest)}`} />
          <FieldItem label="Late Payment Penalties" content={`${numberStartsWithDollarSign(summary.latePenalties)}`} />
          <FieldItem
            accent
            label={fees.processingFeeLabel}
            content={`$${fees.processingFee}`}
            popupMessage="Stripe processing fee applied to full payment"
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <CheckoutForm />
        </Grid.Column>
      </Grid>
      <ButtonContainer>
        <Button basic content="Back" onClick={() => history.push('/pay-tot/sign')} />
      </ButtonContainer>
      <ImageContainer>
        <Image src="../../../assets/tot-pay-payment.svg" />
      </ImageContainer>
    </SectionContainer>
  );
};

export default TOTStripePayment;
