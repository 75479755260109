import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

export const SiteLogo = styled.img`
  &&& {
    width: 6rem !important;
    padding: 0.1rem 0.3rem;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  &&& {
    font-weight: bold;
    font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    display:none!important;
  }
`;

export const StyledMenuItemMobile = styled(Menu.Item)`
  &&& {
    display:none;
    font-weight: bold;
    font-size: 15px;
    margin-top:6rem;
    padding-bottom:1rem;
    @media (max-width: 768px) {
    display:block;
  }
  }
`;
