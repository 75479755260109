import React from 'react';
import { TableMobile } from './table-responsive-styles';

// This component uses an api similar to react-table
const Cell = ({ header, children }) => (
  <td role="cell">
    <span className="responsive-table__heading" aria-hidden="true">
      {header}
    </span>
    {children}
  </td>
);

const TableResponsive = ({ data, columns, getRowKey, title }) => {
  if (!data) {
    return null;
  }
  return (
      <TableMobile role="table" aria-label={title} tabindex="0">
        <thead>
          <tr role="row">
            {columns.map((column, i) => (
              <th role="columnheader" key={'header' + i}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row) => {
            return (
              <tr role="row" key={getRowKey && getRowKey(row)}>
                {columns?.map(({ accessor, header }) => {
                  return (
                    <Cell key={row[accessor]} header={header}>
                      {row[accessor]}
                    </Cell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableMobile>
  );
};
      

export default TableResponsive;
