/* eslint-disable operator-linebreak */
// Payment Actions
export const UPDATE_TOT_CERT_ID = 'UPDATE_TOT_CERT_ID';
export const UPDATE_PAYMENT_STEP = 'UPDATE_PAYMENT_STEP';
export const UPDATE_EMAIL_VERIFICATION = 'UPDATE_EMAIL_VERIFICATION';
export const UPDATE_CHECKOUT_DETAILS = 'UPDATE_CHECKOUT_DETAILS';

export const UPDATE_SIGNATORY = 'UPDATE_SIGNATORY';
export const UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS =
  'UPDATE_ACKNOWLEDGE_CHECKOUT_DETAILS';
export const UPDATE_REPORT_REVENUE_DETAILS = 'UPDATE_REPORT_REVENUE_DETAILS';

// TOT Certification Actions
export const UPDATE_TOT_CERTIFICATION_STEP = 'UPDATE_TOT_CERTIFICATION_STEP';
export const UPDATE_ACKNOWLEDGE_DETAILS = 'UPDATE_ACKNOWLEDGE_DETAILS';
export const UPDATE_APN_FOR_APPLY_TOT = 'UPDATE_APN_FOR_APPLY_TOT';
export const UPDATE_PROPERTY_DETAILS = 'UPDATE_PROPERTY_DETAILS';
export const UPDATE_OWNER_INFO = 'UPDATE_OWNER_INFO';
export const UPDATE_MANAGER_INFO = 'UPDATE_MANAGER_INFO';
export const UPDATE_LOCAL_CONTACT_INFO = 'UPDATE_LOCAL_CONTACT_INFO';
export const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME';
export const UPDATE_RENTAL_MANAGER = 'UPDATE_RENTAL_MANAGER';
export const UPDATE_LOCAL_CONTACT = 'UPDATE_LOCAL_CONTACT';
export const UPDATE_LOCAL_CONTACT_RAW = 'UPDATE_LOCAL_CONTACT_RAW';
export const UPDATE_SAME_AS_RENTAL = 'UPDATE_SAME_AS_RENTAL';
export const UPDATE_RENTAL_TYPE = 'UPDATE_RENTAL_TYPE';
export const UPDATE_UNIT_NUMBER = 'UPDATE_UNIT_NUMBER';
export const UPDATE_NO_OF_UNITS = 'UPDATE_NO_OF_UNITS';
export const UPDATE_RENTAL_UNIT_INFO = 'UPDATE_RENTAL_UNIT_INFO';
export const UPDATE_HAS_UNIT_NUMBER = 'UPDATE_HAS_UNIT_NUMBER';
export const UPDATE_SECONDARY_DWELLING = 'UPDATE_SECONDARY_DWELLING';
export const UPDATE_MULTIPLE_UNIT = 'UPDATE_MULTIPLE_UNIT';
export const PAYMENT_METHOD_TAB_SELECTED = 'PAYMENT_METHOD_TAB_SELECTED';
export const UPDATE_PARCEL_NUMBER = 'UPDATE_PARCEL_NUMBER';
export const UPDATE_LISTINGS = 'UPDATE_LISTINGS';
export const UPDATE_PAYMENT_CONFIRMATION = 'UPDATE_PAYMENT_CONFIRMATION';
export const UPDATE_CERT = 'UPDATE_CERT';

// Update TOT Actions
export const UPDATE_TOT_NUMBER = 'UPDATE_TOT_NUMBER';
export const UPDATE_INFO_UPDATE_OWNER_INFO = 'UPDATE_INFO_UPDATE_OWNER_INFO';
export const UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO =
  'UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO';
export const UPDATE_TOT_DETAILS = 'UPDATE_TOT_DETAILS';
