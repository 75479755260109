import styled from 'styled-components';
import { Divider, Input, Radio, Search } from 'semantic-ui-react';

export const SectionContainer = styled.div`
  color: #5c5d5d;
`;

export const HeaderWrapper = styled.div`
  font-size: 18px;
  padding: 1rem 0;
`;

export const InputWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
`;

export const LocationInput = styled(Search)`
flex:1;
  > .ui.icon.input {
    width:100%;
    > .prompt {
      border-radius: 4px;
    }
  }

  > .results.transition {
    width: 500px;
  }
  ::focus {
    border-color: #027680;
  }
  padding-right: 1rem;
`;

export const MessageWrapper = styled.div`
  color: #39b54a;
  padding: 1rem 0;
  display: flex;
`;

export const MessageContent = styled.p`
  margin-left: 5px;
  font-weight: bold;
`;

export const AddressWrapper = styled.div`
  padding: 1rem 0;
`;

export const AddressContent = styled.p`
  color: #5c5d5d;
  padding-left: 1.9rem;
`;

export const RadioOption = styled(Radio)`
  font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
  width: 500px;
`;

export const ParcelNumberInput = styled(Input)`
  ::focus {
    border-color: #027680;
  }
`;
