import { ROUTES } from '../common/constants';
import { authSender } from '../../utils/auth-sender';
import { formatPhoneNumber } from '../../utils/helper-functions';

/**
 * Returns the response to the request
 * @param {number} totCertId TOT's certification ID to apply updates onto
 * @param {Object} postData information to update TOT
 * @param {string} postData.localContact "certificateHolder" or "someoneElse" (required fields)
 * @param {string} postData.ownerPhone phone number of owner (required fields)
 * @param {string} postData.ownerMailingAddress address of owner (required fields)
 * @param {string} postData.ownerEmail email address of owner (required fields)
 */
export const submitTOTUpdate = async (totCertId, postData) => {
  const { data } = await authSender.post(
    `${ROUTES.updateCert}/${totCertId}`,
    postData
  );

  return data;
}

/**
 * Returns a structured payload for the client to dispatch a state update
 * @param {string} totCertNumber user's TOT certificate number
 */
export const getTOTDetails = async (totCertNumber) => {
  const { data } = await authSender.get(`${ROUTES.getCert}/${totCertNumber}`);
  const listings = data.listingUrls.length ? data.listingUrls : [''];

  const payload = {
    ownerInfo: {
      name: data.ownerName,
      email: data.ownerEmail,
      phone: formatPhoneNumber(data.ownerPhone),
      address: data.ownerMailingAddress,
    },
    localContactInfo: {
      whoIs: data.localContact,
      name: data.localContactName,
      email: data.localContactEmail,
      phone: formatPhoneNumber(data.localContactPhone),
      address: data.localContactMailingAddress,
    },
    companyName: data.propertyManagementCompanyName,
    managerInfo: {
      name: data.propertyManagerName,
      email: data.propertyManagerEmail,
      phone: formatPhoneNumber(data.propertyManagerPhone),
      address: data.propertyManagerMailingAddress,
      company: data.propertyManagerCompanyName,
    },
    manageRental: data.operatesRental,
    parcelNumber: data.parcelNumber,
    propertyAddress: data.propertyAddress,
    totCertificateNumber: data.totCertId,
    listingUrls: listings,
    rentalType: data.rentalType,
  };

  return payload;
}

/**
 * Returns an object that indicates if there are any email changes
 *  and which ones have changed
 * @param {Object} previousData unaltered data to check against
 * @param {Object} currentData altered data to be sent
 */
export const checkIfEmailsHaveChanged = (previousData, currentData) => {
  // manageRental = 'owner' or 'property_mgr'
  const { manageRental } = currentData;

  const emailsWhichHaveChanged = {
    owner: false,
    property_mgr: false,
    localContactInfo: false,
  };

  // Check owner, property manager, and local point of contact email
  const prevOwnerEmail = previousData.ownerInfo.email;
  const currOwnerEmail = currentData.ownerInfo.email;
  if (prevOwnerEmail !== currOwnerEmail) {
    emailsWhichHaveChanged.owner = true;
  }

  const prevPropertyManagerEmail = previousData.managerInfo.email;
  const currPropertyManagerEmail = currentData.managerInfo.email;
  if (prevPropertyManagerEmail !== currPropertyManagerEmail) {
    emailsWhichHaveChanged.property_mgr = true;
  }

  const prevLocalPOC = previousData.localContactInfo.email;
  const currLocalPOC = currentData.localContactInfo.email;
  if (prevLocalPOC !== currLocalPOC) {
    emailsWhichHaveChanged.localContactInfo = true;
  }

  const loginEmailHasChanged = emailsWhichHaveChanged[manageRental];
  return {loginEmailHasChanged, emailsWhichHaveChanged};
}

export const buildPostData = (currentState) => {
  const {
    ownerInfo,
    managerInfo,
    localContactInfo,
    manageRental,
    listingUrls,
  } = currentState;

  const contactInfo =
    localContactInfo.whoIs === 'someoneElse' ?
      // User selected 'Someone else' for local point of contact
      localContactInfo :
      // User selected 'The certificate holder', then check when manages the rental
      manageRental === "owner" ?
        ownerInfo :
        managerInfo;

  // Start off with required fields + ungrouped items
  let payload = {
    localContact: localContactInfo.whoIs,
    ownerEmail: ownerInfo.email,
    ownerPhone: ownerInfo.phone,
    ownerMailingAddress: ownerInfo.address,
    listingUrls,
  };

  // Add on localContact changes
  payload = {
    ...payload,
    localContactName: contactInfo.name,
    localContactEmail: contactInfo.email,
    localContactPhone: contactInfo.phone,
    localContactMailingAddress: contactInfo.address,
  };

  // Add on propertyManager changes
  if (manageRental === 'property_mgr'){
    payload = {
      ...payload,
      propertyManagerName: managerInfo.name,
      propertyManagerEmail: managerInfo.email,
      propertyManagerPhone: managerInfo.phone,
      propertyManagerMailingAddress: managerInfo.address,
    };
  }

  return payload;
}

export class ManageRentalIsNull extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'operatesRentalIsNull';
    this.message = 'OperatesRental is null'
  }
}
