import React, { useContext, useEffect } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { UPDATE_TOT_CERTIFICATION_STEP } from '../../state/actions';
import { CertificateContext } from '../../state/context';
import { HeaderWrapper } from '../common/common-styles';
import { FieldItem } from '../common/field-item';
import { FieldItemList } from '../common/field-item-list';
import ConditionalFieldItem from './conditional-field-item'
import ManagmentInfoItems from './managment-info-items'
import LocalInfoItems from './local-contact-info-items'
import { ButtonWrapper } from './navigation-buttons-styles';
import { useHistory } from 'react-router-dom';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';
import { getCertHolderInfo } from '../../utils/certificate-holder-info';


const TotConfirmDetails = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CertificateContext);

  const {
    parcelNumber,
    propertyAddress,
    rentalType,
    secondaryDwelling,
    unitNumber,
    noOfUnits,
    ownerInfo,
    managerInfo,
    localContactInfo,
    listingUrls,
    manageRental,
    localContact,
  } = state;

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 5 },
    });
  }, [dispatch]);

  const handleNextClick = () =>
    history.push(ApplyTotRouteMap[history.location.pathname]);

  const certHolderInfo = getCertHolderInfo(manageRental, ownerInfo, managerInfo);

  return (
    <>
      <HeaderWrapper>Please confirm the following information.</HeaderWrapper>
      <Grid stackable>
        <Grid.Column width={8}>
          <FieldItem label='Parcel number' content={parcelNumber} />
          <FieldItem label='Property address' content={propertyAddress} />
          <FieldItem
            label='Rental Type'
            content={rentalType}
          />
          <FieldItem
            label='Secondary Dwelling '
            content={secondaryDwelling ? 'Yes' : 'No'}
          />
          <ConditionalFieldItem label='Unit number' content={unitNumber} />
          <ConditionalFieldItem
            label='Number of units available'
            content={noOfUnits}
          />
          <FieldItem label="Certificate Holder" content={certHolderInfo.name} />
          <FieldItem label="Certificate Holder Email" content={certHolderInfo.email} />
        </Grid.Column>
        <Grid.Column width={8}>
          <FieldItem label='Owner Name' content={ownerInfo.name} />
          <FieldItem label='Phone Number' content={ownerInfo.phone} />
          <FieldItem label='Email' content={ownerInfo.email} />
          <FieldItem label='Mailing Address' content={ownerInfo.address} />
        </Grid.Column>
      </Grid>
      <Grid stackable>
        <ManagmentInfoItems
          manageRental={manageRental}
          managerInfo={managerInfo}
        />
        <LocalInfoItems info={localContactInfo} localContact={localContact} />
      </Grid>

      {listingUrls.filter((url) => Boolean(url)).length >= 1 && (
        <FieldItemList label='Listings' content={listingUrls} />
      )}

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button onClick={handleNextClick} color='black'>
          Proceed to sign
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default TotConfirmDetails;
