import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Container, Header, Image } from 'semantic-ui-react';
import LayoutHeader from '../components/common/layout-header';
import { StyledAnchor } from '../components/tot-pay/tot-pay-footer-styles';

const PaymentErrorPage = () => {
    
  const location = useLocation();
  const errorMessage = location?.state?.errorMessage;
  
  return (
  <>
    <LayoutHeader title='Placer County Short-term Rental Registration and Tax Payment System ' />
    <Container style={{ paddingTop: '10rem', color: '#5c5d5d' }}>
      <Header style={{ color: '#5c5d5d', fontSize: '50px', fontWeight: 'normal' }} size='huge'>
        Something went wrong
      </Header>
      <Header style={{ fontWeight: 'normal', color: '#5c5d5d' }}>
        <p>We were unable to process your payment; reason provided by stripe: {errorMessage ? errorMessage : null}. 
If the problem persists, please contact us at <StyledAnchor style={{ color: '#027680' }} href="mailto:placer-ca-str@deckard.com">
placer-ca-str@deckard.com
        </StyledAnchor></p>
      </Header>
      <div style={{ margin: '3rem 0' }}>
        <Button color='black' content='Try again' />
        <Button basic content='Change Payment' as={Link} to='/' />
      </div>
      <Image src='../../assets/payment-error-page.svg' />
    </Container>
  </>
)};

export default PaymentErrorPage;