import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Step, Stepper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import * as ss from './vertical-stepper-styles';

function QontoStepIcon(props) {
  const classes = ss.useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ?
        <Check className={classes.completed} /> :
        <div className={classes.circle} />
      }
    </div>
  );
}

function VerticalStepperComponent(props) {
  const { steps, activeStepper } = props;

  return (
    <Stepper
      activeStep={activeStepper}
      orientation="vertical"
      connector={<ss.QontoConnectorV2/>}
      style={{ paddingLeft: '110px' }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <ss.StepperLabel StepIconComponent={QontoStepIcon}>
            {label}
          </ss.StepperLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function mapStateToProps(state) {
  return {
    activeStepper: state.stepper.currentStep,
    steps: state.stepper.steps,
  }
}

export const VerticalStepper = connect(mapStateToProps)(VerticalStepperComponent);
