import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Message, Radio, Popup } from 'semantic-ui-react';
import {
  UPDATE_LOCAL_CONTACT_RAW,
  UPDATE_INFO_UPDATE_OWNER_INFO,
} from '../../state/actions';
import { UpdateTOTContext } from '../../state/context';
import { HeaderWrapper } from '../common/common-styles';
import { ButtonWrapper } from '../common/navigation-buttons-styles';
import { SectionHeadingWrapper,UpdateDetailsButtonWrapper,UpdateDetailsWrapper } from './update-details-styles';
import { ownerLabels } from '../common/constants';
import { RadioFieldWrapper, RadioSectionWrapper } from '../apply-tot/apply-tot-owner-info-styles';
import ContactSectionAddress from '../apply-tot/contact-section-address';
import { Alert } from 'rsuite';
import { ERROR_MESSAGES } from '../common/error-constants';
import {
  contactFormValidation,
  getAddressSuggestions,
  getRawLocalContact
} from '../../utils/helper-functions';
import * as Sentry from "@sentry/react";
import { ManageRentalIsNull } from './update-utils';

const UpdateDetails = () => {
  // Hooks
  const history = useHistory();
  const {
    state: {
      ownerInfo,
      manageRental,
      localContactInfo,
    },
    dispatch,
  } = useContext(UpdateTOTContext);
  const [initialDataLoading, setInitialDataLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // For auto-suggestions of property manager address input
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    /**
     * Address may come in as null
     * Default to empty string, if address is null
     * Prevents React error about value of input being null instead empty string
     */
    setSearchTerm(ownerInfo.address || '');
    setInitialDataLoading(true);

    const contexts = {
      ownerInfo
    };
    if (manageRental === null) {
      Alert.error(ERROR_MESSAGES.OperatesRentalIsNull, 0);
      Sentry.captureException(new (ManageRentalIsNull), { contexts });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Triggers auto-suggests for owner mailing address
  useEffect(() => {
    if (Boolean(searchTerm)) {
      (async () => setSuggestions(await getAddressSuggestions(searchTerm)))();
    } else if (initialDataLoading) {
      updateOwnerInfo({ ...ownerInfo, address: searchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // Dispatches
  const updateOwnerInfo = (payload) => {
    dispatch({
      type: UPDATE_INFO_UPDATE_OWNER_INFO,
      payload,
    });
  };

  const updateRawLocalContact = (e, { value }) => {
    // Required to remember what was select in Update-Details form
    dispatch({
      type: UPDATE_LOCAL_CONTACT_RAW,
      payload: { rawLocalContact: value },
    });
  };

  const infoContainsErrors = () => {
    setErrorMessage(null);
    const errorMessage = contactFormValidation(ownerInfo);

    if (errorMessage) setErrorMessage(errorMessage);
    return Boolean(errorMessage);
  }

  // Click handlers
  const handleNextClick = () => {
    if (infoContainsErrors()) return;
    if (manageRental === null) {
      Alert.error(ERROR_MESSAGES.OperatesRentalIsNull, 0);
      return;
    }

    Alert.closeAll();
    const nextRoute = manageRental === 'owner' && rawLocalContact === 'certificateHolder' ?
      // '/update-info/update-listings' : TODO: put back when server supports listings
      '/update-info/update-review' :
      '/update-info/update-contact';
    history.push(nextRoute);
  };

  const handleBackClick = () => {
    Alert.closeAll();
    history.goBack();
  }

  const rawLocalContact = getRawLocalContact(manageRental, localContactInfo.whoIs);

  return (
    <UpdateDetailsWrapper className='UpdateDetailsWrapper'>
      <HeaderWrapper data-cy="owner-header">
        Update your contact information below.
      </HeaderWrapper>

      <Grid stackable divided relaxed>
        <Grid.Column width={8}>
          <SectionHeadingWrapper>Owner</SectionHeadingWrapper>

          <ContactSectionAddress
            labels={ownerLabels}
            info={ownerInfo}
            updateInfo={updateOwnerInfo}
            searchTerm={searchTerm}
            suggestions={suggestions}
            setSearchTerm={setSearchTerm}
            disabledFields={{ name: true, phone: false }}
          />
        </Grid.Column>

        <Grid.Column width={8}>

        <RadioSectionWrapper>
            <HeaderWrapper>Who is the certificate holder?</HeaderWrapper>
            <Popup
              basic
              content='Create a new certificate to change this field'
              trigger={
                <span>
                  <RadioFieldWrapper>
                    <Radio
                      label='The owner'
                      name='radioGroupManager'
                      value='owner'
                      checked={manageRental === 'owner'}
                      disabled={true}
                      data-cy="certificate-holder-owner"
                    />
                  </RadioFieldWrapper>
                  <RadioFieldWrapper>
                    <Radio
                      label='The property manager'
                      name='radioGroupManager'
                      value='property_mgr'
                      checked={manageRental === 'property_mgr'}
                      disabled={true}
                      data-cy="certificate-holder-property-manager"
                    />
                  </RadioFieldWrapper>
                </span>
              }
            />
          </RadioSectionWrapper>

          <RadioSectionWrapper>
            <HeaderWrapper>new:2Who is the local contact person?</HeaderWrapper>
            <RadioFieldWrapper>
              <Radio
                label='The certificate holder'
                name='radioGroupContact'
                value='certificateHolder'
                checked={rawLocalContact === 'certificateHolder'}
                onChange={updateRawLocalContact}
              />
            </RadioFieldWrapper>
            <RadioFieldWrapper>
            </RadioFieldWrapper>
            <RadioFieldWrapper>
              <Radio
                label='Someone else'
                name='radioGroupContact'
                value='someoneElse'
                checked={rawLocalContact === 'someoneElse'}
                onChange={updateRawLocalContact}
              />
            </RadioFieldWrapper>
          </RadioSectionWrapper>
        </Grid.Column>
      </Grid>

      {errorMessage && (
        <Message style={{ width: '300px' }} error>
          {errorMessage}
        </Message>
      )}

      <UpdateDetailsButtonWrapper>
        <Button
          basic
          content='Back'
          onClick={handleBackClick}
        />

        <Button
          color='black'
          content='Continue'
          onClick={handleNextClick}
          disabled={!localContactInfo.whoIs}
        />
      </UpdateDetailsButtonWrapper>
    </UpdateDetailsWrapper>
  );
};

export default UpdateDetails;
