import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Icon, Image, Message } from 'semantic-ui-react';
import {
  HeaderWrapper,
  StyledInput,
  StyledLink,
  ButtonWrapper,
  ContentWrapper,
  SectionHeader,
  StyledButton,
  SectionContent,
  ImageWrapper,
} from './find-tot-styles';
import { uiSender } from '../../utils/ui-sender';
import { Auth } from 'aws-amplify';
import { ROUTES } from '../common/constants';
import { UpdateTOTContext } from '../../state/context';
import { UPDATE_TOT_DETAILS, UPDATE_TOT_NUMBER } from '../../state/actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import TOTPaymentFooter from '../tot-pay/tot-pay-footer';
import NavigationButtonsModal from '../common/navigation-buttons-with-modal';
import {
  currentAuthenticatedUser,
  isAuthenticated,
} from '../../utils/auth-functions';
import EmailVerificationModal from './email-verification-modal';
import { getTOTDetails } from './update-utils';
import { Alert } from 'rsuite';
import SubmitButton from '../common/submitButton';
import { FlexResponsive } from '../common/common-styles';

const checkIsValidNumber = (input) => {
  const pattern = /^\d+$/;
  return pattern.test(input);
};

const FindTot = () => {
  const [tot, setTot] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isValidInput, setIsValidInput] = useState(true);
  const [isValidTOT, setIsValidTOT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [totDetails, setTOTDetails] = useState({
    totCertificate: null,
    address: null,
    certificateHolder: null,
    certificateHolderEmail: null,
  });
  const { dispatch } = useContext(UpdateTOTContext);
  const { url } = useRouteMatch();
  const history = useHistory();
  const [isAuthingUser, setIsAuthingUser] = useState(false);

  useEffect(() => {
    const devOnlyCerts = ['1736', '1919', '5026'];

    if (tot.length > 0 || devOnlyCerts.includes(tot)) {
      const isValidNumber = checkIsValidNumber(tot);
      setIsValidInput(!isValidNumber);
      setErrorMessage(null);
    }
  }, [tot, dispatch]);

  /*
   * This ref will be used for storing a reference to the cognito user instance that
   * we want to login into the application
   */
  const cognitoUserRef = useRef();

  const initiateLogin = useCallback(
    async (openModalCB) => {
      try {
        if (openModalCB) openModalCB();
        cognitoUserRef.current = await Auth.signIn(`${tot}`);
        setDisableResend(false);
      } catch (err) {
        console.error(err);
        Alert.error('Error Initiating Verification', 0, () => setOpen(false));
        setDisableResend(false);
        // cert number not found.. must notify user. Talk to James specify this state in the design
      }
    },
    [tot]
  );

  /**
   * Callback for when next button is clicked
   */
  const handleNextClick = useCallback(
    async (isOpen) => {
      setIsAuthingUser(true);
      const currentAuthUser = await currentAuthenticatedUser();

      // If the user is logged in under same tot, no need to open login modal
      if (currentAuthUser === tot) {
        cognitoUserRef.current = await Auth.signIn(`${tot}`);
        const totCertNumber = cognitoUserRef.current.username;
        const payload = await getTOTDetails(totCertNumber);
        dispatch({ type: UPDATE_TOT_DETAILS, payload });

        setIsAuthingUser(false);
        history.push('/update-info/update-details');
      } else {
        setIsAuthingUser(false);
        Auth.signOut();
        initiateLogin(() => setOpen(isOpen));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tot, history, initiateLogin]
  );

  /**
   * This callback will be triggered when the user submits the authentication code.
   * On success of this operation, the user will be logged in.
   *
   * @param {string} code Authentication code
   */
  const submitCode = useCallback(
    async (code) => {
      try {
        setIsAuthingUser(true);

        // this will login the user and save tokens to localStorage
        await Auth.sendCustomChallengeAnswer(cognitoUserRef.current, code);
        const totCertNumber = cognitoUserRef.current.username;
        const loginSuccess = await isAuthenticated();
        if (loginSuccess) {
          const payload = await getTOTDetails(totCertNumber);
          dispatch({ type: UPDATE_TOT_DETAILS, payload });

          history.push('/update-info/update-details');
        } else {
          // Throw when invalid/incorrect code is submitted
          const errorMsg = 'Incorrect user verification code';
          throw new Error(errorMsg);
        }
      } catch (err) {
        // let users know there was a problem with the login
        // console.log('err', err);
        setLoginError(true);
      }

      setIsAuthingUser(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cognitoUserRef, history]
  );

  const handleTotSubmit = async () => {
    setIsLoading(true);
    const { data } = await uiSender.get(`${ROUTES.totCertLookup}/${tot}`);
    const { error } = data;

    if (!error) {
      setTOTDetails({
        totCertificate: data.certId,
        address: data.propertyAddress,
        certificateHolder: data.certificateHolder,
        certificateHolderEmail: data.email,
      });
      setIsValidTOT(true);
      setErrorMessage(null);
      dispatch({
        type: UPDATE_TOT_NUMBER,
        payload: {
          totCertId: data.certId,
          certificateHolder: data.certificateHolder,
        },
      });
    } else {
      setErrorMessage(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <EmailVerificationModal
        open={open}
        setOpen={setOpen}
        submitCode={submitCode}
        resendCode={initiateLogin}
        loginError={loginError}
        resetLoginError={() => setLoginError(false)}
        disableResend={disableResend}
        setDisableResend={setDisableResend}
      />
      <HeaderWrapper>
        <p>Let’s get started by entering your TOT certificate number:</p>
      </HeaderWrapper>
      <FlexResponsive>
        <StyledInput
          icon={isValidTOT ? <Icon name="check" /> : null}
          onChange={(event) => setTot(event.target.value)}
          value={tot}
          data-cy="tot-input"
        />

        <SubmitButton
          onClick={handleTotSubmit}
          disabled={isValidInput}
          loading={isLoading}
        />
      </FlexResponsive>

      {errorMessage && (
        <Message
          error
          style={{ width: '300px' }}
          data-cy="errorMessage"
        >
          {errorMessage}
        </Message>
      )}

      <ButtonWrapper>
        <StyledLink to={`${url}/address`}>
          Help me find my certificate number
        </StyledLink>
      </ButtonWrapper>

      {isValidTOT ? (
        <div data-cy='tot-results'>
          <ContentWrapper>
            <SectionHeader>TOT CERTIFICATE</SectionHeader>
            <SectionContent>{totDetails.totCertificate}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>PROPERTY ADDRESS</SectionHeader>
            <SectionContent>{totDetails.address}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>CERTIFICATE HOLDER</SectionHeader>
            <SectionContent>{totDetails.certificateHolder}</SectionContent>
          </ContentWrapper>
          <ContentWrapper>
            <SectionHeader>CERTIFICATE HOLDER EMAIL</SectionHeader>
            <SectionContent>{totDetails.certificateHolderEmail}</SectionContent>
          </ContentWrapper>
          <NavigationButtonsModal
            isNextDisabled={false}
            backClick='home'
            nextClick={handleNextClick}
            isLoading={isAuthingUser}
          />
        </div>
      ) : (
        <div>
          <ButtonWrapper>
            <StyledButton
              basic
              content='Back'
              onClick={history.goBack}
            />
          </ButtonWrapper>
          <ImageWrapper>
            <Image src='../../../assets/undraw-inspection-re-tbt-7.svg' />
          </ImageWrapper>
        </div>
      )}

      <TOTPaymentFooter />
    </>
  );
};

export default FindTot;
