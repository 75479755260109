import React from 'react'
import { ListItem } from './list-item';
import * as ap from './str-license-application-requirements-styles';
import * as cs from '../../components/common/common-styles';

// TODO: Remove when state management is setup (info to be received from stats call)
import sampleRequirementsData from './str-license-application-requirements-data.json'

export function StrLicenseApplicationRequirements() {
  return (
    <cs.Wrapper>
      <ap.Header>
        <ap.HeaderText>Application Requirements</ap.HeaderText>
        <ap.HeaderSubText>{"Let's make sure you have everything you need to get an STR license."}</ap.HeaderSubText>
      </ap.Header>

      {sampleRequirementsData.map((props, index) => (
        <ListItem key={props.id} index={index} {...props} />
      ))}

      <div style={{ marginTop: '68px' }}>Want to print, fill out and mail forms directly to our offices?</div>
      <div>
        <cs.Link href="http://google.com" target="_blank" rel="noreferrer">Download PDF forms here{' '}</cs.Link>
        and mail it to us.
      </div>
    </cs.Wrapper>
  );
}
