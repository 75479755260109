/**
 * Returns the TOT application payload to be sent to API
 * @param {Object} userData ApplyTOT flow state
 * @param {String} signatoryName name of the person signing the TOT application
 */
export const buildTOTApplicationPayload = (userData, signatoryName) => {
  const {
    ownerInfo,
    managerInfo,
    localContactInfo,
    manageRental,
    localContact,
    propertyAddress,
    rentalType,
    secondaryDwelling,
    listingUrls
  } = userData;

  const propertyManagerInfo = manageRental === 'owner' ?
    ownerInfo : managerInfo;

  let localPOCInfo;
  if (localContact === 'owner') localPOCInfo = ownerInfo;
  if (localContact === 'property_mgr') localPOCInfo = managerInfo;
  if (localContact === 'someoneElse') localPOCInfo = localContactInfo;
  const payload = {
    operatesRental: manageRental,
    localContact: localContact,
    propertyAddress: propertyAddress,
    rentalType: rentalType,
    secondaryDwelling: secondaryDwelling,
    ownerName: ownerInfo.name,
    ownerPhone: ownerInfo.phone,
    ownerEmail: ownerInfo.email,
    ownerMailingAddress: ownerInfo.address,
    localContactName: localPOCInfo.name,
    localContactPhone: localPOCInfo.phone,
    localContactEmail: localPOCInfo.email,
    localContactMailingAddress: localPOCInfo.address,
    propertyManagerName: propertyManagerInfo.name,
    propertyManagerPhone: propertyManagerInfo.phone,
    propertyManagerEmail: propertyManagerInfo.email,
    propertyManagerMailingAddress: propertyManagerInfo.address,
    propertyManagerCompanyName: managerInfo.company,
    signatory: signatoryName,
    listingUrls: listingUrls.filter(listing => listing.length),
  };

  return payload;
}
