import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from './stripe-form';
import appSettings from '../app-settings.json';

const stripePromise = loadStripe(appSettings.stripePublishableKey);

export function CheckoutForm() {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm />
    </Elements>
  );
}
