import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { awsConfig } from '../aws-config';
import ApplySTR from '../pages/ApplySTR';
import Home from '../pages/Home';
import TOTPayment from '../pages/TOTPayment';
import UnderConstruction from '../pages/UnderConstruction';
import UnderConstructionPermit from '../pages/UnderConstructionPermit';
import ApplyTOT from '../pages/ApplyTOT';
import ErrorPage from '../pages/Error';
import Help from '../pages/Help';
import PaymentErrorPage from '../pages/PaymentError';
import UpdateTOT from '../pages/UpdateTOT';
import { FLOW_PATHS } from './constants'

Amplify.configure(awsConfig);
const Routes = () => (
  <Router>
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/under-construction' component={UnderConstruction} />
      <Route path='/under-construction-permit' component={UnderConstructionPermit} />
      <Route path='/error' component={ErrorPage} />
      <Route path='/payment-error' component={PaymentErrorPage} />
      <Route path='/apply-tot' component={ApplyTOT} />
      <Route path={FLOW_PATHS.applyStrLicense} component={ApplySTR} />
      <Route path='/pay-tot' component={TOTPayment} />
      <Route path='/apply-tot' component={Home} />
      <Route path='/update-info' component={UpdateTOT} />
      <Route path='/help' component={Help} />
      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  </Router>
);

export default Routes;
