import React, { useEffect, useState } from 'react';
import { Button, Image, Input, Modal } from 'semantic-ui-react';
import { EMAIL_CODE_LENGTH, INPUT_MAX_LENGTHS } from '../common/constants';
import CountdownButton from '../common/countdown-button';

const EmailVerificationModal = (props) => {
  const {
    setOpen,
    open,
    submitCode,
    resendCode,
    loginError,
    resetLoginError,
    setDisableResend,
    disableResend,
  } = props;

  const [code, setVerificationCode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isAuthingUser, setIsAuthingUser] = useState(false);
  const [countdownResetter, setCountdownResetter] = useState(0);
  const loginErrorDisplay = loginError ? 'inherit' : 'none';

  useEffect(() => {
    if (code.length === EMAIL_CODE_LENGTH) {
      setIsValid(true);
    }

    if (code.length !== EMAIL_CODE_LENGTH && isValid) {
      setIsValid(false);
    }
  }, [code, setOpen, isValid]);

  const handleSubmit = async () => {
    setIsAuthingUser(true);
    await submitCode(code);
    setIsAuthingUser(false);
  }

  const handleClose = () => {
    setIsValid(false);
    resetLoginError();
    setOpen(false);
  }

  return (
    <Modal onClose={handleClose} onOpen={() => setOpen(true)} open={open} size='small'>
      <Modal.Header data-cy='email-verification-modal-header'>Verification</Modal.Header>
      <Modal.Content image>
        <Image size='medium' src='../../../assets/email-verification.svg' wrapped />
        <Modal.Description>
          <p style={{ paddingBottom: '1rem' }}>
            We have sent a verification code to the email address associated with this TOT certificate, please enter the
            code below. The code expires in 3 minutes. Click resend to obtain a new code. Make sure to check your spam
            folder.
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              key={loginErrorDisplay}
              onChange={(e) => setVerificationCode(e.target.value)}
              name='code'
              type='text'
              maxLength={INPUT_MAX_LENGTHS.EMAIL_VERIFICATION_CODE}
              data-cy='email-verification-modal-input'
            />
            <div
              style={{ marginLeft: '10px', display: loginErrorDisplay, color: 'red' }}
              data-cy='email-verification-modal-errorMsg'
            >
              Invalid Code. Please Click Resend Code.
            </div>
          </div>
          <p style={{ paddingTop: '1rem' }}>
            If you don&apos;t have an email on file, please
            <span> </span>
            <a
              style={{ color: '#027680' }}
              href='https://www.placer.ca.gov/DocumentCenter/View/556/Transient-Occupancy-Tax-Worksheet-for-Individuals-PDF'
            >
              {' '}
              download the TOT worksheet
            </a>
          </p>
          <div style={{ marginTop: '10px' }}>
            <CountdownButton
              key={countdownResetter}
              label='Resend Code'
              disabled={disableResend}
              onClick={() => {
                if (disableResend) { return; }

                setDisableResend(true);
                setCountdownResetter(countdownResetter + 1);
                resendCode();
                setVerificationCode('');
                resetLoginError();
              }}
              forceEnabled={loginError}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content='Close'
          disabled={isAuthingUser}
          onClick={handleClose}
          data-cy='email-verification-modal-close'
        />
        <Button
          color='black'
          content='Continue'
          disabled={!isValid || isAuthingUser}
          loading={isAuthingUser}
          onClick={handleSubmit}
          data-cy='email-verification-modal-submit'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EmailVerificationModal;
