import { configureStore } from '@reduxjs/toolkit'
import { appReducer } from './app-slice';
import appSettings from '../app-settings.json';
import { stepperReducer } from './stepper-slice';

const reducer = {
  app: appReducer, // global level information stored here
  stepper: stepperReducer,
};

const initState = {};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: appSettings.region === 'us-east-2',
  initState,
  enhancers: [],
});
