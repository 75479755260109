import ConditionalFieldItem from './conditional-field-item'
import { Grid } from 'semantic-ui-react';

const LocalInfoItems = (props) => {
  const {
    localContact,
    info: {
      name,
      phone,
      email,
      address,
    },
    hasChanged = false,
  } = props;

  if (localContact === 'property_mgr') {
    return (
      <Grid.Column width={8}>
        <ConditionalFieldItem label='Local Contact' content= "Same as Certificate Holder" />
      </Grid.Column>
    );
  }

  if (localContact === 'owner') {
    return (
      <Grid.Column width={8}>
        <ConditionalFieldItem label='Local Contact' content= "Same as Property Owner" />
      </Grid.Column>
    );
  }

  return (
    <Grid.Column width={8}>
      <ConditionalFieldItem label='Local Contact Name' content={name} />
      <ConditionalFieldItem label='24/7 Phone Number' content={phone} />
      <ConditionalFieldItem label='Email' content={email} hasChanged={hasChanged}/>
      <ConditionalFieldItem label='Mailing Address' content={address} />
    </Grid.Column>
  );
}

export default LocalInfoItems;
