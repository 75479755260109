import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../../../routes/applyTot-route-constants';
import {
  UPDATE_APN_FOR_APPLY_TOT,
  UPDATE_PARCEL_NUMBER,
} from '../../../../state/actions';
import { CertificateContext } from '../../../../state/context';
import { formatParcelNumber } from '../../../../utils/helper-functions';
import { uiSender } from '../../../../utils/ui-sender';
import { INPUT_MAX_LENGTHS, ROUTES } from '../../../common/constants';
import { ERROR_MESSAGES } from '../../../common/error-constants';
import {
  ButtonWrapper,
  HeaderWrapper,
  InputWrapper,
  StyledErrorMessage,
  StyledInput,
  StyledLink,
} from '../../tot-address-styles';

const validParcelLength = 15;
const emptyString = '';

export function ParcelNumberSearch({ setShowResults }) {
  const history = useHistory();

  const {
    state: { parcelNumber },
    dispatch,
  } = useContext(CertificateContext);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(emptyString);

  // Dispatch Functions
  const handleParcelNumberChange = (e, { value }) => {
    dispatch({
      type: UPDATE_PARCEL_NUMBER,
      payload: { parcelNumber: formatParcelNumber(value) },
    });
  };

  /**
   * Dispatches a payload with the relevant data to load when
   *  a valid parcel number has been submitted
   * @param {string} APN APN
   * @param {string} propertyAddress address of the property, defaults to empty string
   * @param {Array} certs list of valid TOT certificates, defaults to empty array
   */
  const updateAPN = (APN, propertyAddress = emptyString, certs = []) => {
    dispatch({
      type: UPDATE_APN_FOR_APPLY_TOT,
      payload: {
        apn: APN,
        propertyAddress,
        certs,
      }
    })
  }

  const validateParcelLength = async () => {
    setIsLoading(true);
    setErrors(null);
    setShowResults(false);

    // Invalid parcel number length (including hyphens)
    if (parcelNumber.length !== validParcelLength) {
      setErrors(ERROR_MESSAGES.InvalidParcelLength);
      setIsLoading(false);
      return false;
    }

    const APN = parcelNumber.replace(/-/g, emptyString);
    updateAPN(APN);

    try {
      const {
        data: { error, propertyAddress, totCertificates },
      } = await uiSender.get(`${ROUTES.totCertLookupByApn}/${APN}`);

      // Errors come back with status 200, but with the "error" field populated with a string
      if (error) throw new Error(error);

      updateAPN(APN, propertyAddress, totCertificates);

      const apnHasExistingTOTs = Boolean(totCertificates.length);

      setShowResults(apnHasExistingTOTs);
      setIsLoading(false);

      return !apnHasExistingTOTs;
    } catch (e) {
      setErrors(e.message);
      setIsLoading(false);
      return false;
    }
  };

  const handleNextClick = async () => {
    const parcelIsValid = await validateParcelLength();
    if (parcelIsValid) history.push(ApplyTotRouteMap[history.location.pathname]);
  }

  return (
    <>
      <HeaderWrapper>
        <p>
          What is the parcel number of your rental property?
        </p>
      </HeaderWrapper>

      <p>
        Example: 123-123-123-000. If the parcel number of your property is 9 digits, add “000” at the end to make it 12 digits.
      </p>

      <InputWrapper>
        <StyledInput
          type='tel'
          placeholder='123-456-789-000'
          value={parcelNumber}
          onChange={handleParcelNumberChange}
          label={{ icon: 'asterisk' }}
          labelPosition='right corner'
          maxLength={INPUT_MAX_LENGTHS.PARCEL_NUMBER}
          data-cy="parcel-input"
        />
      </InputWrapper>

      <ButtonWrapper>
        <StyledLink as={'a'} href="https://www.placer.ca.gov/5866/Property-Details">
          Lookup parcel number by address
        </StyledLink>
      </ButtonWrapper>

      {errors && (
        <StyledErrorMessage negative data-cy="tot-add-error-message">
          {errors}
        </StyledErrorMessage>
      )}

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button
          onClick={handleNextClick}
          color='black'
          loading={isLoading}
          disabled={isLoading}
          data-cy="proceed-btn"
        >
          Apply for a new TOT Certificate
        </Button>
      </ButtonWrapper>
    </>
  )
}
