import styled from 'styled-components';

// Semantic ui uses paddings and margins that are fractions of 1em (14px)
const pixel2rem = (pixel) => pixel / 14;

export const TableMobile = styled.table`
  /* base styles */
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0 0;
  border-collapse: collapse;
  border-spacing: 0;
  th {
    padding: 0 ${pixel2rem(11)}rem ${pixel2rem(13)}rem 0;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
  }
  td {
    padding: ${pixel2rem(11)}rem;
    padding-left: 0;
  }
  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
  }
  tbody tr td:first-child,
  tbody tr td:last-child {
    font-weight: 700;
  }

  /* desktop styles */
  @media (min-width: 769px) {
    tbody tr {
      display: table-row;
    }
    tbody tr td {
      display: table-cell;
      text-align: left;
    }
    tbody tr:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  /* mobile styles */
  @media (max-width: 768px) {
    td {
      display: flex;
      justify-content: space-between;
      min-width: 1px;
      text-align: right;
      padding: 0 !important;
    }
    tbody {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    tbody tr td:last-child {
      border-bottom: 0;
    }
    tbody tr td:first-child .responsive-table__heading {
      display: none;
    }
    tbody tr td:first-child {
      padding-bottom: ${pixel2rem(6)}rem!important;
    }
    tr {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      gap: ${pixel2rem(17)}rem;
      /* margin-bottom: 1.5rem; */

      padding: 0 0.5rem ${pixel2rem(24)}rem 0;
      width: 100%;
    }

    /* hide desktop headings on mobile */
    thead {
      border: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  /* mobile only titles  */
  .responsive-table__heading {
    padding-right: 1rem;
    text-align: left;
    word-break: initial;
    @media (min-width: 769px) {
      display: none;
    }
  }
`;