import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { getCurrentDate } from '../../utils/utility-functions';
import { UPDATE_ACKNOWLEDGE_DETAILS, UPDATE_CERT, UPDATE_TOT_CERTIFICATION_STEP } from '../../state/actions';
import { CertificateContext } from '../../state/context';
import { HeaderWrapper } from '../common/common-styles';
import { useHistory } from 'react-router-dom';
import {
  SignatoryWrapper,
  SignatoryInput,
  SignatoryLabel,
  AcknowledgementSectionWrapper,
} from './tot-sign-styles';
import { ButtonWrapper } from './navigation-buttons-styles';
import { buildTOTApplicationPayload } from './tot-sign-utils';
import { uiSender } from '../../utils/ui-sender';
import { ROUTES } from '../common/constants';
import { ApplyTotRouteMap } from '../../routes/applyTot-route-constants';


const ApplyTotSignaturePage = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CertificateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const { apn } = state;

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 6 },
    });
  }, [dispatch]);

  const handleAcknowledgement = () => {
    dispatch({
      type: UPDATE_ACKNOWLEDGE_DETAILS,
      payload: {
        userConfirmedInfoAccuracy: !state.userConfirmedInfoAccuracy,
      },
    });
  };

  const updateCert = (certNumber) =>
    dispatch({ type: UPDATE_CERT, payload: { certNumber }});

  const handleNameChange = (e, { value }) => setName(value);

  const handleNextClick = async () => {
    setIsLoading(true);

    if (state.userConfirmedInfoAccuracy && name.length > 0) {
      const payload = buildTOTApplicationPayload(state, name);

      try {
        const { data } = await uiSender.post(`${ROUTES.newCert}/${apn}`, payload);
        const { totCertId, error } = data;
        if (!error) {
          updateCert(totCertId);

          history.push(ApplyTotRouteMap[history.location.pathname]);
        } else {
          throw new Error(error);
        }
      } catch (error) {
        history.push('/error');
      }
    }

    setIsLoading(false);
  }

  return (
    <>
      <HeaderWrapper>Please sign below.</HeaderWrapper>
      <SignatoryWrapper>
        <div>
          <SignatoryLabel>Legal Name of Authorized Signatory - Required</SignatoryLabel>
          <SignatoryInput value={name} onChange={handleNameChange} placeholder='Enter your full name' />
        </div>
        <div>
          <SignatoryLabel>Date (MM/DD/YYYY)</SignatoryLabel>
          <SignatoryInput value={getCurrentDate()} disabled />
        </div>
      </SignatoryWrapper>

      <AcknowledgementSectionWrapper>
        <Checkbox
          label='I certify under penalty of perjury
        that this information is accurate and I am an authorized representative
        or owner of this property. I acknowledge that my signature is legally
        binding.'
          checked={state.userConfirmedInfoAccuracy}
          onChange={handleAcknowledgement}
        />
      </AcknowledgementSectionWrapper>

      <ButtonWrapper>
        <Button onClick={history.goBack}>Back</Button>

        <Button
          onClick={handleNextClick}
          color='black'
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </ButtonWrapper>
    </>
  );
};

export const TotSign = () => {
  const history = useHistory();

  if (history.action === "POP") {
    /*
     * Reloading the home page forcefully in order to delete history.
     * This is done in order to prevent the user from using the back button to navigate back
     * into the Apply TOT form once the certificate has been created successfully
     */
    window.history.pushState(null, null, '/'); // will change url in address bar
    window.location.reload(); // this is to delete
    return null;
  }

  return <ApplyTotSignaturePage />
}

export default TotSign;
