import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Image } from 'semantic-ui-react';
import LayoutHeader from '../components/common/layout-header';

const ErrorPage = () => (
  <>
    <LayoutHeader title='Placer County Short-term Rental Registration and Tax Payment System ' />
    <Container style={{ paddingTop: '10rem', color: '#5c5d5d' }}>
      <Header style={{ color: '#5c5d5d', fontSize: '50px', fontWeight: 'normal' }} size='huge'>
        Something went wrong
      </Header>
      <Header style={{ fontWeight: 'normal', color: '#5c5d5d' }}>
        There was an unexpected server error. Please try again later.
      </Header>
      <div style={{ margin: '3rem 0' }}>
        <Button basic content='Back' as={Link} to='/' />
      </div>
      <Image src='../../assets/error-page.svg' />
    </Container>
  </>
);

export default ErrorPage;
