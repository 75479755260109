import appSettings from '../app-settings.json';
import axios from 'axios';
import { Auth } from 'aws-amplify';

export class BaseSender {
  static tokenTypes = {
    ACCESS_TOKEN: 'accessToken',
    ID_TOKEN: 'idToken',
  };

  static axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: 180000, // https://medium.com/@masnun/handling-timeout-in-axios-479269d83c68
  });

  static async setToken() {
    // getting the current session will refresh the token
    const cognitoUserSession = await Auth.currentSession();
    const idToken = cognitoUserSession[BaseSender.tokenTypes.ID_TOKEN].jwtToken;
    BaseSender.axiosInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  }

  dataDomain = appSettings.strPlacerApiDomain;

  /**
   * GET requests with BaseSener
   * @param {string} path
   * @param {object} params GET params. E.g.: { id: 12345 } will send <path>?id=12345
   * Check of this.localDataOverride[path] is checking to see if the data for a specific path
   * was set in local-config.js, e.g. { kpi : true }.  In this case, it will then return
   * data from the dummy-data file. Otherwise, it will return data from the server.
   */
  async get(path, params, responseType = 'json') {
    await BaseSender.setToken();
    return BaseSender.axiosInstance.get(`${this.dataDomain}${path}`, { params, responseType });
  }

  async post(path, body, responseType = '') {
    await BaseSender.setToken();
    const bodyJSON = (body && body.toJSON) ? body.toJSON() : body;
    return BaseSender.axiosInstance.post(`${this.dataDomain}${path}`, bodyJSON, { responseType });
  }
}
