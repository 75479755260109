import React from 'react';
import { FooterLink, FooterWrapper } from './footer-styles';

const Footer = () => (
  <FooterWrapper>
    <div>
      {`For general information regarding Placer County’s response to Short-term
      rentals and the COVID-19 pandemic, please visit our website at `}
      <FooterLink target='_blank' href="https://www.placer.ca.gov/str">
        https://www.placer.ca.gov/str
      </FooterLink>
      {', or email us at '}
      <FooterLink href="mailto:str@placer.ca.gov">str@placer.ca.gov</FooterLink>
      {`. This site is hosted by Deckard Technologies, a service provider to
      Placer County as defined in the California Consumer Privacy Act (CCPA).
      Personal information, opt-out, or deletion requests should be sent
      directly to Placer County. For technical support, please send email to `}
      <FooterLink href="mailto:placer-ca-str@deckard.com">placer-ca-str@deckard.com</FooterLink>
    </div>
  </FooterWrapper>
);

export default Footer;
