export const ROUTES = {
  totCertLookup: '/lookupCert',
  calculateTot: '/calculateTot',
  paymentImmenent: '/totPaymentImminent',
  totCertLookupByApn: '/lookupApn',
  totCertLookupByAddress: '/lookupCertByAddress',
  checkTotPayment: '/checkTotPayment',
  apn: '/apn',
  newCert: '/newCert',
  getCert: '/getCert',
  updateCert: '/updateCert',
};

export const APP_CONTEXT = {
  certificate: 'certificate',
  payment: 'payment',
  updatetot: 'updatetot',
};

export const DATA_KEYS = {
  totCertNo: 'certId',
  ownerName: 'certiticateHolder',
  ownerEmail: 'email',
  propertyAddress: 'propertyAddress',
  submissionDate: 'dateCreated',
  paymentId: 'payment_id',
  grossIncome: 'gross_income',
  totDue: 'tot_due',
  penalty: 'penalty',
  interest: 'interest',
  total: 'total',
  clientSecret: 'client_secret',
  dateCreated: 'dateCreated',
};

export const EMAIL_CODE_LENGTH = 6;

export const INPUT_MAX_LENGTHS = {
  EMAIL_VERIFICATION_CODE: '6',
  PARCEL_NUMBER: '15',
  UNIT_NUMBER: '50',
  NAME: '50',
  PHONE: '20',
  EMAIL: '255',
  ADDRESS: '255',
  URL: '2000',
};

export const ownerLabels = {
  name: 'Owner Name',
  phone: 'Phone Number',
  email: 'Email',
  address: 'Mailing Address',
};

export const propertyManagerLabels = {
  name: 'Manager name',
  phone: 'Phone Number',
  email: 'Email',
  address: 'Mailing Address',
  company: 'Company name',
};

export const localContactLabels = {
  name: 'Local contact name',
  phone: '24/7 Phone Number',
  email: 'Email',
  address: 'Mailing Address (optional)',
};
