import React from 'react';
import * as ap from './str-license-application-requirements-styles';

/**
 * Renders optional table data within the requirements data into
 * a viewable format
 */
export function TableContent({ tableData }) {
  return (
    <>
      {tableData.map((tableRow) => (
        <ap.TableGrid key={`${tableRow[0].label}-row`}>
          {tableRow.map(({ label, type }) => (
            <ap.TableCell key={label} type={type}>
              {label}
            </ap.TableCell>
          ))}
        </ap.TableGrid>
      ))}
    </>
  )
}
