import React, { useContext } from 'react';
import { Steps, Icon } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
// eslint-disable-next-line import/no-cycle
import { CertificateContext, PaymentContext } from '../../state/context';

const styles = {
  width: '200px',
  display: 'inline-table',
  verticalAlign: 'top',
};

const Stepper = ({ steps, context }) => {
  let currentContext = null;
  if (context === 'certificate') {
    currentContext = CertificateContext;
  } else {
    currentContext = PaymentContext;
  }
  const { state } = useContext(currentContext);

  return (
    <div>
      <Steps current={state.currentStep} vertical style={styles}>
        {steps.map((step) => (
          <Steps.Item title={step} key={step} icon={<Icon icon='circle' />} />
        ))}
      </Steps>
    </div>
  );
};

export default Stepper;
