import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { UpdateTOTContext } from '../../state/context';
import {
  UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO,
  UPDATE_MANAGER_INFO,
} from '../../state/actions';
import ContactSectionAddress from '../apply-tot/contact-section-address';
import { SectionHeadingWrapper } from './update-details-styles';
import { HeaderWrapper } from '../common/common-styles';
import { ButtonWrapper } from '../common/navigation-buttons-styles';
import { localContactLabels, propertyManagerLabels } from '../common/constants';
import { contactFormValidation, getAddressSuggestions } from '../../utils/helper-functions';
import { ERROR_MESSAGES } from '../common/error-constants';
import { StyledErrorMessage } from '../apply-tot/tot-address-styles';

export const UpdateContact = () => {
  // Hooks
  const history = useHistory();
  const {
    state: {
      managerInfo,
      localContactInfo,
      manageRental,
    },
    dispatch
  } = useContext(UpdateTOTContext);
  const [initialDataLoading, setInitialDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGES.NoErrorsArray);
  const unalteredData = useRef({managerInfo, localContactInfo});

  // For auto-suggestions of property manager address input
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // For auto-suggestions of local point of contact address input
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [localSuggestions, setLocalSuggestions] = useState([]);

  useEffect(() => {
    /**
     * Address may come in as null
     * Default to empty string, if address is null
     * Prevents React error about value of input being null instead empty string
     */
    setSearchTerm(managerInfo.address || '');
    setLocalSearchTerm(localContactInfo.address || '');
    setInitialDataLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   // Triggers auto-suggests for property manager mailing address
   useEffect(() => {
    if (Boolean(searchTerm)) {
      (async () => setSuggestions( await getAddressSuggestions(searchTerm)))();
    } else if (initialDataLoading) {
      updateManagerInfo({ ...managerInfo, address: searchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // Triggers auto-suggests for local point of contact mailing address
  useEffect(() => {
    if (Boolean(localSearchTerm)) {
      (async () => setLocalSuggestions( await getAddressSuggestions(localSearchTerm)))();
    } else if (initialDataLoading) {
      updateLocalContactInfo({ ...localContactInfo, address: localSearchTerm });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchTerm]);

  // Dispatch Functions
  const updateLocalContactInfo = (payload) => {
    dispatch({
      type: UPDATE_INFO_UPDATE_LOCAL_CONTACT_INFO,
      payload,
    });
  };

  const updateManagerInfo = (payload) => {
    dispatch({ type: UPDATE_MANAGER_INFO, payload });
  };

  // Click Handlers
  const handleNextClick = () => {
    setIsLoading(true);
    setErrorMessages(ERROR_MESSAGES.NoErrorsArray);
    let errorMsgs = [];

    /**
     * Validate property manager info if and only if,
     *  manageRental (operatesRental) is the property manager
     */
    if (manageRental === 'property_mgr') {
      const managerLabel = 'property manager';
      const formErrors = contactFormValidation(
        managerInfo,
        ['company'],
        managerLabel,
      );
      if (formErrors) errorMsgs.push(formErrors);
    }

    if (localContactInfo.whoIs === 'someoneElse') {
      // Validate local point of contact info
      const localPOCLabel = 'local contact person';
      const localPOCFormErrors = contactFormValidation(
        localContactInfo,
        ['address'],
        localPOCLabel,
      );
      if (localPOCFormErrors) errorMsgs.push(localPOCFormErrors);
    }

    if (errorMsgs.length) {
      setErrorMessages(errorMsgs);
    } else {
      // Go to online marketplace listings if there are no errors
      // history.push('/update-info/update-listings'); // TODO: put back when server supports this
      history.push('/update-info/update-review')
    }
    setIsLoading(false);
  }

  // Reverts all changes made to managerInfo and localContactInfo when go back to previous form
  const handleBackClick = () => {
    if (manageRental === 'property_mgr') updateManagerInfo(unalteredData.current.managerInfo);
    if (localContactInfo.whoIs === 'someoneElse') updateLocalContactInfo(unalteredData.current.localContactInfo);
    history.goBack();
  }

  return (
    <>
      <HeaderWrapper>Update point of contact information below.</HeaderWrapper>

      <Grid stackable>
        <Grid.Column width={8}>
          {manageRental === 'property_mgr' &&
            <>
              <SectionHeadingWrapper>Property Manager Information</SectionHeadingWrapper>
              <ContactSectionAddress
                labels={propertyManagerLabels}
                info={managerInfo}
                updateInfo={updateManagerInfo}
                searchTerm={searchTerm}
                suggestions={suggestions}
                setSearchTerm={setSearchTerm}
                disabledFields={{ company: true, name: true }}
              />
            </>
          }

          {localContactInfo.whoIs === 'someoneElse' &&
            <>
              <SectionHeadingWrapper>24-Hour Local Contact Person</SectionHeadingWrapper>
              <ContactSectionAddress
                labels={localContactLabels}
                info={localContactInfo}
                updateInfo={updateLocalContactInfo}
                searchTerm={localSearchTerm}
                suggestions={localSuggestions}
                setSearchTerm={setLocalSearchTerm}
              />
            </>
          }
        </Grid.Column>
      </Grid>

      {errorMessages && (
        <StyledErrorMessage negative>
          {errorMessages.map((error, idx) => error)}
        </StyledErrorMessage>
      )}

      <ButtonWrapper>
        <Button
          basic
          content='Back'
          onClick={handleBackClick}
          loading={isLoading}
          disabled={isLoading}
        />
        <Button
          color='black'
          content='Continue'
          onClick={handleNextClick}
          loading={isLoading}
          disabled={isLoading}
        />
      </ButtonWrapper>
    </>
  )
}
