import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { AuthenticatedRoute } from './authenticated-route';
import CertificateByAddress from '../components/tot-pay/certificate-by-address';
import ConfirmAndSign from '../components/tot-pay/confirm-and-sign';
import FindTOT from '../components/tot-pay/find-tot';
import { APP_CONTEXT } from '../components/common/constants';
import PayConfirmation from '../components/tot-pay/pay-confirmation';
import ReportRevenue from '../components/tot-pay/report-revenue';
import TOTStripePayment from '../components/tot-pay/tot-payment';

const PayTotRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <FindTOT />
      </Route>
      <Route path={`${path}/address`}>
        <CertificateByAddress />
      </Route>
      <AuthenticatedRoute path={`${path}/revenue`} context={APP_CONTEXT.payment}>
        <ReportRevenue />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${path}/sign`} context={APP_CONTEXT.payment}>
        <ConfirmAndSign />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${path}/payment`} context={APP_CONTEXT.payment}>
        <TOTStripePayment />
      </AuthenticatedRoute>
      <Route path={`${path}/confirm`} context={APP_CONTEXT.payment}>
        <PayConfirmation />
      </Route>
      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  );
};

export default PayTotRoutes;
