import React, { useState } from 'react';
import { Grid, Icon, Message, Popup, Button } from 'semantic-ui-react';
import { checkIsValidDecimal, checkIsValidNumber } from '../../utils/helper-functions';
import AvailableDaysWarningModal from './available-days-warning-modal';
import {
  ContentWrapper,
  InputLabelSection,
  StyledInput,
  StyledHeader,
  QuarterSectionWrapper,
  ResponsiveGrid,
  TitleWrap,
  ResponsiveMessage,
} from './quarter-section-styles';

const QuarterSection = ({ quarter, setQuarters, quarters, handleDeleteClick, noRevenueQuarter = false }) => {
  const [open, setOpen] = useState(false);
  const [availableDaysInvalid] = useState(false);
  const [daysOccupiedInvalid, setDaysOccupiedInvalid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { title, taxableReceipts, tbidExcluded, numDaysAvailable, noOfDays, numDaysOccupied } = quarter;

  const handleInputChange = (e, data) => {
    const lastChar = data.value.slice(-1);
    let theValue = data.value === '' ? '' : Number(data.value).toString();
    if (lastChar === '.') {
      theValue = `${theValue}${lastChar}`;
    }
    if (data.value === '' || data.value >= 0) {
      if (e.target.name === 'numDaysAvailable') {
        if (data.value <= noOfDays) {
          if (checkIsValidNumber(theValue) || data.value === '') {
            setQuarters([
              ...quarters.map((item) => {
                if (item.title === title) {
                  item.numDaysAvailable = theValue;
                }
                return item;
              }),
            ]);
          }
        }
      } else if (e.target.name === 'numDaysOccupied') {
        if (checkIsValidNumber(theValue) || data.value === '') {
          if (data.value !== '') {
            if (numDaysAvailable === '' || Number(data.value) > Number(numDaysAvailable)) {
              // setOpen(true);
              setDaysOccupiedInvalid(true);
              setErrorMsg('Days occupied cannot exceed days available. Please enter a value less than available days to clear error.');
              theValue = '';
            } else {
              setDaysOccupiedInvalid(false);
              setErrorMsg('');
            }
          }
          setQuarters([
            ...quarters.map((item) => {
              if (item.title === title) {
                item.numDaysOccupied = theValue;
              }
              return item;
            }),
          ]);
        }
      } else if (e.target.name === 'tbidExcluded') {
        if (checkIsValidDecimal(theValue) || data.value === '') {
          setQuarters([
            ...quarters.map((item) => {
              if (item.title === title) {
                item.tbidExcluded = theValue;
              }
              return item;
            }),
          ]);
        }
      } else {
        if (checkIsValidDecimal(theValue) || data.value === '') {
          setQuarters([
            ...quarters.map((item) => {
              if (item.title === title) {
                item.taxableReceipts = theValue;
              }
              return item;
            }),
          ]);
        }
      }
    }
  };

  return (
    <>
      <AvailableDaysWarningModal open={open} setOpen={setOpen} />
      <QuarterSectionWrapper>
        <TitleWrap>
          <StyledHeader>{title}</StyledHeader>
          {handleDeleteClick && (
            <button
              icon
              onClick={() => {
                handleDeleteClick(quarter.name);
              }}
            >
              <Icon name="delete" />
            </button>
          )}
        </TitleWrap>

        <ResponsiveGrid>
          <ContentWrapper>
            <InputLabelSection>
              <div>Taxable Receipts</div>
              <Popup
                content="Enter the gross amount made in the quarter"
                trigger={<Icon name="info circle" />}
                inverted
              />
            </InputLabelSection>
            <StyledInput
              name="taxableReceipts"
              value={taxableReceipts}
              onChange={(e, data) => handleInputChange(e, data)}
              label={{ icon: 'asterisk' }}
              labelPosition="right corner"
              disabled={noRevenueQuarter}
              icon="dollar"
              iconPosition="left"
            />
          </ContentWrapper>

          {/* <ContentWrapper>
            <InputLabelSection>
              <div>TBID exclusion amount</div>
              <Popup
                content="Enter the amount of revenue collected before tbid"
                trigger={<Icon name="info circle" />}
                inverted
              />
            </InputLabelSection>
            <StyledInput
              name="tbidExcluded"
              value={tbidExcluded}
              onChange={(e, data) => handleInputChange(e, data)}
              label={{ icon: 'asterisk' }}
              labelPosition="right corner"
              disabled={noRevenueQuarter}
              icon="dollar"
              iconPosition="left"
            />
          </ContentWrapper> */}

          <ContentWrapper>
            <InputLabelSection>
              <div>Number of Days Available</div>
              <Popup
                content="Enter the number of days available in the quarter (e.g. 90)"
                trigger={<Icon name="info circle" />}
                inverted
              />
            </InputLabelSection>
            <StyledInput
              name="numDaysAvailable"
              value={numDaysAvailable}
              onChange={(e, data) => handleInputChange(e, data)}
              label={{ icon: 'asterisk' }}
              labelPosition="right corner"
              error={availableDaysInvalid}
            />
          </ContentWrapper>

          <ContentWrapper>
            <InputLabelSection>
              <div>Number of Days Occupied</div>
              <Popup
                content="Enter the number of days the property was occupied in the quarter"
                trigger={<Icon name="info circle" />}
                inverted
              />
            </InputLabelSection>
            <StyledInput
              name="numDaysOccupied"
              value={numDaysOccupied}
              onChange={(e, data) => handleInputChange(e, data)}
              label={{ icon: 'asterisk' }}
              labelPosition="right corner"
              disabled={noRevenueQuarter}
              error={daysOccupiedInvalid}
            />
          </ContentWrapper>
        </ResponsiveGrid>
        {errorMsg && (
        <ResponsiveMessage>
          <Message error>
            {errorMsg}
          </Message>
        </ResponsiveMessage>
        )}
      </QuarterSectionWrapper>
    </>
  );
};

export default QuarterSection;
