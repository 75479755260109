import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, Icon } from 'semantic-ui-react';
import { SiteLogo, StyledMenuItem, StyledMenuItemMobile  } from './layout-header-styles';

const LayoutHeader = ({ title, isHelp }) => (
  <>
    <Menu fixed='top' size='small' borderless>
      <Menu.Item as={'a'} href='https://www.placer.ca.gov/6109/Short-Term-Rental-Program' header>
        <SiteLogo src='../../assets/Placer-County.png' alt='placer county logo' />
      </Menu.Item>
      <StyledMenuItem>{title}</StyledMenuItem>
      <Menu.Menu position='right'>
        <Menu.Item>
          <Button size='tiny' as={Link} to='/' color='teal'>
            <Icon name='tasks' />
            Main Menu
          </Button>
        </Menu.Item>
        <Menu.Item>
          {isHelp ? (
            <Link style={{ color: '#000' }} to='/'>
              Back
            </Link>
          ) : (
            <Link style={{ color: '#000' }} to='/help'>
              Help
            </Link>
          )}
        </Menu.Item>
      </Menu.Menu>
    </Menu>
    <StyledMenuItemMobile className="ui container">{title}</StyledMenuItemMobile>

  </>
);

export default LayoutHeader;
