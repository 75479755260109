import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

export const SectionContainer = styled.div`
  color: #5c5d5d;
`;

export const HeaderWrapper = styled.div`
  font-size: 18px;
  padding: 1rem 0;
`;

export const PageContent = styled.p`
  padding: 1rem 0;
`;

export const DeclarationWrapper = styled.div`
  margin: 1rem 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    label {
      color: #5c5d5d;
    }
  }
`;

export const ButtonSectionWrapper = styled.div`
  margin: 1rem 0;
`;

export const ImageSectionContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row-reverse;
`;
