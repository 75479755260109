import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import {
  SectionWrapper,
  HeadingContainer,
  ConfirmationNumberContainer,
  StyledParagraph,
} from './pay-confirmation-styles';
// import applauseSound from '../../../public/assets/applause.mp3';
import { PaymentContext } from '../../state/context';
import { UPDATE_PAYMENT_STEP } from '../../state/actions';
import { Auth } from 'aws-amplify';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size'

const CCConfirmation = ({ paymentConfirmation }) => (
  <>
    <HeadingContainer>Confirmation Number</HeadingContainer>
    <ConfirmationNumberContainer>{paymentConfirmation}</ConfirmationNumberContainer>
    <StyledParagraph>
      Your Transient Occupancy Tax payment has been submitted successfully. We have emailed you details of the receipt
      and confirmation.
    </StyledParagraph>
  </>
);

const ACHConfirmation = ({ paymentConfirmation }) => (
  <>
    <HeadingContainer>Payment in Process</HeadingContainer>
    <ConfirmationNumberContainer>Payment Underway!</ConfirmationNumberContainer>
    <ConfirmationNumberContainer>{paymentConfirmation}</ConfirmationNumberContainer>
    <StyledParagraph>
      Thank you for submitting your payment. Payment by bank transfer typically takes 5-7 days to clear.
      We will notify you by email as soon as the payment is settled.
    </StyledParagraph>
  </>
);

const PayNothingConfirmation = ({ paymentConfirmation }) => (
  <>
    <HeadingContainer>No Revenue Reported</HeadingContainer>
    <ConfirmationNumberContainer>{paymentConfirmation}</ConfirmationNumberContainer>
    <StyledParagraph>
      Thank you for submitting your quarter data for recordation.
    </StyledParagraph>
  </>
)

const PayConfirmation = () => {
  const { state, dispatch } = useContext(PaymentContext);
  const location = useLocation();

  const paymentType = location?.state?.paymentType;

  useEffect(() => {
    dispatch({
      type: UPDATE_PAYMENT_STEP,
      payload: { currentStep: 4 },
    });
  }, [dispatch]);

  useEffect(() => {
    Auth.signOut();
    //window.confetti.start(7000);
    // <confetti />
  }, []);

  const contentComponents = {
    cc: CCConfirmation,
    ach: ACHConfirmation,
    noPay: PayNothingConfirmation,
  }

  const ConfirmationContent = contentComponents[paymentType];
  const { width, height} = useWindowSize();
  // user landed on this page directly
  if (!paymentType) return null;

  return (
    <SectionWrapper>
      <Confetti width={width} height={height} recycle={false} numberOfPieces={500} duration={7}></Confetti>
      <audio src='../../../assets/applause.mp3' autoPlay/>
      <ConfirmationContent paymentConfirmation={state?.paymentConfirmation} />
      <Image src='../../../assets/tot-pay-confirmation.svg' />
    </SectionWrapper>
  );
};

export default PayConfirmation;
