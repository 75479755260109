import styled from 'styled-components';

export const FooterWrapper = styled.div`
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledAnchor = styled.a`
  color: #027680;
`;
