import { Checkbox, Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  &&&&& {
    color: #5c5d5d !important;
  }
`;

export const HeaderWrapper = styled.div`
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

export const SignatoryWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  flex: wrap;
  gap: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    & > div {
      width: 100%;
    }
  }
`;

export const AcknowledgementSectionWrapper = styled.div`
  margin: 1rem 0;
`;

export const AcknowledgementContent = styled.p`
  margin: 1rem 0;
`;

export const SignatoryLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const SignatoryInput = styled(Input)`
  margin: 0.5rem 0;
  width:100%;
`;

export const NameSectionWrapper = styled.div`
`;

export const StyledCheckbox = styled(Checkbox)`
  /* > .ui.checkbox {
    color: #5c5d5d;
    input {
      color: #5c5d5d;
    }
  } */
  /* & > div {
    color: #5c5d5d !important;
  } */

  /* & > div > label {
    color: #5c5d5d !important;
  } */
`;