import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const SignatoryWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  flex: wrap;
  gap:5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap:1rem;
    .ui.input{
      width:100%;
    }
  }
`;

export const AcknowledgementSectionWrapper = styled.div`
  margin: 1rem 0;
`;

export const AcknowledgementContent = styled.p`
  margin: 1rem 0;
`;

export const SignatoryLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const SignatoryInput = styled(Input)`
  margin: 0.5rem 0;
  width: 250px;
`;
