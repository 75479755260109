import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import { styledAnchor, styledLink, StyledCard } from './section-card-styles';

const SectionCard = ({ title, image, url, target }) => {
  const urlPath = `../../assets/${image}`;

  return (
    <StyledCard>
      <Card as={target ? styledAnchor : styledLink} url={url} target={target}>
        <Image src={urlPath} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{title}</Card.Header>
        </Card.Content>
      </Card>
    </StyledCard>
  );
};

export default SectionCard;
