import { CREDIT_CARD_TAB } from '../checkout-form/checkout-constants';

export const initialPaymentState = {
  tot: null,
  emailAddress: '',
  isEmailVerified: false,
  propertyAddress: null,
  certificateHolder: null,
  totConfirmationNumber: null,
  reportRevenueDetails: null,
  checkoutDetails: null,
  acknowledgeCheckoutDetails: false,
  currentStep: 0,
  isReportReveneueDetailsSet: false,
  quarters: [],
  previousQuarters: [],
  selectedQuarters: [],
  previousQuartersChecked: null,
  noRevenueQuarter: false,
  signatoryName: '',
  activePaymentMethodTab: CREDIT_CARD_TAB,
  paymentConfirmation: null,
};

export const initialCertificateState = {
  apn: null,
  parcelNumber: '',
  certs: [],
  currentStep: 0,
  certNumber: null,
  acknowledgeDetails: false,
  propertyAddress: '',
  rentalType: '',
  secondaryDwelling: false,
  ownerName: '',
  ownerPhone: '',
  ownerMailingAddress: '',
  isOwnerAddressPropertyAddress: false,
  isLocalContactOwner: false,
  localContactName: null,
  localContactPhone: null,
  localContactEmail: null,
  localContactMailingAddress: null,
  isOwnerPropertyManager: false,
  propertyManagerName: null,
  propertyManagerPhone: null,
  propertyManagerEmail: null,
  propertyManagerMailingAddress: null,
  userConfirmedInfoAccuracy: false,
  listingUrls: [''],
  ownerInfo: { name: '', phone: '', email: '', address: '' },
  managerInfo: { name: '', phone: '', email: '', address: '' },
  localContactInfo: { name: '', phone: '', email: '', address: '' },
  manageRental: '',
  localContact: null,
  sameAsRental: false,
  unitNumber: '',
  noOfUnits: '',
  hasUnitNumber: false,
  multipleUnit: false,
  companyName: '',
};

export const initialUpdateTotState = {
  ownerInfo: { name: '', phone: '', email: '', address: '' },
  localContactInfo: { name: '', phone: '', email: '', address: '' },
  companyName: '',
  managerInfo: { name: '', phone: '', email: '', address: '' },
  parcelNumber: '',
  propertyAddress: '',
  totCertificateNumber: null,
};
