import styled from 'styled-components';

export const SectionContainer = styled.div`
  color: #5c5d5d;
`;

export const HeaderWrapper = styled.div`
  font-size: 18px;
  margin: 0.5rem 0;
`;

export const FieldWrapper = styled.div`
  font-size: 14px;
  margin: 1rem 0;
`;

export const FieldLabel = styled.div`
  font-size: 10px;
  color: #7f8fa4;
`;

export const ButtonContainer = styled.div`
  margin: 1rem 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3rem;
`;

export const StyledAmountContent = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000000;
`;

export const StyledAmountWrapper = styled.div`
  margin-bottom: 3rem;
`;
