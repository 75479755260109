import React, { useReducer } from 'react';
import { Grid } from 'semantic-ui-react';
import { WrapperContainer, HideMobile } from '../components/common/common-styles';
import LayoutHeader from '../components/common/layout-header';
import Stepper from '../components/common/stepper';
import { PaymentSteps } from '../utils/steps';
import { PaymentContext } from '../state/context';
import { paymentReducer } from '../state/reducer';
import { initialPaymentState } from '../state/initial-state';
import PayTotRoutes from '../routes/payTot';

const TOTPayment = () => {
  const [state, dispatch] = useReducer(paymentReducer, initialPaymentState);

  return (
    <>
      <PaymentContext.Provider value={{ state, dispatch }}>
        <LayoutHeader title="Placer County Online TOT Payment" />
        <WrapperContainer>
          <Grid stackable>
            <Grid.Column width={12}>
              <PayTotRoutes />
            </Grid.Column>
            <Grid.Column width={4}>
              <HideMobile>
                <Stepper steps={PaymentSteps} currentStep={1} />
              </HideMobile>
            </Grid.Column>
          </Grid>
        </WrapperContainer>
      </PaymentContext.Provider>
    </>
  );
};

export default TOTPayment;
