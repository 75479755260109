import ConditionalFieldItem from './conditional-field-item'
import { Grid } from 'semantic-ui-react';

const ManagmentInfoItems = (props) => {
  const {
    manageRental,
    managerInfo,
    hasChanged = false,
  } = props;

  if (manageRental === 'owner') {
    return null;
  }

  return (
    <Grid.Column width={8}>
      <ConditionalFieldItem label='Property Management Company Name' content={managerInfo.company} />
      <ConditionalFieldItem label='Property Manager Name' content={managerInfo.name} />
      <ConditionalFieldItem label='Phone Number' content={managerInfo.phone} />
      <ConditionalFieldItem label='Email' content={managerInfo.email} hasChanged={hasChanged}/>
      <ConditionalFieldItem label='Mailing Address' content={managerInfo.address} />
    </Grid.Column>
  );
}

export default ManagmentInfoItems;
