import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const styledLink = styled(Link).attrs(props => ({
  to: props.url,
}))`
  :hover {
    text-decoration: none;
  }
`;

export const styledAnchor = styled.a.attrs(props => ({
  href: props.url,
  target: props.target,
}))`
  :hover {
    text-decoration: none;
  }
`;

export const StyledCard = styled.div`
  @media (max-width: 768px) {
    & > * {
      width: 100% !important;
      padding-bottom: 200px;
    }
  }
`;