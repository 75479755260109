import React from 'react';
import { FieldLabel, FieldWrapper } from '../tot-pay/tot-payment-styles';

export const FieldItemList = ({ label, content }) => (
  <FieldWrapper>
    <FieldLabel>{label}</FieldLabel>
    {content.map((item, index) => (
      <p key={index}>{item}</p>
    ))}
  </FieldWrapper>
);
