/* eslint-disable operator-linebreak */
export const availableDaysInAQuarter = (quarter, year) => {
  let noOfDays = null;
  let dateStart = '';
  let dateEnd = '';
  if (quarter === 1) {
    noOfDays = new Date(year, 1, 0).getDate() + new Date(year, 2, 0).getDate() + new Date(year, 3, 0).getDate();
    dateStart = `${year}/01`;
    dateEnd = `${year}/03`;
  } else if (quarter === 2) {
    noOfDays = new Date(year, 4, 0).getDate() + new Date(year, 5, 0).getDate() + new Date(year, 6, 0).getDate();
    dateStart = `${year}/04`;
    dateEnd = `${year}/06`;
  } else if (quarter === 3) {
    noOfDays = new Date(year, 7, 0).getDate() + new Date(year, 8, 0).getDate() + new Date(year, 9, 0).getDate();
    dateStart = `${year}/07`;
    dateEnd = `${year}/09`;
  } else {
    noOfDays = new Date(year, 10, 0).getDate() + new Date(year, 11, 0).getDate() + new Date(year, 12, 0).getDate();
    dateStart = `${year}/10`;
    dateEnd = `${year}/12`;
  }
  return { noOfDays, dateStart, dateEnd };
};

export const makeName = (y, q) => {
  return `${q}-${y}`;
}

export const getQuarterDetails = (quarter = null, year = null) => {
  const date = new Date();
  let title = '';
  let q = null;
  let y = null;
  if (quarter === null && year === null) {
    q = Math.ceil((date.getMonth() + 1) / 3);
    y = date.getFullYear();
    if (q === 1) {
      q = 4;
      y = y - 1;
    } else {
      q = q - 1;
    }
  } else {
    q = quarter;
    y = year;
  }
  const { noOfDays, dateStart, dateEnd } = availableDaysInAQuarter(q, y);

  if (q === 1) {
    title = `January ${y} to March ${y}`;
  } else if (q === 2) {
    title = `April ${y} to June ${y}`;
  } else if (q === 3) {
    title = `July ${y} to September ${y}`;
  } else {
    title = `October ${y} to December ${y}`;
  }

  return [
    {
      noOfDays,
      title,
      quarter: q,
      year,
      name: makeName(year, q),
      dateStart,
      dateEnd,
      taxableReceipts: '',
      numDaysOccupied: '',
      numDaysAvailable: '',
    },
  ];
};

export const getPastQuarters = () => {
  const date = new Date();
  let q = Math.ceil((date.getMonth() + 1) / 3);
  let y = date.getFullYear();

  if (q === 1) {
    q = 4;
    y = y - 1;
  } else {
    q = q - 1;
  }

  let currentYearQuarters = {};
  let currentYearChecked = {};
  if (q === 4) {
    currentYearQuarters = {
      year: y,
      quarters: [
        { title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y}` },
        { title: 'Apr-Jun', quarter: 2, checked: false, name: `2-${y}` },
        { title: 'Jul-Sep', quarter: 3, checked: false, name: `3-${y}` },
      ],
    };
    currentYearChecked = { [`1-${y}`]: false, [`2-${y}`]: false, [`3-${y}`]: false };
  } else if (q === 3) {
    currentYearQuarters = {
      year: y,
      quarters: [
        { title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y}` },
        { title: 'Apr-Jun', quarter: 2, checked: false, name: `2-${y}` },
      ],
    };
    currentYearChecked = { [`1-${y}`]: false, [`2-${y}`]: false };
  } else if (q === 2) {
    currentYearQuarters = {
      year: y,
      quarters: [{ title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y}` }],
    };
    currentYearChecked = { [`1-${y}`]: false };
  }

  const previousQuarters = [
    {
      year: y - 3,
      quarters: [
        { title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y - 3}` },
        { title: 'Apr-Jun', quarter: 2, checked: false, name: `2-${y - 3}` },
        { title: 'Jul-Sep', quarter: 3, checked: false, name: `3-${y - 3}` },
        { title: 'Oct-Dec', quarter: 4, checked: false, name: `4-${y - 3}` },
      ],
    },
    {
      year: y - 2,
      quarters: [
        { title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y - 2}` },
        { title: 'Apr-Jun', quarter: 2, checked: false, name: `2-${y - 2}` },
        { title: 'Jul-Sep', quarter: 3, checked: false, name: `3-${y - 2}` },
        { title: 'Oct-Dec', quarter: 4, checked: false, name: `4-${y - 2}` },
      ],
    },
    {
      year: y - 1,
      quarters: [
        { title: 'Jan-Mar', quarter: 1, checked: false, name: `1-${y - 1}` },
        { title: 'Apr-Jun', quarter: 2, checked: false, name: `2-${y - 1}` },
        { title: 'Jul-Sep', quarter: 3, checked: false, name: `3-${y - 1}` },
        { title: 'Oct-Dec', quarter: 4, checked: false, name: `4-${y - 1}` },
      ],
    },
  ];
  const previousChecked = {
    [`1-${y - 3}`]: false,
    [`2-${y - 3}`]: false,
    [`3-${y - 3}`]: false,
    [`4-${y - 3}`]: false,
    [`1-${y - 2}`]: false,
    [`2-${y - 2}`]: false,
    [`3-${y - 2}`]: false,
    [`4-${y - 2}`]: false,
    [`1-${y - 1}`]: false,
    [`2-${y - 1}`]: false,
    [`3-${y - 1}`]: false,
    [`4-${y - 1}`]: false,
    ...currentYearChecked,
  };
  previousQuarters.push(currentYearQuarters);

  return { previousQuarters, previousChecked };
};

export const getCurrentDate = () => {
  const today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  return `${mm}/${dd}/${yyyy}`;
};

export function numberWithCommas(num) {
  if (!num && num !== 0) return '';
  let temp = num.toFixed(2);
  return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberStartsWithDollarSign(num) {
  if (!num && num !== 0) return null;
  return `$${numberWithCommas(num)}`;
}

export function secToMinSec(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const divider = seconds > 9 ? ':' : ':0';
  return `${minutes}${divider}${seconds}`;
}

/**
 * Function that trims whitespace from values within the dictionary
 * @param {Object} contactInfo contact information
 * @returns the same object with any extra whitespace trimmed
 */
export function contactInfoWhiteSpaceTrimmer(contactInfo) {
  const trimmedInfo = contactInfo;

  for (let key in trimmedInfo) {
    trimmedInfo[key] = trimmedInfo[key].trim();
  }

  return trimmedInfo;
}
