import React from 'react';
import { TableContent } from './table-content';
import * as ap from './str-license-application-requirements-styles';
import * as cs from '../../components/common/common-styles';

/**
 * Renders the requirements data into a viewable list format
 */
export function ListItem(props) {
  const {
    id,
    index,
    header,
    text,
    tableData,
    links = [],
  } = props;

  return (
    <ap.ListItem>
      <ap.ListLabel>{index + 1}</ap.ListLabel>

      <ap.ListContent>
        <ap.ListHeader>{header}</ap.ListHeader>
        <ap.ListText>{text}</ap.ListText>
        {links.map(({ label, link }) => (
          <cs.Link
            key={id}
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ paddingTop: '5px' }}
          >
            {label}
          </cs.Link>
        ))}

        {tableData && <TableContent tableData={tableData} />}
      </ap.ListContent>
  </ap.ListItem>
  )
}
