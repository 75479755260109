import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import { ApplyTotRouteMap } from '../../../../routes/applyTot-route-constants';
import { CertificateContext } from '../../../../state/context';
import {
  ButtonWrapper,
  Certificate,
  Grid,
  ResultsWrapper,
  StyledButton,
  StyledText,
  Text,
} from './existing-certificates-display-styles';

function ExistingCertificate(props) {
  const history = useHistory();

  return (
    <Certificate>
      <StyledText>Existing TOT Certificate</StyledText>
      {/* NOTE: That the typo "certiticateHolder" is purposeful as that's the field in the response JSON */}
      <StyledText>{`${props.certId} ${props.certiticateHolder && `- ${props.certiticateHolder}`}`}</StyledText>
      <div>{props.propertyAddress}</div>
      <div>Valid since {props.dateCreated}</div>
      <StyledButton onClick={() => history.push('/pay-tot')}>Pay TOT</StyledButton>
    </Certificate>
  )
}

function NavigationCertificate(props) {
  return (
    <Certificate noBackground={true} >
      <StyledText gridIndex={4}>{`${props.isSingular ? 'Not you?' : 'None of these you?'}`}</StyledText>
      <StyledButton width="250px" onClick={props.navFn}>Apply for a new TOT Certificate</StyledButton>
    </Certificate>
  )
}

export function ExistingCertificatesDisplay({ setShowResults }) {
  const history = useHistory();

  const {
    state: { certs: totCertificates = [] },
  } = useContext(CertificateContext);

  const onlyOneTOTCert = useMemo(() => totCertificates.length === 1, [totCertificates])

  const handleBackClick = useCallback(() => {
    setShowResults(false);
  }, []);

  const handleNextClick = useCallback(() => {
    history.push(ApplyTotRouteMap[history.location.pathname]);
  }, [history]);

  if (!totCertificates.length) return null;

  return (
    <ResultsWrapper>
      <Header>{`${onlyOneTOTCert ? 'A TOT Certificate' : 'TOT Certificates'} already exist${onlyOneTOTCert ? 's' : ''} for this property.`}</Header>
      <Text>You can pay your TOT or apply for a new TOT Certificate if necessary. Please select what you would like to do below.</Text>

      <Grid>
        {totCertificates.map((certificate) => <ExistingCertificate key={certificate.certId} {...certificate} />)}
        <NavigationCertificate isSingular={onlyOneTOTCert} navFn={handleNextClick} />
      </Grid>

      <ButtonWrapper marginTop="40px">
        <Button onClick={handleBackClick}>Back</Button>
      </ButtonWrapper>
    </ResultsWrapper>
  )
}
