/* eslint-disable no-unused-vars */
import React, { useContext, useState, useCallback, useRef } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Wrapper, CardField, FieldLabel, PayButton, StyledInput, TabWrapper, Tab, TabItem } from './stripe-form-styles';
import { CONSTSTRINGS, ACH_PAYMENT_TAB, CREDIT_CARD_TAB } from './checkout-constants';
import { PaymentContext } from '../state/context';
import { ROUTES } from '../components/common/constants';
import { authSender } from '../utils/auth-sender';
import ACHTab from './ach-tab';
import { useHistory } from 'react-router-dom';
import { PAYMENT_METHOD_TAB_SELECTED, UPDATE_PAYMENT_CONFIRMATION } from '../state/actions';
import { payCC } from './stripe-functions';
import { Checkbox } from 'semantic-ui-react'
import * as Sentry from '@sentry/browser';
import { Alert } from 'rsuite';
import { ERROR_MESSAGES } from '../components/common/error-constants';

const CARD_OPTIONS = {
  style: {
    base: {
      fontWeight: 500,
      fontFamily: 'Nunito Sans, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
    },
  },
};

function onCaughtError(err, { tot, receiptEmail, signatoryName }) {
  Sentry.withScope(function(scope) {
    scope.setUser({ id: tot, email: receiptEmail, username: signatoryName });
    Sentry.captureException(err);
  });
}

export function StripeForm() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const { state, dispatch } = useContext(PaymentContext);

  const [bankIdentifiers, setBankIdentifiers] = useState();
  const [emailInputValue, setEmailInputValue] = useState('');
  const [lockPayment, setLockPayment] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { signatoryName } = state;

  // for locking immediately, without relying on async state change
  const lockRef = useRef(false);

  const setActiveTab = useCallback(
    (newTab) => {
      dispatch({
        type: PAYMENT_METHOD_TAB_SELECTED,
        payload: { activePaymentMethodTab: newTab },
      });
    },
    [dispatch]
  );

  const confirmPaymentSuccess = useCallback(async () => {
    const { data } = await authSender.get(`${ROUTES.checkTotPayment}/${state.checkoutDetails.paymentId}`);
    dispatch({
      type: UPDATE_PAYMENT_CONFIRMATION,
      payload: { paymentConfirmation: data.paymentConfirmation },
    });
  }, [dispatch, state.checkoutDetails.paymentId]);

  const setLock = useCallback((value) => {
    lockRef.current = value;
    setLockPayment(value);
  }, [setLockPayment]);

  const handleSubmit = async () => {
    if (lockRef.current) return;
    setLock(true);

    payCC({
      stripe,
      elements,
      paymentId: state.checkoutDetails.paymentId,
      signatory: state.signatoryName,
      certificateHolder: state.certificateHolder,
      emailInputValue,
      confirmPaymentSuccess,
      onSuccessRedirect: () => history.push('/pay-tot/confirm', { paymentType: 'cc' }),
      unlock: () => {
        setLock(false);
      },
      history,
      onError: (err) => onCaughtError(err, {
        tot: state.totCertId,
        receiptEmail: emailInputValue,
        signatoryName: state.signatoryName,
      }),
    });
  };

  const paymentId = state.checkoutDetails.paymentId;

  const payACH = useCallback(async () => {
    if (lockRef.current) return;
    setLock(true);

    const receiptEmail = emailInputValue;

    try {
      const { data } = await authSender.post('/payACH', {
        bTok: bankIdentifiers.bTok,
        paymentId,
        email: receiptEmail,
        signatory: signatoryName,
        metadata: bankIdentifiers.metadata
      });
      await confirmPaymentSuccess();
      history.push('/pay-tot/confirm', { paymentType: 'ach' });
    } catch (err) {
      // TODO: serve error. Bc of this, we need to implement a way to come back to a payment.
      // The user must be able to check if the payment went through despite the error.
      // console.log('err', err);
      onCaughtError(err, {
        tot: state.totCertId,
        receiptEmail: emailInputValue,
        signatoryName: state.signatoryName,
      });
      Alert.error(ERROR_MESSAGES.PayACHError, 0)
      setLock(false);
    }
  }, [bankIdentifiers, paymentId, signatoryName, history, emailInputValue, confirmPaymentSuccess, setLock, state.totCertId, state.signatoryName]);

  return (
    <Wrapper>
      <FieldLabel fontSize='16px'>{CONSTSTRINGS.PAYMENT_METHOD}</FieldLabel>
      <TabWrapper>
        <Tab isactive={state.activePaymentMethodTab === CREDIT_CARD_TAB} onClick={() => setActiveTab(CREDIT_CARD_TAB)}>
          <TabItem>
            <img
              src='https://js.stripe.com/v3/fingerprinted/img/card-ce24697297bd3c6a00fdd2fb6f760f0d.svg'
              alt=''
              height='14px'
            />
          </TabItem>
          <TabItem>
            <span
              style={{
                width: '100%',
                height: '12px',
                fontSize: '12px',
                padding: '5px 0 2px',
                lineHeight: '1',
              }}
            >
              Credit or Debit Card
            </span>
          </TabItem>
        </Tab>
        <ACHTab
          isactive={state.activePaymentMethodTab === ACH_PAYMENT_TAB}
          setActiveTab={setActiveTab}
          setBankIdentifiers={setBankIdentifiers}
          achTotal={state.checkoutDetails.achTotal}
        />
      </TabWrapper>
      {state.activePaymentMethodTab === CREDIT_CARD_TAB && (
        <>
          <FieldLabel>{CONSTSTRINGS.CARD_INFORMATION}</FieldLabel>
          <CardField>
            <CardNumberElement className='stripe-element' id='stripe-card-number' options={CARD_OPTIONS} />
          </CardField>
          <CardField>
            <CardExpiryElement className='stripe-element' id='stripe-expiry' options={CARD_OPTIONS} />
            <CardCvcElement className='stripe-element' id='stripe-cvc' options={CARD_OPTIONS} />
          </CardField>
        </>
      )}
      <FieldLabel htmlFor='tot_certificate'>{CONSTSTRINGS.TOT_CERTIFICATE}</FieldLabel>
      <StyledInput readOnly name='tot_certificate' value={state.totCertId} />
      <FieldLabel htmlFor='email'>{CONSTSTRINGS.EMAIL}</FieldLabel>
      <StyledInput
        name='email'
        id='stripe-email-input'
        value={emailInputValue}
        onChange={(evt) => {
          setEmailInputValue(evt.target.value);
        }}
      />

      {state.activePaymentMethodTab === CREDIT_CARD_TAB && (
        <PayButton
          id='payButton'
          loading={lockPayment}
          disabled={!emailInputValue || lockPayment}
          onClick={handleSubmit}
        >
          Pay
        </PayButton>
      )}
      {state.activePaymentMethodTab === ACH_PAYMENT_TAB && (
        <>
          <div style={{ display: 'flex', marginTop: 15, alignItems: 'center' }}>
            <Checkbox
              onChange={(_, { checked }) => { setTermsAccepted(checked) }}
              checked={termsAccepted}
            />
            <div style={{ marginLeft: 10 }}>
              <span>Accept</span>
              <a rel="noreferrer" target="_blank" href="https://plaid.com/legal/#consumers">
                &nbsp;Terms of Service
              </a>
            </div>
          </div>
          <PayButton
            id='payButton'
            type='button'
            loading={lockPayment}
            onClick={payACH}
            disabled={!bankIdentifiers || !emailInputValue || lockPayment || !termsAccepted}
          >
            Pay
          </PayButton>
        </>
      )}
    </Wrapper>
  );
}
