import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { StrLicenseApplicationRequirements } from './str-license-application-requirements'
import { FLOW_PATHS } from '../../routes/constants'
import { APPLY_STR_PATHS } from './apply-str-paths';
import { ParcelLookupForm } from '../../parcel-lookup/parcel-lookup-form';
import * as cs from '../../components/common/common-styles';

// temporary while pages in construction
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { stepperActions } from '../../app/stepper-slice';

// routes/index.js -> ApplySTR -> ApplyStrLicensesRoutes -> (all apply STR pages)

const applyRouteStepperSteps = [
  'Property',
  'License Holder',
  'Authorized Agent',
  'Documents',
  'Confirmation',
  'Payment',
];

function ApplyStrLicensesRoutesComponent(props) {
  const { updateStepper } = props;
  const history = useHistory();

  useEffect(() => {
    updateStepper(applyRouteStepperSteps);
  }, [])

  return (
    <cs.ViewContainer>
      <Switch>
        <Route exact path={FLOW_PATHS.applyStrLicense} >
          <Redirect to={APPLY_STR_PATHS.requirements} />
        </Route>

        <Route path={APPLY_STR_PATHS.requirements}>
          <StrLicenseApplicationRequirements />
        </Route>

        <Route path={APPLY_STR_PATHS.property}>
          <ParcelLookupForm />
        </Route>
        {/*
        * The next components are left to implement in the next PR
        */}
        <Route path={APPLY_STR_PATHS.licenseHolder} >
          <div>license Holder</div>
          <div>
            <button onClick={history.goBack}>back</button>
            <button onClick={() => history.push(APPLY_STR_PATHS.authorizedAgent)}>continue</button>
          </div>
        </Route>

        <Route path={APPLY_STR_PATHS.authorizedAgent} >
          <div>authorized agent</div>
          <div>
            <button onClick={history.goBack}>back</button>
            <button onClick={() => history.push(APPLY_STR_PATHS.documents)}>continue</button>
          </div>
        </Route>

        <Route path={APPLY_STR_PATHS.documents} >
          <div>documents</div>
          <div>
            <button onClick={history.goBack}>back</button>
            <button onClick={() => history.push(APPLY_STR_PATHS.confirmation)}>continue</button>
          </div>
        </Route>

        <Route path={APPLY_STR_PATHS.confirmation} >
          <div>confirmation</div>
          <div>
            <button onClick={history.goBack}>back</button>
            <button onClick={() => history.push(APPLY_STR_PATHS.payment)}>continue</button>
          </div>
        </Route>

        <Route path={APPLY_STR_PATHS.payment} >
          <div>payment</div>
          <div>
            <button onClick={history.goBack}>back</button>
            <button onClick={() => history.push(APPLY_STR_PATHS.success)}>continue</button>
          </div>
        </Route>

        <Route path={APPLY_STR_PATHS.success} >
          <div>success</div>
        </Route>
      </Switch>
    </cs.ViewContainer>
  )}

function mapDispatchToProps(dispatch) {
  return {
    updateStepper: (payload) => dispatch(stepperActions.updateSteps(payload)),
  }
}

export const ApplyStrLicensesRoutes = connect(
  null,
  mapDispatchToProps,
)(ApplyStrLicensesRoutesComponent);
