import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Icon, Form, Button } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import TOTPaymentFooter from './tot-pay-footer';
import EmailVerificationModal from './email-verification-modal';
import { useRifm } from 'rifm';
import {
  SectionContainer,
  HeaderWrapper,
  InputWrapper,
  LocationInput,
  MessageContent,
  MessageWrapper,
  RadioOption,
  AddressContent,
  StyledDivider,
  ParcelNumberInput,
} from './certificate-by-address-styles';
import { PaymentContext } from '../../state/context';
import { UPDATE_TOT_CERT_ID } from '../../state/actions';
import NavigationButtonsModal from '../common/navigation-buttons-with-modal';
import { formatParcelNumber } from '../../utils/helper-functions';
import { isAuthenticated } from '../../utils/auth-functions';
import { useHistory } from 'react-router-dom';
import { uiSender } from '../../utils/ui-sender';
import { DATA_KEYS, ROUTES } from '../common/constants';
import { StyledErrorMessage } from '../apply-tot/tot-address-styles';


const CertificateByAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTot, setSelectedTot] = useState('');
  const [parcelNumber, setParcelNumber] = useState('');
  const [disableResend, setDisableResend] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [open, setOpen] = useState(false);
  const { dispatch } = useContext(PaymentContext);
  const history = useHistory();

  const rifm = useRifm({
    value: parcelNumber,
    onChange: setParcelNumber,
    format: formatParcelNumber,
  });

  useEffect(() => {
    if (searchTerm !== '') {
      (async () => {
        try {
          const result = await fetch(
            `https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${searchTerm}&key=53172441788738393&license=us-autocomplete-pro-cloud&selected=&include_only_cities=CA`,
            { headers: { 'Content-Type': 'application/json' } }
          );
          const data = await result.json();
          let addressSuggestions = [];
          if (data.suggestions) {
            addressSuggestions = data.suggestions.map(({ street_line, secondary, city, state, zipcode }) => ({
              title: `${street_line} ${secondary} ${city} ${state} ${zipcode}`,
            }));
          }
          setSuggestions(addressSuggestions);
        } catch (err) {
          // console.log(err);
        }
      })();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (parcelNumber.length === 15) {
      (async () => {
        try {
          setAddresses([]);
          setErrors('');
          const tempParcelNumber = parcelNumber.replace(/-/g, '');
          const { data } = await uiSender.get(`${ROUTES.totCertLookupByApn}/${tempParcelNumber}`);
          const { error } = data;
          if (!error) {
            setAddresses(
              data.totCertificates.map((cert) => ({
                tot: cert[DATA_KEYS.totCertNo],
                propertyAddress: cert[DATA_KEYS.propertyAddress],
                certHolder: cert[DATA_KEYS.ownerName],
              }))
            );
          } else {
            setErrors(error);
          }
        } catch (err) {
          // console.log(err);
        }
      })();
    } else {
      setAddresses([]);
      setErrors('');
    }
  }, [parcelNumber]);

  /*
   * This ref will be used for storing a reference to the cognito user instance that
   * we want to login into the application
   */
  const cognitoUserRef = useRef();

  const initiateLogin = useCallback(
    async (openModalCB) => {
      try {
        cognitoUserRef.current = await Auth.signIn(`${selectedTot}`);
        setDisableResend(false);
        if (openModalCB) openModalCB();
      } catch (err) {
        setDisableResend(false);
        // cert number not found.. must notify user. Talk to James specify this state in the design
      }
    },
    [selectedTot]
  );

  /**
   * Callback for when next button is clicked
   */
  const handleNextClick = useCallback(
    async (isOpen) => {
      initiateLogin(() => setOpen(isOpen));
    },
    [setOpen, initiateLogin]
  );

  /**
   * This callback will be triggered when the user submits the authentication code.
   * On success of this operation, the user will be logged in.
   *
   * @param {string} code Authentication code
   */
  const submitCode = useCallback(
    async (code) => {
      try {
        // this will login the user and save tokens to localStorage
        await Auth.sendCustomChallengeAnswer(cognitoUserRef.current, code);
        const loginSuccess = await isAuthenticated();
        if (loginSuccess) {
          history.push('/pay-tot/revenue');
        } else {
          throw new Error('Bad login code'); // wrong code
        }
      } catch (err) {
        // let users know there was a problem with the login

        setLoginError(true);
      }
    },
    [cognitoUserRef, history]
  );

  const handleChange = (tot) => {
    setSelectedTot(tot);
    dispatch({
      type: UPDATE_TOT_CERT_ID,
      payload: { totCertId: tot },
    });
  };

  const fetchTotsForSelectedAddress = async () => {
    if (searchTerm !== '') {
      setIsLoading(true);
      setAddresses([]);
      setErrors('');
      const { data } = await uiSender.get(`${ROUTES.totCertLookupByAddress}`, { address: searchTerm });
      // console.log('data', data);
      const { error } = data;
      if (!error && data) {
        setIsLoading(false);
        setErrors('');
        setAddresses(
          data.totCertificates.map((cert) => ({
            tot: cert[DATA_KEYS.totCertNo],
            propertyAddress: cert[DATA_KEYS.propertyAddress],
            certHolder: cert[DATA_KEYS.ownerName],
          }))
        );
      } else {
        setIsLoading(false);
        setErrors(error);
      }
    } else {
      setAddresses([]);
      setErrors('');
    }
  };

  return (
    <>
      <EmailVerificationModal
        open={open}
        setOpen={setOpen}
        submitCode={submitCode}
        resendCode={initiateLogin}
        disableResend={disableResend}
        setDisableResend={setDisableResend}
        loginError={loginError}
        resetLoginError={() => setLoginError(false)}
      />
      <SectionContainer>
        <HeaderWrapper
        >What is the address of the property associated with your TOT certificate?</HeaderWrapper>
        <InputWrapper>
          <LocationInput
            results={suggestions}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            onResultSelect={(event, data) => {
              // console.log('data', data);
              setSearchTerm(data.result.title);
            }}
            icon={<Icon name='map marker alternate' />}
          />
          <Button content='Lookup' loading={isLoading} onClick={() => fetchTotsForSelectedAddress()} />
        </InputWrapper>

        <StyledDivider horizontal>Or</StyledDivider>

        <HeaderWrapper>Parcel number</HeaderWrapper>
        <InputWrapper>
          <ParcelNumberInput type='tel' placeholder='123-456-789-000' value={rifm.value} onChange={rifm.onChange} />
        </InputWrapper>
        {errors && <StyledErrorMessage negative>{errors}</StyledErrorMessage>}

        {addresses.length > 0 ? (
          <MessageWrapper>
            <Icon name='check' />
            <MessageContent>
              We found the following TOT certificate. Please select the TOT certificate that you want to use.
            </MessageContent>
          </MessageWrapper>
        ) : null}
        <Form>
          {addresses &&
            addresses.map((address) => (
              <Form.Field key={address.tot}>
                <RadioOption
                  label={`${address.tot} - ${address.certHolder}`}
                  value={address.tot}
                  onChange={() => handleChange(address.tot)}
                  checked={selectedTot === address.tot}
                />
                <AddressContent>{address.propertyAddress}</AddressContent>
              </Form.Field>
            ))}
        </Form>
        <NavigationButtonsModal
          isNextDisabled={selectedTot.length === 0}
          nextClick={handleNextClick}
          backClick='pay-tot'
        />
        <TOTPaymentFooter withImage='true' />
      </SectionContainer>
    </>
  );
};

export default CertificateByAddress;
