export const ListingOptions = [
  {
    key: 'Airbnb',
    text: 'Airbnb',
    value: 'Airbnb',
  },
  {
    key: 'Vbro',
    text: 'Vbro',
    value: 'Vbro',
  },
  {
    key: 'Booking',
    text: 'Booking',
    value: 'Booking',
  },
  {
    key: 'Flipkey',
    text: 'Flipkey',
    value: 'Flipkey',
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'Other',
  },
];
