import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'semantic-ui-react';
import {
  SectionWrapper,
  HeadingContainer,
  ConfirmationNumberContainer,
  StyledParagraph,
  ButtonWrapper,
} from './tot-confirmation-styles';
import { CertificateContext } from '../../state/context';
import { UPDATE_TOT_CERTIFICATION_STEP } from '../../state/actions';

const TotConfirmation = () => {
  const { state, dispatch } = useContext(CertificateContext);

  useEffect(() => {
    dispatch({
      type: UPDATE_TOT_CERTIFICATION_STEP,
      payload: { currentStep: 6 },
    });
  }, [dispatch]);

  return (
    <SectionWrapper>
      <HeadingContainer>TOT Certificate Number</HeadingContainer>
      <ConfirmationNumberContainer>
        {state.certNumber}
      </ConfirmationNumberContainer>
      <StyledParagraph>
        Congratulations on successfully registering your rental property. Your
        signed certificate will be mailed to you shortly. We have also emailed
        you details of the certificate.
      </StyledParagraph>
      <ButtonWrapper>
        <Button
          as={Link}
          to={{
            pathname: '/pay-tot',
            state: { totCertNumber: state.certNumber },
          }}
          positive
        >
          Report and Pay STR Taxes for Past Quarters
        </Button>
      </ButtonWrapper>
      <Image src='../../../assets/apply-tot-confirm.svg' />
    </SectionWrapper>
  );
};

export default TotConfirmation;
