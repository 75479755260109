import { Link } from 'react-router-dom';
import { Input, Message } from 'semantic-ui-react';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
`;

export const StyledInput = styled(Input)`
  width: 200px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  input {
    &&& {
      ::focus {
        border-color: #027680 !important;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 1rem 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin-top: ${props => props.marginTop || null};

  @media screen and (max-width: 768px) {
    & > * {
      margin: 0 !important;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: #027680;
  text-decoration: underline;
  &:hover {
    color: #027680;
  }
`;

export const ImageWrapper = styled.div`
  margin: 5rem 0;
`;

export const ResultsWrapper = styled.div`
  margin: 2rem 0;
`;

export const PropertyAddressLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const PropertyAddressContent = styled.div`
  font-weight: bold;
  color: #000000;
`;

export const ResultsInfoContentWrapper = styled.div`
  display: flex;
  color: #2ea2f8;
  margin: 2rem 0;
`;

export const ResultsInfoContent = styled.div`
  font-weight: bold;
`;

export const AddressDescriptionLine = styled.div`
  margin: 0.5rem 0;
`;

export const StyledErrorMessage = styled(Message)`
  width: 400px;
  @media only screen and (max-width: 768px) {
    width: unset !important;
  }
`;

export const Certificate = styled.div`
  display: grid;
  grid-template-rows: 20px 20px 20px 20px 50px;
  gap: 10px;
  color: black;
  background: ${props => props.noBackground ? null : 'rgb(217,218,220)'};
  padding: 15px;
  border-radius: 5px;
`;

export const StyledText = styled.div`
  font-weight: bold;
  grid-row-start: ${props => props?.gridIndex || null};
`;

export const StyledButton = styled.button`
  color: white;
  background: rgb(73,97,177);
  height: 45px;
  width: ${props => props.width || '75px'};
  font-weight: bold;
  grid-row-start: 5;
`;

export const Header = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
