import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UpdateTOTContext } from '../../state/context';
import { ButtonWrapper } from '../common/navigation-buttons-styles';
import { Button, Grid, Message } from 'semantic-ui-react';
import { HeaderWrapper } from '../common/common-styles';
import { FieldItem } from '../common/field-item';
import ConditionalFieldItem from '../apply-tot/conditional-field-item'
import ManagmentInfoItems from '../apply-tot/managment-info-items'
import LocalInfoItems from '../apply-tot/local-contact-info-items'
import { FieldItemList } from '../common/field-item-list';
import { buildPostData, checkIfEmailsHaveChanged, getTOTDetails, submitTOTUpdate } from './update-utils';
import { getCertHolderInfo } from '../../utils/certificate-holder-info';


export const UpdateReviewComponent = () => {
  const history = useHistory();
  const { state } = useContext(UpdateTOTContext);
  const unalteredData = useRef({});

  // Default to true to prevent user from submitting before validations
  const [isLoading, setIsLoading] = useState(true);
  const [buttonText, setButtonText] = useState('Submit');

  const [hasLoginEmailChanged, setHasLoginEmailChanged] = useState(false);
  const [emailsWhichHaveChanged, setEmailsWhichHaveChanged] = useState({});
  const [areChangesConfirmed, setAreChangesConfirmed] = useState(false);

  const {
    ownerInfo,
    managerInfo,
    localContactInfo,
    totCertId,
    parcelNumber,
    propertyAddress,
    noOfUnits,
    rentalType,
    secondaryDwelling,
    unitNumber,
    manageRental,
    listingUrls = [""],
  } = state;

  useEffect(() => {
    (async() => {
      unalteredData.current = await getTOTDetails(totCertId);
      await handleCheckEmailsForChange();
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Click Handlers
  const handleUpdateInfo = async () => {
    const postData = buildPostData(state);
    const data = await submitTOTUpdate(totCertId, postData);
    /**
     * Simple check to see if data returned contains the TOT Cert ID being modified
     * before proceeding to the confirmation page
     */
    if (Boolean(data?.totCertId)) history.push('/update-info/confirm');
  };

  const handleCheckEmailsForChange = async () => {
    setIsLoading(true);
    setAreChangesConfirmed(false);

    const {
      loginEmailHasChanged,
      emailsWhichHaveChanged,
    } = checkIfEmailsHaveChanged(unalteredData.current, state);

    if (!loginEmailHasChanged) {
      // No emails have changed
      setButtonText('Submit');
      setHasLoginEmailChanged(false);
    } else {
      // Emails have changed
      setButtonText('Confirm changes')
      setHasLoginEmailChanged(true);
    }

    setHasLoginEmailChanged(loginEmailHasChanged);
    setEmailsWhichHaveChanged(emailsWhichHaveChanged);

    setIsLoading(false);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!hasLoginEmailChanged || areChangesConfirmed) {
      await handleUpdateInfo();
    } else if (!areChangesConfirmed) {
      setButtonText('Submit');
      setAreChangesConfirmed(true);
    }
    setIsLoading(false);
  }

  const certHolderInfo = getCertHolderInfo(manageRental, ownerInfo, managerInfo);

  return (
    <>
      <HeaderWrapper>Please confirm the following information.</HeaderWrapper>

      <Grid stackable>
        <Grid.Column width={8}>
          <FieldItem label='TOT certificate number' content={totCertId} />
          <FieldItem label='Parcel number' content={parcelNumber} />
          <FieldItem label='Property address' content={propertyAddress} />
          <ConditionalFieldItem
            label='Rental Type'
            content={rentalType}
          />
          <FieldItem label='Secondary Dwelling ' content={secondaryDwelling ? 'Yes' : 'No'} />
          <ConditionalFieldItem label='Unit number' content={unitNumber} />
          <ConditionalFieldItem label='Number of units available' content={noOfUnits} />
          <FieldItem label="Certificate Holder" content={certHolderInfo.name} />
          <FieldItem
            label="Certificate Holder Email"
            content={certHolderInfo.email}
            hasChanged={emailsWhichHaveChanged[manageRental]}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <FieldItem label='Owner Name' content={ownerInfo.name} />
          <FieldItem label='Phone Number' content={ownerInfo.phone} />
          <FieldItem
            label='Email'
            content={ownerInfo.email}
            hasChanged={emailsWhichHaveChanged['owner']}
          />
          <FieldItem label='Mailing Address' content={ownerInfo.address} />
        </Grid.Column>
      </Grid>

      <Grid stackable>
        <Grid.Column width={8}>
          <ManagmentInfoItems
            manageRental={manageRental}
            managerInfo={managerInfo}
            hasChanged={emailsWhichHaveChanged['property_mgr']}
          />

          {listingUrls.filter((url) => Boolean(url)).length >= 1 && (
            <FieldItemList label="Listings" content={listingUrls} />
          )}
        </Grid.Column>

        <LocalInfoItems
          info={localContactInfo.whoIs === 'someoneElse' ?
            localContactInfo :
            manageRental === "owner" ?
              ownerInfo : managerInfo
          }
          localContact={localContactInfo.whoIs}
          hasChanged={emailsWhichHaveChanged['localContactInfo']}
        />
      </Grid>


      {/*
        Only show warning message when:
          1. Emails have been changed and
          2. Changes have not yet been confirmed by user
       */}
      {hasLoginEmailChanged && !areChangesConfirmed && (
        <Message style={{ width: '100%' }} warning>
          <div className="header">
            You are changing the email associated with your TOT certificate.
          </div>
          This will change your login email address. Please make sure you have access to this email.
        </Message>
      )}

      <ButtonWrapper>
        <Button
          basic
          content='Back'
          onClick={history.goBack}
        />
        <Button
          color='black'
          content={buttonText}
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}
        />
      </ButtonWrapper>
    </>
  );
}

export const UpdateReview = () => {
  const history = useHistory();

  if (history.action === "POP") {
    /*
     * Reloading the home page forcefully in order to delete history.
     * This is done in order to prevent the user from using the back button to navigate back
     * into the Apply TOT form once the certificate has been created successfully
     */
    window.history.pushState(null, null, '/'); // will change url in address bar
    window.location.reload(); // this is to delete
    return null;
  }

  return <UpdateReviewComponent />
}
