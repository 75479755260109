export const PaymentSteps = [
  'Certificate',
  'Report revenue',
  'Confirmation and Acknowledge',
  'Payment'
];

export const CertificateSteps = [
  'Parcel number',
  'Property Details',
  'Owner',
  'Manager and Contact',
  'Optional: listings',
  'Confirmation',
  'Signature',
];
