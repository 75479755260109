import React from 'react';
import { Header } from 'semantic-ui-react';
import Footer from '../components/common/footer';
import LayoutHeader from '../components/common/layout-header';
import SectionCard from '../components/common/section-card';
import { HomeContainer, CardGrid, CardGridItem } from './Home-styles';
import { FlexCol } from '../components/common/common-styles';

const Home = () => (
  <>
    <LayoutHeader title="Placer County Short-term Rental Registration and Tax Payment System" />
    <HomeContainer>
      <FlexCol>
        <Header
          as={'h1'}
          style={{
            color: '#5c5d5d',
            fontSize: '50px',
            fontWeight: 'normal',

          }}
          size="huge"
        >
          Welcome!
        </Header>

        <CardGrid>
          <CardGridItem>
            <Header style={{ fontWeight: 'normal' }}>I’m a new user…</Header>

            <SectionCard
              title="Apply for a TOT Certificate"
              url="/apply-tot"
              image="ui-image-avatar-tot.svg"
            />
          </CardGridItem>

          <CardGridItem>
            <Header style={{ fontWeight: 'normal' }}>
              I’m a returning user…
            </Header>

            <SectionCard
              title="Pay TOT"
              url="/pay-tot"
              image="ui-image-avatar-pay-tot.svg"
            />
          </CardGridItem>
          <CardGridItem>
            <Header style={{ fontWeight: 'normal' }}>
              I’m a returning user…
            </Header>
            <SectionCard
              title="Update TOT Information"
              url="/update-info"
              image="ui-image-avatar-update-information.svg"
            />
          </CardGridItem>
          <CardGridItem className="card-grid-item">
            <Header style={{ fontWeight: 'normal' }}>
              {'Short-Term Rental Permits'}
            </Header>

            <SectionCard
              title="Short-Term Rental Permits"
              url="https://placer-ca-str-permit.deckard.com"
              image="ui-image-avatar-str.svg"
              target="_blank"
            />
          </CardGridItem>
        </CardGrid>
      </FlexCol>
    </HomeContainer>
    <Footer />
  </>
);

export default Home;
