import { Link } from 'react-router-dom';
import { Input, Message } from 'semantic-ui-react';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin: 1rem 0;
  color: #5c5d5d;
  font-size: 18px;
`;

export const StyledInput = styled(Input)`
  width: 200px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  input {
    &&& {
      ::focus {
        border-color: #027680 !important;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 1rem 0;
`;

export const ButtonWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    & > * {
      margin: 0 !important;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: #027680;
  text-decoration: underline;
  &:hover {
    color: #027680;
  }
`;

export const ImageWrapper = styled.div`
  margin: 5rem 0;
`;

export const ResultsWrapper = styled.div`
  margin: 2rem 0;
`;

export const PropertyAddressLabel = styled.div`
  font-weight: bold;
  color: #7f8fa4;
`;

export const PropertyAddressContent = styled.div`
  font-weight: bold;
  color: #000000;
`;

export const ResultsInfoContentWrapper = styled.div`
  display: flex;
  color: #2ea2f8;
  margin: 2rem 0;
`;

export const ResultsInfoContent = styled.div`
  font-weight: bold;
`;

export const AddressDescriptionLine = styled.div`
  margin: 0.5rem 0;
`;

export const StyledErrorMessage = styled(Message)`
  width: 400px;
  @media only screen and (max-width: 768px) {
    width: unset !important;
  }
`;
