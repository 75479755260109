import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import {
  SectionContainer,
  HeaderWrapper,
  PageContent,
  DeclarationWrapper,
  ButtonSectionWrapper,
  ImageSectionContainer,
  StyledCheckbox,
} from './report-revenue-styles';
import PreviousQuartersModal from './previous-quarters-modal';
import NavigationButtons from '../common/navigation-buttons';
import QuarterSection from './quarter-section';
import { PaymentContext } from '../../state/context';
import { UPDATE_PAYMENT_STEP } from '../../state/actions';
import { getPastQuarters, getQuarterDetails } from '../../utils/utility-functions';
import { checkIsValidNumber, checkIsValidDecimal } from '../../utils/helper-functions';

const ReportRevenue = () => {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(PaymentContext);
  const [quarters, setQuarters] = useState([]);
  const [previousQuarters, setPreviousQuarters] = useState([]);
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [previousQuartersChecked, setPreviousQuartersChecked] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [noRevenueQuarter, setNoRevenueQuarter] = useState(false);

  useEffect(() => {
    dispatch({
      type: UPDATE_PAYMENT_STEP,
      payload: { currentStep: 1 },
    });
  }, [dispatch]);

  useEffect(() => {
    if (state.isReportReveneueDetailsSet) {
      setQuarters(state.quarters);
      setPreviousQuarters(state.previousQuarters);
      setPreviousQuartersChecked(state.previousQuartersChecked);
      setNoRevenueQuarter(state.noRevenueQuarter);
      setSelectedQuarters(state.selectedQuarters);
      setIsLoaded(true);
    } else {
      const quartersList = getQuarterDetails();
      setQuarters(quartersList);
      const pastQuartersList = getPastQuarters();
      setPreviousQuarters(pastQuartersList.previousQuarters);
      setPreviousQuartersChecked(pastQuartersList.previousChecked);
      setIsLoaded(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let quartersValid = false;
    let pastQuartersValid = false;
    if (quarters.length > 0) {
      let isAllEntriesValid = [];

      quarters.forEach((item, index) => {
        if (
          checkIsValidNumber(item.numDaysAvailable) &&
          checkIsValidNumber(item.numDaysOccupied) &&
          // checkIsValidDecimal(item.tbidExcluded) &&
          checkIsValidDecimal(item.taxableReceipts)
        ) {
          if (
            item.numDaysAvailable >= 0 &&
            item.numDaysOccupied >= 0 &&
            item.taxableReceipts >= 0
            // && item.tbidExcluded >= 0
          ) {
            isAllEntriesValid.push(true);
          }

          // check if no revenue has been received for the first quarter and a valid number of days available is reported
          else if (index === 0 && item.numDaysAvailable >= 0 && noRevenueQuarter) {
            isAllEntriesValid.push(true);
          }
        }
      });
      if (quarters.length === isAllEntriesValid.length) {
        quartersValid = true;
      }
    }

    //validation for selected past quarters
    if (selectedQuarters.length > 0) {
      let isAllEntriesValid = [];
      selectedQuarters.forEach((item) => {
        if (
          checkIsValidNumber(item.numDaysAvailable) &&
          checkIsValidNumber(item.numDaysOccupied) &&
          // checkIsValidDecimal(item.tbidExcluded) &&
          checkIsValidDecimal(item.taxableReceipts)
        ) {
          if (
            item.numDaysAvailable >= 0 &&
            item.numDaysOccupied >= 0 &&
            item.taxableReceipts >= 0
            // && item.tbidExcluded >= 0
          ) {
            isAllEntriesValid.push(true);
          }
        }
      });
      if (selectedQuarters.length === isAllEntriesValid.length) {
        pastQuartersValid = true;
      }
    }
    if (
      (pastQuartersValid && quartersValid) ||
      (pastQuartersValid && quarters.length === 0) ||
      (selectedQuarters.length === 0 && quartersValid) ||
      (pastQuartersValid && noRevenueQuarter)
    ) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [quarters,  setIsNextDisabled, noRevenueQuarter]);

  const handleDeleteClick = (name) => {
    const filteredQuarters = selectedQuarters.filter((quarter) => quarter.name !== name);
    setSelectedQuarters(filteredQuarters);
  }

  const handlePreviousQuartersModal = () => {
    // checking whether the selected past quarters and checked quarters in the modal match correctly
    let tempPreviousQuartersChecked = JSON.parse(JSON.stringify(previousQuartersChecked));
    Object.keys(tempPreviousQuartersChecked).forEach((v) => (tempPreviousQuartersChecked[v] = false));
    selectedQuarters.forEach((item) => {
      tempPreviousQuartersChecked[`${item.quarter}-${item.year}`] = true;
    });
    setPreviousQuartersChecked(tempPreviousQuartersChecked);
    setOpen(true);
  };

  const handleNoRevenue = (event, { checked }) => {
    if (checked) {
      setQuarters(
        quarters.map((quarter) => {
          quarter.numDaysAvailable = 0;
          quarter.numDaysOccupied = 0;
          quarter.taxableReceipts = 0;
          // quarter.tbidExcluded = 0;
          return quarter;
        })
      );
      setNoRevenueQuarter(checked);
    } else {
      setQuarters(
        quarters.map((quarter) => {
          quarter.numDaysAvailable = '';
          quarter.numDaysOccupied = '';
          quarter.taxableReceipts = '';
          // quarter.tbidExcluded = '';
          return quarter;
        })
      );
      setNoRevenueQuarter(checked);
    }
  };

  return (
    <>
      {isLoaded ? (
        <PreviousQuartersModal
          open={open}
          previousQuarters={previousQuarters}
          selectedQuarters={selectedQuarters}
          setSelectedQuarters={setSelectedQuarters}
          previousQuartersChecked={previousQuartersChecked}
          setPreviousQuartersChecked={setPreviousQuartersChecked}
          setOpen={setOpen}
        />
      ) : null}
      {isLoaded ? (
        <SectionContainer>
          <HeaderWrapper>
            For the following quarters, please report the taxable receipts, i.e. payments received for stays for fewer
            than 30 consecutive nights.
          </HeaderWrapper>
          <PageContent>
            Taxable receipts INCLUDES, but is not limited to, nightly rents, weekly rents, standard cleaning fees, pet
            fees, internet charges, late check-out fees, extra person fees, and resort fees. Taxable receipts EXCLUDES
            refundable deposits and any additional items included in a special package rate, such as ski passes, or
            other recreational activity or additional service subject to CA sales tax. Taxable receipts exclude payments
            received for units that are occupied more than 30 consecutive days.
          </PageContent>
          {quarters &&
            quarters.map((quarter) => (
              <QuarterSection
                key={quarter.title}
                quarters={quarters}
                quarter={quarter}
                setQuarters={setQuarters}
                setIsNextDisabled={setIsNextDisabled}
                noRevenueQuarter={noRevenueQuarter}
              />
            ))}
          <DeclarationWrapper>
            <StyledCheckbox
              label='I have not received any revenue for this quarter'
              onChange={(e, data) => handleNoRevenue(e, data)}
              checked={noRevenueQuarter}
            />

          </DeclarationWrapper>
          {selectedQuarters &&
            selectedQuarters.map((selectedQuarter) => {
              return (
                <QuarterSection
                  key={selectedQuarter.title}
                  quarter={selectedQuarter}
                  quarters={selectedQuarters}
                  setQuarters={setSelectedQuarters}
                  setIsNextDisabled={setIsNextDisabled}
                  handleDeleteClick={handleDeleteClick}
                />
              )
            }
            )}
          <ButtonSectionWrapper>
            <Button onClick={handlePreviousQuartersModal} color={noRevenueQuarter ? 'black' : 'grey'}>
              <Icon name='add' />
              <span>Add previous quarters</span>
            </Button>
          </ButtonSectionWrapper>
          <PageContent>
            Notice for properties located in the Mountaineer Transit Company TBID special district:
            The assessment rate for the MTC increased to 1.5% on reservations made on or after February 1st, 2023.
            If you are remitting for assessments at both the 1% and 1.5% rate, please contact Revenue Services at
            (916) 543-3950 or tot@placer.ca.gov.
            We'd be happy to directly assist you in completing your quarterly remittance
          </PageContent>
          <NavigationButtons
            isNextDisabled={isNextDisabled && !noRevenueQuarter}
            nextClick='sign'
            nextPayload={[...quarters, ...selectedQuarters]}
            backClick='pay-tot'
            contextPayload={{ quarters, previousQuarters, selectedQuarters, previousQuartersChecked, noRevenueQuarter }}
          />
          <ImageSectionContainer>
            <Image src='../../../assets/tot-pay-revenue.svg' />
          </ImageSectionContainer>
        </SectionContainer>
      ) : null}
    </>
  );
};

export default ReportRevenue;
