import React, { useContext } from 'react'
import { UpdateTOTContext } from '../../state/context';
import { SectionHeadingWrapper } from './update-details-styles';
import { FieldItem } from '../common/field-item';
import { getCertHolderInfo } from '../../utils/certificate-holder-info';

export const UpdateCertificateInfo = () => {
  const {
    state: {
      totCertId,
      parcelNumber,
      propertyAddress,
      ownerInfo,
      managerInfo,
      manageRental,
    }
  } = useContext(UpdateTOTContext);

  const certHolder = getCertHolderInfo(manageRental, ownerInfo, managerInfo);

  if (!certHolder) return null;

  return (
    <>
      <SectionHeadingWrapper>Certificate Information</SectionHeadingWrapper>
      <FieldItem label='Certificate Holder' content={certHolder.name} />
      <FieldItem label='TOT Certificate Number' content={totCertId} />
      <FieldItem label='Parcel Number' content={parcelNumber} />
      <FieldItem label='Property Address' content={propertyAddress} />
    </>
  )
}
