import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const CityLogo = styled.img`
  width: 30px;
  height: auto;
`;

const HeaderLabel = styled(Menu.Item)`
  &&& {
    font-weight: bold;
    font-size: 15px;
    padding: 0;
  }
`;

const HeaderLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #000000;
`;

export const PageBanner = ({ city, label, logo, isHelp }) => (
  <Menu fixed="top" size="small" borderless>
    <Menu.Item
      as={'a'}
      href="https://www.google.com"
      target="_blank"
      header
    >
      <CityLogo src={logo} alt={`${city} logo`} />
    </Menu.Item>

    <HeaderLabel>{city} {label}</HeaderLabel>

    <Menu.Menu position="right">
      <Menu.Item>
        <HeaderLink to="/">Main</HeaderLink>
      </Menu.Item>
      <Menu.Item>
        {isHelp ?
          <HeaderLink to="/">Back</HeaderLink> :
          <HeaderLink to="/help">Help</HeaderLink>
        }
      </Menu.Item>
    </Menu.Menu>
  </Menu>
);
