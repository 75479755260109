import React from 'react';
import { Icon } from 'semantic-ui-react';
import { formatPhoneNumber } from '../../utils/helper-functions';
import { INPUT_MAX_LENGTHS } from '../common/constants';
import { FieldInput, FieldLabel, RowWrapper } from './contact-section-styles';
import { LocationInput } from './tot-contact-manager-styles';

const ContactSectionAddress = (props) => {
  const {
    labels,
    updateInfo,
    info,
    suggestions,
    setSearchTerm,
    searchTerm,
    disabledFields = { company: false, name: false, phone: false },
    sameAsRental = false,
  } = props;
  const { name, phone, email, address, company } = labels;

  const handleInputChange = (e, data) => {
    if (data.name === 'name') {
      updateInfo({ ...info, name: data.value });
    } else if (data.name === 'phone') {
      const phoneNumber = formatPhoneNumber(data.value);

      updateInfo({ ...info, phone: phoneNumber });
    } else if (data.name === 'address') {
      updateInfo({ ...info, address: data.value });
    } else if (data.name === 'email') {
      updateInfo({ ...info, email: data.value });
    } else if (data.name === 'company') {
      updateInfo({ ...info, company: data.value });
    }
  };

  const updateAddress = (addressTitle) => updateInfo({ ...info, address: addressTitle });

  const handleAddressUpdates = (address) => {
    setSearchTerm(address);
    updateAddress(address);
  }

  return (
    <>
      {Boolean(company) &&
        <RowWrapper>
          <FieldLabel>{company}</FieldLabel>
          <FieldInput
            name='company'
            width='100%'
            value={info.company}
            onChange={(e, data) => handleInputChange(e, data)}
            disabled={disabledFields['company']}
            data-cy={`${labels.cypressPrefix}-company`}
          />
        </RowWrapper>
      }
        <RowWrapper>
          <FieldLabel>{name}</FieldLabel>
          <FieldInput
            name='name'
            value={info.name}
            label={{ icon: 'asterisk' }}
            labelPosition='left corner'
            onChange={(e, data) => handleInputChange(e, data)}
            disabled={disabledFields['name']}
            maxLength={INPUT_MAX_LENGTHS.NAME}
            data-cy={`${labels.cypressPrefix}-name`}
            width='100%'

          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>{phone}</FieldLabel>
          <FieldInput
            name='phone'
            value={info.phone}
            onChange={(e, data) => handleInputChange(e, data)}
            label={{ icon: 'asterisk' }}
            labelPosition='left corner'
            disabled={disabledFields['phone']}
            maxLength={INPUT_MAX_LENGTHS.PHONE}
            data-cy={`${labels.cypressPrefix}-phone`}
            width='100%'

          />
        </RowWrapper>
      <RowWrapper>
        <FieldLabel>{email}</FieldLabel>
        <FieldInput
          name='email'
          label={{ icon: 'asterisk' }}
          labelPosition='left corner'
          value={info.email}
          onChange={(e, data) => handleInputChange(e, data)}
          maxLength={INPUT_MAX_LENGTHS.EMAIL}
          data-cy={`${labels.cypressPrefix}-email`}
          width='100%'
        />
      </RowWrapper>
      <RowWrapper>
        <FieldLabel>{address}</FieldLabel>
        <LocationInput
          results={suggestions}
          onSearchChange={(e) => handleAddressUpdates(e.target.value)}
          value={searchTerm}
          onResultSelect={(e, data) => handleAddressUpdates(data.result.title)}
          icon={<Icon name='map marker alternate' />}
          disabled={sameAsRental}
          maxLength={INPUT_MAX_LENGTHS.ADDRESS}
          data-cy={`${labels.cypressPrefix}-address`}
        />
      </RowWrapper>
    </>
  );
};

export default ContactSectionAddress;
